/* * {
    box-sizing: border-box;
  }
  
  body {
    font: inherit;  
  } */
  
  /*the container must be positioned relative:*/
  .autocomplete {
    position: relative;
    display: inline-block;
  }
  
  input {
    border: 1px solid transparent;
    background-color: #f1f1f1;
    padding: 10px;
    font-size: inherit;
  }
  
  .addTextArea input[type=text] {
    background-color: #f1f1f1;
    width: 100%;
  }
  
  input[type=submit] {
    background-color: DodgerBlue;
    color: #fff;
    cursor: pointer;
  }
  
  .autocomplete-items {
    position: absolute;
    border: 2px solid #d4d4d4;
    border-bottom: none;
    /* border-top: none; */
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    /* top: 100%; */
    bottom: 100%;
    left: 0;
    right: 0;
    overflow: scroll;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    scroll-behavior: smooth;
    /* max-height: 200px;     */
  }
  
  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff; 
    border-bottom: 1px solid #d4d4d4; 
  }
  
  /*when hovering an item:*/
  .autocomplete-items div:hover {
    background-color: #e9e9e9; 
  }
  
  /*when navigating through the items using the arrow keys:*/
  .autocomplete-active {
    background-color: DodgerBlue !important; 
    color: #ffffff;
  }