*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: 'Roboto', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }



  @font-face {
    font-family: 'Menlo Regular';
    font-style: normal;
    font-weight: normal;
    src: local('../fonts/Menlo/Menlo-Regular.woff'), url('../fonts/Menlo/Menlo-Regular.woff') format('woff');
  }

@font-face{
    font-family: 'Seravek-Bold';
    src: url('../fonts/seravek/Seravek-Bold.ttf');
    src: local('../fonts/seravek/Seravek-Bold.ttf'),
    url('../fonts/seravek/Seravek-Bold.ttf') format('truetype');
}

@font-face{
  font-family: 'Seravek-ExtraLight';
  src: url('../fonts/seravek/Seravek-ExtraLight.ttf');
  src: local('../fonts/seravek/Seravek-ExtraLight.ttf'),
  url('../fonts/seravek/Seravek-ExtraLight.ttf') format('truetype');
}

@font-face{
  font-family: 'Seravek-Light';
  src: url('../fonts/seravek/Seravek-Light.ttf');
  src: local('../fonts/seravek/Seravek-Light.ttf'),
  url('../fonts/seravek/Seravek-Light.ttf') format('truetype');
}

@font-face{
  font-family: 'Seravek-Medium';
  src: url('../fonts/seravek/Seravek-Medium.ttf');
  src: local('../fonts/seravek/Seravek-Medium.ttf'),
  url('../fonts/seravek/Seravek-Medium.ttf') format('truetype');
}

@font-face{
  font-family: 'Seravek';
  src: url('../fonts/seravek/Seravek.ttf');
  src: local('../fonts/seravek/Seravek.ttf'),
  url('../fonts/seravek/Seravek.ttf') format('truetype');
}

  /*----------------- Seravek font ---------------*/

  @font-face{
    font-family: 'HelveticaNeueBold';
    src: url('../fonts/helvetica-neue/HelveticaNeueBold.ttf');
    src: local('../fonts/helvetica-neue/HelveticaNeueBold.ttf'),
    url('../fonts/helvetica-neue/HelveticaNeueBold.ttf') format('truetype');
  }
  
  @font-face{
    font-family: 'HelveticaNeueCyr-Thin';
    src: url('../fonts/helvetica-neue/HelveticaNeueCyr-Thin.ttf');
    src: local('../fonts/helvetica-neue/HelveticaNeueCyr-Thin.ttf'),
    url('../fonts/helvetica-neue/HelveticaNeueCyr-Thin.ttf') format('truetype');
  }
  
  @font-face{
    font-family: 'HelveticaNeueCyr-UltraLight';
    src: url('../fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.ttf');
    src: local('../fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.ttf'),
    url('../fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.ttf') format('truetype');
  }
  
  @font-face{
    font-family: 'HelveticaNeueItalic';
    src: url('../fonts/helvetica-neue/HelveticaNeueItalic.ttf');
    src: local('../fonts/helvetica-neue/HelveticaNeueItalic.ttf'),
    url('../fonts/helvetica-neue/HelveticaNeueItalic.ttf') format('truetype');
  }
  
  @font-face{
    font-family: 'HelveticaNeueLight';
    src: url('../fonts/helvetica-neue/HelveticaNeueLight.ttf');
    src: local('../fonts/helvetica-neue/HelveticaNeueLight.ttf'),
    url('../fonts/helvetica-neue/HelveticaNeueLight.ttf') format('truetype');
  }
  
  @font-face{
    font-family: 'HelveticaNeueMedium';
    src: url('../fonts/helvetica-neue/HelveticaNeueMedium.ttf');
    src: local('../fonts/helvetica-neue/HelveticaNeueMedium.ttf'),
    url('../fonts/helvetica-neue/HelveticaNeueMedium.ttf') format('truetype');
  }
    
  @font-face{
    font-family: 'HelveticaNeueUltraLight';
    src: url('../fonts/helvetica-neue/HelveticaNeueUltraLight.ttf');
    src: local('../fonts/helvetica-neue/HelveticaNeueUltraLight.ttf'),
    url('../fonts/helvetica-neue/HelveticaNeueUltraLight.ttf') format('truetype');
  }

  

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }


body {
  margin: 0;
  font-weight: 400;
  text-align: left;
  font-size: 16px;
  background-color: #fff;
  background-image: url('https://chatbot.appypie.com/dist/img/public/img/iconbbg.jpg');   
}

// .layout-not-liveChat{
//   background-image: url(https://chatbot.appypie.com/dist/img/public/img/iconbbg.jpg);
// }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
    margin: 0px;
    padding: 0px;
}

p {
    margin: 0px;
    padding: 0px; }

ol,
ul,
dl {
  margin: 0px;
  padding: 0px;
 }

ol ol,
ul ul,
ol ul,
ul ol {
    margin: 0px;
    padding: 0px;
}

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #7460ee;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #381be7;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: 'Roboto', sans-serif;
  font-size: 1em; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #adb5bd;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; outline: 0;}


input:focus,
button:focus,
select:focus,
optgroup:focus,
textarea:focus {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; outline: 0;}

  button, input, optgroup, select, textarea{
    outline: 0 !important;
  }


input:focus,
button:focus,
select:focus,
optgroup:focus,
textarea:focus {
  outline: 0}



button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="search"],
input[type="month"] {
  -webkit-appearance: listbox; }

input[type="date"]:focus,
input[type="time"]:focus,
input[type="datetime-local"]:focus,
input[type="search"],
input[type="text"],
input[type="month"]:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color; }

input[type="text"]{
      cursor: auto;
  }

textarea:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color; }

textarea {
  overflow: auto;
  resize: none;
 }



/**********************************************************/
/**********************************************************/
/**********************************************************/
/************** start anas libarary css *******************/
/**********************************************************/
/**********************************************************/
/**********************************************************/


.k-flex{
    display: flex;
}

.justify-content-end{
    justify-content: flex-end;
}

.justify-content-flexend{
  justify-content: flex-end;
}

.justify-content-center{
  justify-content: center;
}

.align-items-center{
    align-items: center;
}

.btn-K{
  font-size: 16px;
  padding: 8px 30px;
}

// .popupbtn{
//   min-width: 162px;
//   font-size: 14px;
//   padding: 10px 30px;
// }

// .popupbtngray{
//   background: #E8E8E8;
//   padding: 10px 10px;
//   border-radius: 6px;
//   border: 1px solid #E8E8E8;
//   color: #A2A2A2;
// }

.btn-radius{
  border-radius: 100px !important;
  -webkit-border-radius: 100px !important;
  -moz-border-radius: 100px !important;
  -ms-border-radius: 100px !important;
  -o-border-radius: 100px !important;
}

// .popupbtnblue{
//   background: #4092FB;
//   border-radius: 6px;
//   border: 1px solid #4092FB;
//   color: #fff;
// }

.btnBlue{
  background: #4092FB;
  border: 1px solid #4092FB;
  font-size: 14px;
  color: #fff;
  padding: 8px 30px;
  border-radius: 5px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
}

.btnBlue:focus{
  font-size: 14px;
}

.btnAddnotes{
  font-size: 12px;
  background: #07f;
  color: #fff;
  border: 1px solid #07f;
  padding: 2px 10px;
  border-radius: 3px;
  margin-left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.btnAddnotes:focus{
  font-size: 12px;
  background: #07f;
  color: #fff;
  border: 1px solid #07f;
  padding: 2px 10px;
  border-radius: 5px;
  margin-left: 10px;
}

.btnBlueSettings{
  background: #1B85FC;
  border: 1px solid #1B85FC;
  font-size: 14px;
  color: #fff;
  padding: 11px 30px;
  border-radius: 2px;
  box-shadow: 0px 6px 12px rgba(27, 133, 252, 0.34);
}

.btnOrangeSettings{
  background: #f85d1d;
  border: 1px solid #f85d1d;
  font-size: 14px;
  color: #fff;
  padding: 8px 30px;
  border-radius: 2px;
}

.btnWhiteSettings{
  background: #fff;
  border: 1px solid #fff;
  font-size: 14px;
  color: #000;   
  padding: 8px 20px;
  border-radius: 2px;
}
.btnBorderSettings {
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  font-size: 14px;
  color: #000;   
  padding: 8px 20px;
}
.btnRestore{
  background: #DDDDDD;
  border: 1px solid #DDDDDD;
  font-size: 14px;
  color: #9B9B9B;
  padding: 8px 30px;
  border-radius: 2px;
}

.btnBlueSettings:focus, .btnWhiteSettings:focus, .btnRestore:focus, .btnUploadImage:focus, .btnBorderSettings:focus {
  font-size: 14px;
}

.cursor-pointer { cursor: pointer;}

.btnUploadImage{
  background: #d5d5d5;
  border: 1px solid #d5d5d5;
  font-size: 14px;
  color: #969696;
  padding: 8px 30px;
}

.btnUploadImage:hover{
  background: #0184f8;
  border: 1px solid #0184f8;
  color: #ffffff;
}

.btnUploadImage:hover span svg{
  fill: #ffffff;
}

.btnUploadImage:hover span svg path{
  fill: #ffffff;
}


.cursorPointer{
  cursor: pointer;
}

.btnWhite{
  background: #fff;
  border: 1px solid #fff;
  font-size: 14px;
  color: #A2ABD1;
  padding: 8px 30px;
  border-radius: 5px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
}

.btnWhite:focus{
  font-size: 14px;
}

// .btngray{
//   background: gray;
//   border: 1px solid gray;
//   font-size: 14px;
//   color: #fff;
//   padding: 8px 30px;
//   border-radius: 5px;
//   box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
// }

// .btngray:focus{
//   font-size: 14px;
// }

// .btngray:hover{
//   background: #4092FB;
//   border: 1px solid #4092FB;
// }

.verifyBtn{
  background: #4BB772;
  border: 1px solid #4BB772;
  font-size: 14px;
  color: #fff;
  padding: 8px 30px;
  border-radius: 5px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
}

.verifyBtn:focus{
  font-size: 14px;
}


// .rightPanelHeaderBtn{
//   border: 1px solid #E8E8E8;
//   padding: 8px 30px;
//   border-radius: 100px;
//   color: #bebebe;
//   font-size: 16px;
//   background: #fff;
// }

// .rightPanelHeaderBtn:hover{
//   border: 1px solid #1C223A;
//   padding: 8px 30px;
//   border-radius: 100px;
//   color: #fff;
//   font-size: 16px;
//   background: #1C223A;
// }

// .lightBtn{
//   border: 1px solid #EFF3FF;
//   border-radius: 100px;
//   color: #A2ABD1;
//   background: #EFF3FF;
// }


// .custome-row{
//   width: 100%;
//   margin-top: 20px;
//   margin-bottom: 20px;
//   margin-left: auto;
//   margin-right: auto;
// }

// .top10{top: 10px !important;}
// .top20{top: 20px !important;}
// .top30{top: 30px !important;}
// .top40{top: 40px !important;}
// .top50{top: 50px !important;}
// .top60{top: 60px !important;}
// .top70{top: 70px !important;}
// .top80{top: 80px !important;}
// .top90{top: 90px !important;}
// .top100{top: 100px !important;}

// .pb10{padding-bottom: 10px;}
// .pb20{padding-bottom: 20px;}
// .pb30{padding-bottom: 30px;}
// .pb40{padding-bottom: 40px;}
// .pb50{padding-bottom: 50px;}
// .pb60{padding-bottom: 60px;}
// .pb70{padding-bottom: 70px;}
// .pb80{padding-bottom: 80px;}
// .pb90{padding-bottom: 90px;}
// .pb100{padding-bottom: 100px;}

// .pt10{padding-top: 10px;}
// .pt20{padding-top: 20px;}
// .pt30{padding-top: 30px;}
// .pt40{padding-top: 40px;}
// .pt50{padding-top: 50px;}
// .pt60{padding-top: 60px;}
// .pt70{padding-top: 70px;}
// .pt80{padding-top: 80px;}
// .pt90{padding-top: 90px;}
// .pt100{padding-top: 100px;}


// .p-RL10{padding: 0px 10px;}
// .p-RL15{padding: 0px 15px;}
// .p-20{padding: 0px 20px;}
// .p-RL25{padding: 0px 25px;}
// .p-RL30{padding: 0px 30px;}


.mb10{margin-bottom: 10px;}
.mb20{margin-bottom: 20px;}
.mb30{margin-bottom: 30px;}
.mb40{margin-bottom: 40px;}
.mb50{margin-bottom: 50px;}
.mb60{margin-bottom: 60px;}
.mb70{margin-bottom: 70px;}
.mb80{margin-bottom: 80px;}
.mb90{margin-bottom: 90px;}
.mb100{margin-bottom: 100px;}


.fb{background: #0F78D3;}
.tw{background: #43A8FF}
.pi{background: #EB0000}
.bl{background: #F4640C}




h3.card-title{font-size: 24px;}
h5.card-title{font-size: 16px;}
h4.card-title{font-size: 18px;}
h6.card-title{font-size: 14px;}



p.text-mutede{font-size: 16px;}
p.card-body-text{font-size: 14px;}

.text-black{color: #000;}
.text-light-blue{color: #0176ff;}


.list-style-none > li{
  list-style: none;
}

.word-break-all{
  word-break: break-all;
}

h1.heding-text{font-size: 32px;}
h2.heding-text{font-size: 24px;}
h3.heding-text{font-size: 18px;}
h4.heding-text{font-size: 16px;}
h5.heding-text{font-size: 14px}
h6.heding-text{font-size: 10px;}

p.text-10{font-size: 10px;}
p.text-12{font-size: 12px;}
p.text-13{font-size: 13px;}
p.text-14{font-size: 14px;}
p.text-15{font-size: 15px;}
p.text-16{font-size: 16px;}
p.text-18{font-size: 18px;}
p.text-24{font-size: 24px;}
p.text-28{font-size: 28px;}
p.text-32{font-size: 32px;}
p.text-34{font-size: 34px;}


.text-10{font-size: 10px;}
.text-12{font-size: 12px;}
.text-13{font-size: 13px;}
.text-14{font-size: 14px;}
.text-15{font-size: 15px;}
.text-16{font-size: 16px;}
.text-18{font-size: 18px;}
.text-24{font-size: 24px;}
.text-28{font-size: 28px;}
.text-32{font-size: 32px;}
.text-34{font-size: 34px;}

.w-100P{
  width: 100%;
}
.w-110 {
  width: 110px;
}
.w-200{
  width: 200px;
}

.w-170{
  width: 170px;
}

.w-150{
  width: 150px !important;
}

.cursorPointer{
  cursor: pointer;
}

.borderTop{
  border-top: 1px solid rgba(86, 86, 86, 0.18);
}
.borderBottom{
  border-bottom: 1px solid rgba(86, 86, 86, 0.18);
}
.borderLeft{
  border-left: 1px solid rgba(86, 86, 86, 0.18);
}
.borderRight{
  border-right: 1px solid rgba(86, 86, 86, 0.18);
}

.dblock{display: block}








/**********************************************************/
/**********************************************************/
/**********************************************************/
/**************** end anas libarary css *******************/
/**********************************************************/
/**********************************************************/
/**********************************************************/

.popupShadow{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.59);
  z-index: 99;
  display: none;
}

.popupShadowSession{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: none;
  backdrop-filter: blur(6px);
}

.plan-upgrade .popupShadow {z-index: 99999}

.popupShadow-upgradePlan {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.59);
  z-index: 99;
  display: none;
}

.popupShadow-freePlan {
  position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.59);
    z-index: 99;
    margin-left: 15%;
    display: none;
}

.popupShadow-freefullPagePlan {
  position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.59);
    z-index: 99;
    margin-left: 4%;
    display: none;
}

.incoming-outgoing-btn{
  background: #3E4360;
  border: 0;
  padding: 1rem 1rem !important;
  color: #fff;
  padding: 10px !important;
  font-size: 14px;
}

.incoming-outgoing-btn:focus{
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.incoming-outgoing-btn span.mr-4{
  width: 20px;
}

.bg-white{
  background-color: #fff;
}


// .bg-blue{
//   background-color: #4092FB;
// }

.bg-lightblack{
  background: #1C223A;
}

.bg-lightgray{
  background-color: #f2f5fa;
}

.bg-lightgraysh{
  background-color: #dadada;
}

.bg-darksecound{
  background-color: #2c3049;
}

.bg-black{
  background-color: #000000;
}

// .border-1-blue-table{
//   border: 1px solid #4092FB;
// }


// .border-1-black{
//   border: 1px solid #333;
// }


.outline-1-black{
  outline: 1px solid #dfdfe6;
outline-offset: -1px;
}


.box-shadow-chat{
  box-shadow: 10px 10px 26px rgba(128, 128, 128, 0.12);
}


.sidebar.not-livechat{
  width: 160px;
}

.left-Menu-notlivechat > ul > li > a {
  list-style: none;
  padding: 5px 10px;
  font-size: 14px;
  color: #c3c3c3;
  font-family: 'HelveticaNeueLight';
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.left-Menu-notlivechat > ul > li > a.active, .left-Menu-notlivechat > ul > li > a:hover{
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

// .Integrations-logo-colume{
//   border: 1px solid #707070;
//   border-radius: 10px;
//   padding: 20px 20px;
//   width: 100%;
//   min-height: 300px;
//   max-height: 300px;
// }

.navigation-not-liveChat{
  position: fixed;
  background: #1C223A;
  width: 62px;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 50;
  padding-top: 0px;
  overflow: visible;
  align-content: space-between;
  align-items: center;
}

.plan-upgrade .navigation-not-liveChat {z-index: 9991;}

.page-wrapper {
  //width: calc(100% - 62px);
  position: relative;
  margin-left: 62px;
  padding-top: 50px;
  transition: all 0.45s ease-out;
  -webkit-transition: all 0.45s ease-out;
  -moz-transition: all 0.45s ease-out;
  -ms-transition: all 0.45s ease-out;
  -o-transition: all 0.45s ease-out;
  //background-image:;url('https://chatbot.appypie.com/dist/img/public/img/iconbbg.jpg');
  height: 100vh;
}

.page-wrapper .main-wrapper, .chatform-Preview{
  width: 100%;
}


.sidebarnavChild {
  position: fixed;
  width: 160px;
  height: calc(100% - 50px);
  left: 62px;
  top: 50px;
  background: #2C3049;
  text-align: left;
}
.plan-upgrade .sidebarnavChild, .plan-upgrade .sidebarnavChild { z-index: 999;}
.plan-upgrade .navigation-not-liveChat {
  z-index: 999999;
}
.topbar {
  width: calc(100% - 62px);
  background: #fff;
  z-index: 50;
  position: fixed;
  margin-left: 62px;
  height: 50px;
}

// .Integrations-logo > img{
//   width: 100%;
//   height: 100%;
// }

// .Integrations-logo{
//   height: 120px;
//   width: 100%;
// }

.btn-blue{
  background: #0176ff;
  border: 1px solid #0176ff;
  padding: 5px 20px;
  border-radius: 4px;
  font-size: 14px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.btn-blue:focus{
  font-size: 14px;
}

.btn-edites {
  color: #fff;
  background: #717171;
  padding: 4px 10px;
  font-size: 12px;
  border: 1px solid #717171;
  margin-left: 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  cursor: pointer;
  font-weight: normal;
}

.header-heding h3{
  font-family: 'HelveticaNeueLight';
  color: #636363;
}

.sidebar-collapse {
  margin-left: 223px;
  transition: all 0.45s ease-out;
  width: calc(100% - 223px);
  -webkit-transition: all 0.45s ease-out;
  -moz-transition: all 0.45s ease-out;
  -ms-transition: all 0.45s ease-out;
  -o-transition: all 0.45s ease-out;
}

.widgetDeskDesignRight{
  position: absolute;
  right: 0;
  top: 63px;
  z-index: 91;
  background: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 22px;
  border-radius: 20px 0px 0px 20px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.19);
  display: none;
}

.widgetDeskDesignRight svg{
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.rotteIcon svg{
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

.right-panel-widget-show{
  margin-right: 423px;
  width: calc(100% - 646px);
}

button.btn-cus{
  margin: 0 !important;
  box-shadow: inherit !important;
  background-color: #edeef6 !important;
}
button.btn-cus:hover{
  margin: 0 !important;
  box-shadow: inherit !important;
  background-color: #edeef6 !important;
}
button.btn-cus:focus{
  margin: 0 !important;
  box-shadow: inherit !important;
  background-color: #edeef6 !important;
}
button.btn-cus:active{
  margin: 0 !important;
  box-shadow: inherit !important;
  background-color: #edeef6 !important;
}
button.btn-cus:focus-within{
  margin: 0 !important;
  box-shadow: inherit !important;
  background-color: #edeef6 !important;
}
button.btn-cus:focus-visible{
  margin: 0 !important;
  box-shadow: inherit !important;
  background-color: #edeef6 !important;
}

.main-heading h3.heding-text{
  font-family: 'HelveticaNeueBold';
}

/* Added By AG Start */

.uploading {
  position: absolute;
  display: inline-block;
  background-image: url(../img/icon/loaderUpload3.gif);
  background-repeat: no-repeat;
  width: 20px;
  height: 15px;
  top: auto;
  right: 1px;
  bottom: 6px;
  /* transform: translate(-50%, -50%); */
  /* -webkit-transform: translate(130%, -30%); */
  -moz-transform: translate(-50%, -50%);
  background-size: contain;
  z-index: 999;
}

/* For Translator start */

.translateChat{
  padding: 10px 10px;
  background: #fff;
  margin: 0px 20px;
  border: 1px solid rgb(42, 48, 73);
  position: relative;
}

span.dismissSpan{
  width: 14px;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

span.dismissSpan img.dismissIconImg{
  cursor: pointer;
  width: 100%;
  margin-top: -2px;
}

.googleTranslateIconImage > span > img{
  width: 50px;
  margin-right: 10px;
}

.googleTranslateIconImage {
  width: 30px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(14%, -46%);
  -webkit-transform: translate(14%, -46%);
  -moz-transform: translate(14%, -46%);
  -ms-transform: translate(14%, -46%);
  -o-transform: translate(14%, -46%);
}

.google-translate-new{
  position: absolute;
  left: 0;
  bottom: -20px;
  z-index: 99;
}

.google-translate-new > img{
  width: 80px;
  background: #fbfaff;
}

.languageFilter{
  position: relative;
}

.languageFilter p.detactLanguage{
  margin: 0px 10px 0px 0px;
  float: left;
  padding: 4px 0px;
  color: #2c3048;
  font-size: 12px;
  font-weight: 700;
}


.selectOptionDetactLanguage{
  float: left;
  margin-right: 10px;
  width: 90px;
}

.selectOptionDetactLanguage select{
  width: 100%;
  height: 28px;
  margin-top: 0px;
  font-size: 12px;
}

.detactLanguageBTN > button{
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 5px;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.detactLanguageBTN button.translate{
  background: #4092fb;
  color: #fff;
}

.detactLanguageBTN button.dismis{
  background:transparent;
  color: #2c3048;
}

.translateIcon{
  width: 22px;
  vertical-align: middle;
  margin-right: 10px;
  opacity: 0.4;
}

.googleTranslateIconImage > span{
  font-size: 13px;
  color: #908f8f;
}

.detactLanguageBTN button.stopTranslating{
  background:transparent;
  color: #2c3048;
  box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.34);
  -webkit-box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.34);
  -moz-box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.34);
  -o-box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.34);
}

/* For Translator End */

/* Added By AG End */



/*-------------- Scroll css start ---------------*/
.scrollbar, .screen-body, .cannedPop .CPopupBody.hashtagPop ul, .textviewSuggest .absSuggest {
  overflow-y: auto;
  padding-right: 10px;
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar-track, .screen-body::-webkit-scrollbar-track, .cstmSmartCompose::-webkit-scrollbar-track, .cannedPop .CPopupBody.hashtagPop ul::-webkit-scrollbar-track,
.textviewSuggest .absSuggest::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
.cstmSmartCompose::-webkit-scrollbar-track  {
  margin: 15px 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
}

.scrollbar::-webkit-scrollbar, .screen-body::-webkit-scrollbar, .cannedPop .CPopupBody.hashtagPop ul::-webkit-scrollbar,
.textviewSuggest .absSuggest::-webkit-scrollbar, .cstmSmartCompose::-webkit-scrollbar, .agent-table.scroll-view-tbl::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}
.agent-table.scroll-view-tbl::-webkit-scrollbar {
  height: 5px;
}
.cstmSmartCompose::-webkit-scrollbar {
  background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #4092FB;
}
/*-------------- Scroll css end ---------------*/
/*-------------- start Scroll 2 css start ---------------*/


.scroll-2{
	overflow-y: auto;
  padding-right: 10px;
  scrollbar-width: thin;
}

#scroll-2::-webkit-scrollbar-track, .agent-table.scroll-view-tbl::-webkit-scrollbar-track
{
	
	background-color: #F5F5F5;
}

#scroll-2::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}
  
#scroll-2::-webkit-scrollbar-thumb, .textviewSuggest .absSuggest::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

.scroll-2::-webkit-scrollbar-track{background-color: #2b3049;}
.scroll-2::-webkit-scrollbar{	width: 6px;background-color: #2b3049;}
.scroll-2::-webkit-scrollbar-thumb, .cannedPop .CPopupBody.hashtagPop ul::-webkit-scrollbar-thumb, .cstmSmartCompose::-webkit-scrollbar-thumb
{ background-color: #727272; border-radius:100px ; -webkit-border-radius:100px ; -moz-border-radius:100px ; -ms-border-radius:100px ; -o-border-radius:100px ; } 
/*-------------- end Scroll 2 css start ---------------*/


/*-------------- start Scroll 3 css start ---------------*/


.scroll-3
{
	overflow-x: auto;
  padding-bottom: 5px !important;
  scrollbar-width: thin;
}

#scroll-3::-webkit-scrollbar-track
{
	
	background-color: #F5F5F5;
}

#scroll-3::-webkit-scrollbar
{
	height: 3px;
	background-color: #F5F5F5;
}

#scroll-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

.scroll-3::-webkit-scrollbar-track{background-color: #fbfaff;}
.scroll-3::-webkit-scrollbar{	height: 3px;background-color: #fbfaff;}
.scroll-3::-webkit-scrollbar-thumb{ background-color: #727272; border-radius:100px ; -webkit-border-radius:100px ; -moz-border-radius:100px ; -ms-border-radius:100px ; -o-border-radius:100px ; }
/*-------------- end Scroll 3 css start ---------------*/

/*-------------- start Scroll 4 css start ---------------*/


.scroll-4{
	overflow-y: auto;
  padding-right: 10px;
  scrollbar-width: thin;
}


#scroll-4::-webkit-scrollbar-track
{
	
	background-color: #fbfaff;
}

#scroll-4::-webkit-scrollbar
{
	width: 6px;
	background-color: #fbfaff;
}
  
#scroll-4::-webkit-scrollbar-thumb, .agent-table.scroll-view-tbl::-webkit-scrollbar-thumb
{
	background-color: #CCCCCC;
}

.scroll-4::-webkit-scrollbar-track{background-color: #fbfaff;}
.scroll-4::-webkit-scrollbar{	width: 5px;background-color: #fbfaff;   }
.scroll-4::-webkit-scrollbar-thumb{background-color: #CCCCCC; border-radius:100px ; -webkit-border-radius:100px ; -moz-border-radius:100px ; -ms-border-radius:100px ; -o-border-radius:100px ; } 


/*-------------- end Scroll 4 css start ---------------*/


/*------------------------checkbox start design css----------------------*/
/* The container */

.chat-content-New{
  flex: 2 1;
}

.containerCheckBox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 4px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default checkbox */
.containerCheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #F5F5F5;
  border-radius: 4px;
  border: 1px solid #E8E8E8;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}


/* On mouse-over, add a grey background color */
// .containerCheckBox:hover input~.checkmark {
//   background-color: #ccc !important;
// }


/* When the checkbox is checked, add a blue background */
.containerCheckBox input:checked~.checkmark {
  background-color: #1b223a;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


/* Show the checkmark when checked */
.containerCheckBox input:checked~.checkmark:after {
  display: block;
}


/* Style the checkmark/indicator */
.containerCheckBox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.containerCheckBox.containerafterchecked .checkmark::after {
  left: 6px;
  top: 4px;
}
/*------------------------checkbox end design css----------------------*/

.visitore-check-new .containerCheckBox input:checked ~ .checkmark {
  background-color: #ffffff;
}

.visitore-check-new .containerCheckBox .checkmark:after{
  left: 6px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

/*------------------------radio start design css----------------------*/

/* The container */  
.containerRadio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #ACACAC;
}

/* On mouse-over, add a grey background color */
.containerRadio:hover input ~ .radioCheckmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerRadio input:checked ~ .radioCheckmark {
  background-color: #fff;
  border: 2px solid #1c96f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio input:checked ~ .radioCheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio .radioCheckmark:after {
  top: 2px;
  left: 2px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #1f96f3;
}

/*------------------------radio end design css----------------------*/


/*---------------------------------------------------*/
/*---------------------------------------------------*/
/*----------------------matrial radio button start-----------------------------*/
/*---------------------------------------------------*/
/*---------------------------------------------------*/

.material-switch>input[type="checkbox"] {
  display: none;
}

.material-switch>label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}

.material-switch>label::before {
  background: #b1dafa;
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.material-switch>label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -9px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.material-switch>input[type="checkbox"]:checked+label::before {
  background: #b1dafa;
  opacity: 1;
}

.material-switch>input[type="checkbox"]:checked+label::after {
  background: #03A9F4;
  left: 20px;
  opacity: 1;
}


/*---------------------------------------------------*/
/*---------------------------------------------------*/
/*---------------------matrial radio button End-----------------------------*/
/*---------------------------------------------------*/
/*---------------------------------------------------*/


/*----------------------------------------------------------------- Start Agent css (17 Aor 2020) ----------------------------------------------------------------------*/

.border-radius-table > tr > th:first-child{
  border-top-left-radius: 4px;
}

.border-radius-table > tr > th:last-child{
  border-top-right-radius: 4px;
}


.border-radius-table > tr:last-child > td:first-child{
  border-bottom-left-radius: 10px;
}

.border-radius-table > tr:last-child > td:last-child{
  border-bottom-right-radius: 10px;
}


.customArrow {
  -webkit-appearance: none;
  background: #fff url(../img/icon/selectArrowSvg.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 98% !important;
  padding: 0 10px;
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.customArrow:focus{
  font-size: 14px;
}

.aside-agent .customArrow{
  max-width: 140px;
  min-width: 140px;
  font-size: 12px;
  cursor: pointer;
  padding-right: 20px;
}

.input-group > input[type="text"]{
  padding: 0px 10px;
  height: 40px;
  background: #fff;
  font-family: 'HelveticaNeueLight';
  font-size: 0.9375rem;
}

.pagingnation {
  width: 475px;
  margin: 20px auto;
}

.pagingnation-list li{
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #fff;
  line-height: 36px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
  cursor: pointer;
  margin: 0px 5px 10px;
  font-size: 12px;
  font-weight: bold;
  color: #A2ABD1;
}

.pagingnation-list li a {
  color: #A2ABD1;
}

.pagingnation-list li:first-child {
  width: 105px;
}

.pagingnation-list li:last-child {
  width: 105px;
}

.pagingnation-list li:hover, .pagingnation-list li:hover a {
  color: #fff;
  background: #4092fa;
}

.pagingnation-list li.selected {
  color: #fff;
  background: #4092fa;
}

.pagingnation-list li.selected a{
  color: #fff;
}

.agent-section .pagingnation {
  text-align: center;
  margin: 20px auto;
}

.agent-section .pagingnation .pagingnation-list .nav {justify-content: center;}
.pagingnation-list li:nth-child(2), .pagingnation-list li:nth-last-child(2){
  display: none;
}

.rightPanelSection {
  width: 430px;
  position: fixed;
  right: 0;
  top: 0px;
  background: white;
  height: 100%;
  z-index: 991;
  box-shadow: 0px 0px 8px -2px rgba(51, 51, 51, 0.29);
  overflow: auto;
  z-index: 99999;
}

.white-text{
  color: #fff;
}

.black-text{
  color: #000;
}

.blue-text{
  color: #4092fa;
}

.light-gray-text{
  color: #8F979D !important;  
}

.ight-panel-header h4.header-title{
  font-family: 'HelveticaNeueBold';
}

.heder-icon-img{
  background: #fff;
  width: 25px;
  height: 25px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}

.planPopup .heder-icon-img {
  position: absolute;
  right: -15px;
  top: -15px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  background: #bf3a31;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  box-shadow: -1px 2px 2px -1px #626366;
}

.agent-rightpanel-feilds{
  display: inline-block;
  font-size: 12px;
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;

}

.agent-rightpanel-feilds input[type="file"] {
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer !important;
  width: 100% !important;
  height: 100%;
  left: 0;
  top: 0;
}


.agent-form .form-feilds .wrapperInfo input{
  font-size: 12px;
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.agent-form .form-feilds .wrapperInfo label.text-screen1{
  font-family: 'HelveticaNeueBold';
}

.text-12-select-agent select{
  font-size: 12px;
  background: transparent;
  height: 100px !important;
}

.text-12-select-agent select option{
  padding: 5px 5px;
  cursor: pointer;
}

.add-profile-img-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 5px;
}

.btn-chat-feilds{
  box-shadow: 0px -2px 5px -1px rgba(0, 0, 0, 0.32);
}

.add-profile-img-icon img{ 
  margin-left: auto;
}

.agent-profile-rightPanel .media-body h5.heding-text span{
  font-family: 'HelveticaNeueCyr-Thin';
}

.agent-profile-rightPanel .media-body h5.heding-text{
  font-family: 'HelveticaNeueMedium';
}

.agent-profile-rightPanel .media-body p.card-body-text{
  font-family: 'HelveticaNeueCyr-Thin';
}

.agent-profile-rightPanel .heder-icon-img{
  position: absolute;
  right: 0;
  top: 0;
}

.chat-status{
  flex-wrap: wrap;
}

.chat-status .chat-status-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 105px;
  border: 1px solid rgba(144, 146, 169, 0.56);
  text-align: center;
  margin-bottom: 15px;
  border-radius: 5px;
}

.chat-status .chat-status-rating-col{
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 10px);
  height: 105px;
  border: 1px solid rgba(144, 146, 169, 0.56);
  text-align: center;
  border-radius: 5px;
  margin: 5px;
}  

// .bg-blue {
//   background-color: #4092FB;
// }

.bg-lightblack {
  background: #1C223A;
}
p.error {
  color: #ff0000;
  font-size: 13px;
}

/*----------------------------------------------------------------- end Agent css (17 Apr 2020) ----------------------------------------------------------------------*/

/*-----------------------------Loader Start Here ---------------*/
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 9999999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/*----------------------------------------------------------------- end Agent css (17 Apr 2020) ----------------------------------------------------------------------*/

/*----------------------------------------------------------------- start Livechat settings css (17 Apr 2020) ----------------------------------------------------------------------*/

.settings-form-feilds .form-feilds .wrapperInfo input{
  font-size: 12px;
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.settings-form-feilds .form-feilds .wrapperInfo textarea{
  font-size: 12px;
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  padding: 10px 10px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}


.main-checkbox-all {
  position: relative;
  top: -10px;
}

.agent-checkbox {
  transform: inherit;
  padding: 0px;
  width: 20px;
  height: 28px;
  float: right;
  position: relative;
  top: 5px;
  cursor: pointer;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  -o-transform: inherit;
}

.settings-feld h4{
  color: #000;
  font-family: 'HelveticaNeueLight';
  font-weight: bold;
}

.settings-feld-post h5{
  color: #000;
}


.customArrow {
  -webkit-appearance: none;
  background: #fff url(../img/icon/selectArrowSvg.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 98% !important;
  padding: 0 10px;
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.customArrow option {
  margin-top: 10px;
  background: transparent;
}


.setting-section .setting-notification-sound h4.heding-text{
  font-family: 'HelveticaNeueBold';
}

// .previous-chats .customArrow{
//   max-width: 500px;
//   min-width: 500px;
// }

// .localization-time .wrapperInfo select{
//   width: 120px;
//   height: 40px;
// }


// .days-neme-localization{
//   padding: 10px 20px;
//   border-radius: 100px;
//   font-size: 14px;
//   display: inline-block;
// }

// .days-neme-bg{
//   background: #DDEDFF;
//   color: #1C223A;
// }


// .localization-days{
//   width: 150px;
// }

// .localization-on-off{
//   width: 100px;
// }


/*----------------------------------------------------------------- end Livechat settings css (17 Apr 2020) ----------------------------------------------------------------------*/

	
/*----------------------------------------------------------------- start analytics  css (21 Apr 2020) ----------------------------------------------------------------------*/

// .analyties-widget-filter-dyas > ul{
//   margin: 0px;
//   padding: 0px;
// }
 
// .analyties-widget-filter-dyas > ul > li {
//   list-style: none;
//   border: 1px solid #4D4E5C;
//   padding: 5px 15px;
//   font-size: 14px;
//   color: #4D4E5C;
//   margin: 0;
//   float: left;
//   border-left: 0;
//   transition: all 0.45s ease-in-out;
//   -webkit-transition: all 0.45s ease-in-out;
//   -moz-transition: all 0.45s ease-in-out;
//   -ms-transition: all 0.45s ease-in-out;
//   -o-transition: all 0.45s ease-in-out;
//   cursor: pointer;
// }

// .analyties-widget-filter-dyas > ul > li:hover, .analyties-widget-filter-dyas > ul > li.active{
//   background: #4D4E5C;
//   color: #fff;
// }

// .analyties-widget-filter-dyas > ul > li:first-child{
//   border-left: 1px solid #4D4E5C;
//   border-radius: 4px 0px 0px 4px;
//   -webkit-border-radius: 4px 0px 0px 4px;
//   -moz-border-radius: 4px 0px 0px 4px;
//   -ms-border-radius: 4px 0px 0px 4px;
//   -o-border-radius: 4px 0px 0px 4px;
// }

// .analyties-widget-filter-dyas > ul > li:last-child{
//   border-radius: 0px 4px 4px 0px;
//   -webkit-border-radius: 0px 4px 4px 0px;
//   -moz-border-radius: 0px 4px 4px 0px;
//   -ms-border-radius: 0px 4px 4px 0px;
//   -o-border-radius: 0px 4px 4px 0px;
// }

// .analyties-widget .customArrow{
//   width: 300px;
// }

// .analyties-chat .customArrow{
//   width: 150px;
// }

// .analytices-duration-list h3 span.small {
//   font-size: 60.967%;
// }

// .analytices-duration{
//   width: 100%;
// }

// .analytices-chats-details {
//   min-height: 150px;
// }

// .analyties-chat h5.heding-text{
//   color: #636363;
// }


/*----------------------------------------------------------------- end analytics  css (21 Apr 2020) ----------------------------------------------------------------------*/




/*----------------------------------------------------------------- start chat History  css (28 Apr 2020) ----------------------------------------------------------------------*/

.chat-his-name span.name-chat-header{
  color: #fff;
}

.chat-his-name .user-showings{
  right: 0;
}

.chat-his-name .user-showings li{
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-left: -12px;
}


.chat-his-name .bodyRightPanel {
  height: calc(100vh - 200px);
}
.chat-his-name .bodyRightPanel.b-space {
  height: calc(100vh - 70px);
}

.profile-rightpanel .bodyRightPanel {
  height: calc(100vh - 100px);
}


.chat-his-name .addNoteHight {
  height: calc(100vh - 60px);
}

.chat-his-chat .chat-content ul .position-relative:last-child {
  margin-bottom: 25px !important;
}

/*----------------------------------------------------------------- end chat History  css (28 Apr 2020) ----------------------------------------------------------------------*/

.input-group > input, .input-group > input:focus, .input-group > input:active{
  background: #fff;
  border-right: 0;
}

.input-group .input-group-append button{
  border-left: 0;
  background-color: #fff !important;
}

.titleSDesign {
  margin: 0;
  position: relative;
  padding: 0px 20px;
  font-size: 12px;
  text-align: center;
}

.titleSDesign span:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 8px;
  border-top: 1px solid rgba(51, 51, 51, 0.25);
  width: 30%;
}

.titleSDesign span:after {
  content: '';
  position: absolute;
  right: 0px;
  top: 8px;
  border-top: 1px solid rgba(51, 51, 51, 0.25);
  width: 30%;
}

/*--------- start add agent listing css --------------*/

.newaddToAgentList {
  height: calc(100vh - 370px);
  overflow-y: auto;
  margin-top: 15px;
  padding-right: 5px;
  overflow-x: hidden;
}

.newaddToAgentList.onlineAgent {
  height: calc(100vh - 160px);
  overflow-y: auto;
  margin-top: 15px;
  padding-right: 5px;
}

.newaddToAgentList > ul > li {
  list-style: none;
  cursor: pointer;
  clear: both;
  width: 100%;
  display: inline-block;
  align-content: center;
  position: relative;
  padding-left: 0;
  background: #fff;
  padding: 10px 10px;
  border: 1px solid #c6c6c6;
  margin: 2px 0px;
  border-radius: 4px;
}

.newaddToAgentList.onlineAgent > ul > li{
  padding: 5px 5px;
  border: 0px solid transparent;
  background: transparent;
  cursor: default !important;
}
.newaddToAgentList.onlineAgent > ul > li > span{position: relative;}
.onlineAgent .newaddToAgentListName p {
  font-weight: normal;
}

.online-Agents{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  background: #5fc100;
  border-radius: 100%; 
}



.online-agentSpan{
  width: 33px;
  height: 33px;
  background: #3e4360;
  font-size: 11px;
  text-align: center;
  display: block;
  line-height: 33px;
  color: #fff;
  border-radius: 100px;
  margin-right: 10px;
  float: left;
  position: relative;
}

.online-agentSpan img{
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  margin-top: -4px;
}

.newaddToAgentList ul li strong {
  width: 33px;
  height: 33px;
  background: #3e4360;
  font-size: 11px;
  text-align: center;
  display: block;
  line-height: 33px;
  color: #fff;
  border-radius: 100px;
  margin-right: 10px;
  float: left;
  position: relative;
}

.newaddToAgentList > ul > li .newaddToAgentListName {
  width: calc(100% - 43px);
  float: left;
}

.newaddToAgentListName p {
  margin: 0px;
  padding: 2px 0px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #3e4360;
  width: calc(100% - 10px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.addAgentCheckbox {
  -ms-transform: scale(1.8);
  -moz-transform: scale(1.8);
  -webkit-transform: scale(1.8);
  -o-transform: scale(1.8);
  transform: scale(1.8);
  padding: 0px;
  width: 20px;
  height: 28px;
  float: right;
  position: relative;
  top: 2px;
  cursor: pointer;
}

#addagent {
  display: inline-block;
  width: 100%;
}

.addAgentUpClickCrose {
  margin: 0px;
  padding: 10px 0px 0px 0px;
  overflow-y: hidden;
  width: 100%;
  height: auto;
  margin-bottom: 0px;
}

.addAgentUpClickCrose > ul {
  margin: 0px;
  padding: 0px;
  max-height: 85px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
}

.addAgentUpClickCrose > ul > li {
  list-style: none;
  display: inline-block;
  position: relative;
  margin: 10px 4px;
}

.addAgentUpClickCrose > ul > li > a {
  position: relative;
  display: inline-block;
  cursor: default;
}

.addAgentUpClickCrose > ul > li > span {
  position: relative;
  display: inline-block;
  cursor: default;
}

.addAgentUpClickCrose > ul > li > a > span, .addAgentUpClickCrose > ul > li > span > span {
  position: absolute;
  color: #fff;
  background: #4092fa;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  right: 0px;
  top: -5px;
  cursor: pointer;
  z-index: 9;
}

.addAgentUpClickCrose > ul > li > a > strong, .addAgentUpClickCrose > ul > li > span > strong {
  background: #3e4360;
  color: #fff;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
}

.addAgentUpClickCrose > ul > li > a > p, .addAgentUpClickCrose > ul > li > span > p {
  width: 40px;
  height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-size: 13px;
  color: #848080;
  text-align: center;
}

.newadd {
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.newadd > button {
  background: #3e4360;
  color: #fff;
  cursor: pointer;
  padding: 8px 30px;
  border-radius: 6px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  margin: auto;
  border: 1px solid #3e4360;
}

/*--------- end add agent listing css --------------*/




/*------------------09-may-2020 start--------------------*/

.select-search-drpdown input{
  height: 40px;
  width: 100%;
  padding: 0px 10px;
}

.select-search-drpdown .down-ser-icon-sel{
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  line-height: 40px;
  cursor: pointer;
}

.select-search-drpdown .down-ser-sel{
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  line-height: 36px;
  cursor: pointer;
}

/*------------------09-may-2020 End-------------------------*/
/*------------------12-may-2020 start-------------------------*/

.profile-nav{
  position: absolute;
  left: 62px;
  bottom: 0;
  background: #fff;
  box-shadow: 0px 2px 5px 4px rgba(51, 51, 51, 0.12);
  border-radius: 0px;
  width: 250px;
  z-index: 9999;
}

.profile-nav:before {
  content: '';
  position: absolute;   
  left: -20px;     
  bottom: 35px;
  border: 10px;
  border-style: solid;
  border-color: transparent #ffffff transparent transparent;
}

/*------------------12-may-2020 End-------------------------*/




/*------------------13-may-2020 start Login-------------------------*/

.loginBG { 
  background-image: url(../images/loginBg.png);
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  position: relative;
}
.loginBG.bg-design {
  background: url(../images/widget-bg.png);
  background-position: top center;
}


.login-header {
  width: 100%;
  margin: auto;
  padding: 10px 0px;
}

.login-header img.loginHeaderLogo {
  width: 235px;
}

.loginBody {
  width: 991px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

.login-description h5 {
  text-align: center;
  margin: 0;
  font-size: 52px;
  font-family: 'Seravek';
  color: #000;
  line-height: 50px;
  font-weight: normal;
}

.signup-description h5{
  font-size: 42px;
}

.company-information1 .login-description h5{
  font-size: 52px;
  font-family: 'Seravek';
}

.login-body {
  margin: 20px 0px 0px 0px;
  display: inline-block;
  width: 100%;
}

.login-body .login-social {
  width: calc(100% - 500px);
  float: left;
  padding: 10px;
}

.login-body .login-form {
  width: 500px;
  float: left;
  padding: 10px 50px 10px 30px;
  position: relative;
  font-family: 'Nunito', sans-serif;
}

.login-body .login-form {
  width: 500px;
  float: left;
  padding: 10px 50px 10px 30px;
  position: relative;
}

.login-form > div {
  width: 100% !important;
  font-family: 'HelveticaNeueLight';
  color: #333;
  font-weight: bold;
  font-size: 14px;
}

.Loginwarning {
  color: red;
  font-size: 12px;
  margin-top: 20px;
}

.socialBtnCenter {
  margin: 0px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  height: 300px;
}

.socialMediaBtnLink {
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 40px;
}

.socialLink > span {
  display: block;
  text-align: left;
  padding: 2px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.socialLink > span.gp {
  background: #4092fb;
  color: #fff;
  padding-right: 20px;
  font-family: 'Nunito', sans-serif;
}

.socialLink > span > img {
  width: 45px;
  margin-right: 10px;
}

.seureArea {
  margin: 0px 0px !important;
  color: #439b30 !important;
  font-size: 14px !important;
  text-align: center;
  font-family: 'Nunito', sans-serif;
}

.seureArea > img {
  width: 22px;
  vertical-align: bottom;
  margin-right: 5px;
}

.orLine::after {
  content: "Or";
  position: absolute;
  right: 0;
  top: 35%;
  background: #fff;
  transform: translate(50%, 100%);
  -webkit-transform: translate(50%, 100%);
  -moz-transform: translate(50%, 100%);
  color: #888787;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
}

.orLine {
  position: relative;
  border-right: 1px solid #d9d9d9;
}

.logininfoIcon {
  position: absolute;
  right: 10px;
  bottom: 10px !important;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
}

.Loginwarning > img {
  width: 18px;
  margin-right: 10px;
  vertical-align: sub;
}

.Loginwarning {
  color: red;
  font-size: 12px;
  margin-top: 0px;
}

.loginSignupBTN {
  background-color: #4092fb;
  width: 100%;
  margin: 30px 0px 10px 0px;
  cursor: pointer;
  padding: 5px 0px;
  border-radius: 100px;
  font-size: 14px;
}

.singnInText {
  text-align: center;
  color: #fff;
  font-size: medium;
  font-weight: bold;
  padding: 6px;
}

.forgotOrSignupLoginPage {
  text-align: center;
}

.forgotOrSignupLoginPage p {
  margin: 0px 0px;
  text-align: left;
  font-size: 14px;
  color: #000;
  font-family: 'HelveticaNeueLight';
}

.forgotOrSignupLoginPage p a{
  cursor: pointer;
}

.forgotOrSignupLoginPage a.signup {
  color: #007bff;
  cursor: pointer;
}

.forgotOrSignupLoginPage a.login {
  color: #007bff;
  cursor: pointer;
}

.forgotOrSignupLoginPage a{
  color: #007bff;
  cursor: pointer;
}


.forgotOrSignupLoginPage p a:hover{
  cursor: pointer;
  color: #007bff !important;
}

.loginPassInfo{position: relative;}


.titleShowHover {
  position: absolute;
  // width: 200px;
  width: max-content;
  background: #fff;
  right:0px;
  top: 20px;
  box-shadow: 0px 0px 2px 1px rgba(128, 128, 128, 0.43);
  z-index: 99;
  padding: 10px 10px;
  border-radius: 6px;
  font-size: 10px;
  color: #7b7b7b;
  display: none;
  z-index: 99;
}

.titleShowHoverWidth{
  width: 200px;
}


.titleShowHover::after {
  content: '';
  background: #fff;
  display: block;
  position: absolute;
  top: -9px;
  right: 5px;
  width: 12px;
  height: 12px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 0% 0;
  -ms-transform-origin: 0% 0;
  transform-origin: 0% 0;
  z-index: 1;
  box-shadow: -1px -1px 0px rgba(128, 128, 128, 0.66);
  -moz-box-shadow: -1px -1px 0px rgba(128, 128, 128, 0.66);
  -webkit-box-shadow: -1px -1px 0px rgba(128, 128, 128, 0.66);
  display: none;
}

.titleShowHover h6{
  font-size: 10px;
  color: rgba(0, 0, 0, 0.49);
}

.titleShowHover h6 strong{
  color: #000;
}

.header-tooltip-3 .titleShowHover{
  right: auto;
  left: 0;
}

.checkbox-reg{
  font-size: 14px;
  font-family: 'HelveticaNeueLight';
  color: #333;
  font-weight: bold;
}

.checkbox-reg a{
  color: #4092fa;
}

.checkbox-reg a:hover{
  text-decoration: none
}

.forgotOrSignupLoginPage {
  text-align: center;
  font-size: 14px;
}

/*------themes login feilds-------*/

// .MuiFormLabel-root{
//   color: #000 !important;
// }
// .MuiFormLabel-root.Mui-focused{color: #000 !important;}
// .MuiInputLabel-formControl {
//   top: auto;
//   left: auto;
//   position: relative;
//   text-align: left;
//   margin: 0;
//   transform: translate(0, 35px) scale(1);
//   -webkit-transform: translate(0, 35px) scale(1);
//   -moz-transform: translate(0, 35px) scale(1);
//   -ms-transform: translate(0, 35px) scale(1);
//   -o-transform: translate(0, 35px) scale(1);
// }

// .MuiInputBase-input{
//   font-size: 14px;
//   text-align: left;
//   border-bottom: 1.5px solid #000 !important;
// }


// .MuiFormControl-root{
//   width: 100%;
// }

.forgotBody {
  width: 500px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

.forgotBody .login-description h5 {
  font-size: 42px;
  text-align: center;
  margin: 0;
  font-family: "Nunito";
  font-weight: 500;
}


/*.forgotBody .login-description p {
  font-size: 18px;
  text-align: center;
  margin: 0;
  font-family: "Nunito";
  font-weight: 500;
}*/

.forgotBody .login-description p{
  text-align: center;
  color: #000;
  font-family: 'HelveticaNeueLight';
}

ul.pass-ver li, .pass-reqir{
  font-size: 15px;
  color: #000;
  font-weight: bold;
  font-family: 'HelveticaNeueLight';
  list-style-type: disc;
  padding: 2px 0px;
}

.forgotBody .login-body {
  margin: 10px 0px;
  display: inline-block;
  width: 100%;
}

.forgotBody .login-body .login-form {
  width: 500px;
  float: none;
  padding: 10px 0px 10px 0px;
  position: relative;
  margin: auto;
}

.forgotOrSignupLoginPage {
  text-align: center;
}

.verificationCodeSection {
  position: relative;
  width: 100%;
  height: 100%;
}

.verificationCode {
  margin: auto;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.clickHereVerfCode{
  margin: 0;
  font-size: 14px;
  text-align: center;
  color: #4294fa;
  cursor: pointer;
  font-weight: 600;
  font-family: "Nunito";
}

.verificationCodePara h4 {
  font-size: 52px;
  text-align: center;
  font-family: 'Seravek';
  font-weight: normal;
}

.verificationCodePara22 h4{
  font-size: 32px;
}

.verificationCodePara p {
  margin: 0;
  font-size: 22px;
  font-weight: normal;
  text-align: center;
  color: #333;
  font-family: 'HelveticaNeueLight';
}

.verifictionCodeFeilds {
  text-align: center;
  margin: 30px auto;
  width: 550px;
  display: table;
}

.feildsInputs {
  padding: 0px 5px;
}

.feildsInputs input[type="text"] {
  height: 70px;
  text-align: center;
  font-size: 2.5rem;
  border: 1px solid #333;
  border-radius: 8px;
  background: 0 0;
  box-shadow: none;
  width: 100%;
  max-width: 100%;
  margin: 0px;
  font-family: "Nunito";
}

.verifictionDownEnter p {
  margin: 0;
  font-size: 14px;
  text-align: center;
  color: #333;
  margin: 5px 0px;
  font-family: 'HelveticaNeueLight';
  font-weight: bold;
}

.verifictionDownEnter h5 {
  margin: 0;
  font-size: 14px;
  text-align: center;
  color: #4294fa;
  cursor: pointer;
  font-family: 'HelveticaNeueLight';
  font-weight: bold;
  display: inline-block;
  text-decoration: underline;
}

.verifictionDownEnter p .clickHereVerfCode {
  color: #4294fa;
  display: inline-block;
  cursor: pointer;
  padding: 0px 5px;
  font-family: 'HelveticaNeueLight';
  font-weight: bold;
}

/*------------------13-may-2020 End Login-------------------------*/



/*------------------ footer Login start -------------------------*/
.loginFooterReview {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}

.review-companies {
  margin-top: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}

.reviewcompanieswrapper {
  position: relative;
  text-align: center;
  opacity: 0.6;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

.reviewcompanieswrapper::after {
  content: "";
  background: #e6e6e6;
  height: 90%;
  top: 0;
  width: 1px;
  position: absolute;
  left: 140%;
  margin: auto;
  opacity: 0.1;
  bottom: 0;
}

.loginImgFilter .reviewcompanieswrapper::after {
  content: "";
  border: 0;
}

.reviewcompaniesimg {
  width: 100px;
  height: 30px;
  margin-bottom: 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}

.reviewcompaniesimg img {
  width: auto;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
}

.loginImgFilter .reviewcompaniesimg img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  opacity: 1;
}

/*------------------ footer Login end -------------------------*/

.registrationImgFilter .reviewcompaniesimg img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  opacity: 1;
}

.reviewcompaniesimg img {
  width: auto;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
}

.reviewcompanieswrapper:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.form-feilds .wrapperInfo input, .form-feilds .wrapperInfo textarea {
  font-size: 12px;
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  
}


.colorwrapperInfo > .color-feilds{
  font-size: 12px;
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.color-feilds > span{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid #ccc;
    margin: 5px 0px;
    cursor: pointer;
}


.form-feilds .wrapperInfo textarea{
  height: auto;
  line-height: 40px;
}
textarea.tarea-lh {line-height: 40px;}

.mixListingPreview {
  position: absolute;
  right: 20px;
  padding-top: 20px;
}

.mixListingPreview > ul {
  margin: 0;
  text-align: center;
}

.mixListingPreview > ul > li {
  list-style: none;
  display: block;
  padding: 10px 10px;
  cursor: pointer;
  position: relative;
  border: 0px solid #4d4e5c;
  width: auto !important;
  font-family: "Nunito";
  font-weight: 400;
  font-size: 14px;
  background: #9e9e9e;
  margin-bottom: 4px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.mixListingPreview > ul > li.actives {
  background: #4d4e5c;
  color: #fff;
} 

.device {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.device img {
  width: 100%;
  height: 100%;
}


.chat-forms .settings-feld h6 {
  color: #000000;
  font-family: 'HelveticaNeueLight';
  font-weight: bold;
}


.categoryHeaderLeft>ul {
  margin: 0px;
  padding: 0px;
}
.categoryHeaderLeft>ul>li {
  list-style: none;
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
}
.categoryHeaderRight>ul {
  margin: 0px;
  padding: 0px;
}
.categoryHeaderRight>ul>li {
  list-style: none;
  margin-right: 5px;
  display: inline-block;
  cursor: pointer;
}

.code-widget-copy textarea{
  padding: 20px;
  font-size: 16px;
  width: 100%;
  border: 1px solid rgba(117, 117, 117, 0.26);
  background: #f1f6f8;
  resize: none;
  color: #636363;
  border-radius: 4px;
  overflow: hidden;
}

.mail-widget input{
  padding: 10px 150px 10px 0px;
  font-size: 14px;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #333;
  width: 100%;
}



.mail-widget > button{
  position: absolute;
  right: 0;
  top: 0;
}

.widget-Or:after {
  content: '';
  position: absolute;
  border-bottom: 1px solid rgba(158, 158, 158, 0.53);
  width: 100%;
  left: 0;
  top: 44px;
}

.widget-Or > span {
  background: rgb(255, 255, 255);
  padding: 2px 5px;
  position: relative;
  width: 100%;
  z-index: 9;
  color: #8F979D;
  font-family: 'HelveticaNeueMedium';
}

p.widget-Or {
  width: 300px;
  margin: auto;
}

.custmize-your-widget-heading{   
  width: 80%;
  margin: auto;
}

.custmize-your-widget{
  width: 100%;
}

// .custimize-livechat{
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
.custimize-livechat .install-chat img {
  max-width: 220px;
}
.custimize-livechat .install-chat .link-style {
  color: #868686;
  font-weight: bold;
  text-decoration: none;
}
.custimize-livechat .install-chat .link-style:hover {
  color: #0184f8;
  // font-weight: bold;
  text-decoration: underline;
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
}


.congratulation h4 {
  font-size: 52px;
  font-family: 'Seravek';
  line-height: 1.2
}


/*
.custmize-your-widget{
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
*/

.setting-section h5.card-title, .setting-section h6.card-title{
  font-family: 'HelveticaNeueLight';
  color: #000;
}

.popupHeader{
  background: #1c223a;
  border-radius: 4px 4px 0px 0px;
}

.popupLivechat{
  display: block;
  width: 500px;
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 5px 1px rgba(128, 128, 128, 0.15);
  border-radius: 4px 4px 8px 8px;
  max-height: calc(100vh - 10px);
  z-index: 9999;
  -webkit-border-radius: 4px 4px 8px 8px;
  -moz-border-radius: 4px 4px 8px 8px;
  -ms-border-radius: 4px 4px 8px 8px;
  -o-border-radius: 4px 4px 8px 8px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.popupInstallLivechat.popupLivechat.revoke-consent {
  z-index: 99999;
}

.popupLivechat.planPopup  {max-width: 400px;}
.popupLivechat .popupBody {
  max-height: calc(100vh - 122px);
  overflow: auto;
}

.popupLivechat.login-popup {z-index: 999999;}

.congratulationPopup{
  padding: 40px;
  width: 500px;
  background-image: url('../images/cong-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -ms-border-radius: 20px 20px 20px 20px;
  -o-border-radius: 20px 20px 20px 20px;
}


.congratulationPopup2{
  padding: 40px;
  width: 80%;
  background-image: inherit;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -ms-border-radius: 20px 20px 20px 20px;
  -o-border-radius: 20px 20px 20px 20px;
  position: inherit !important;
  left: auto;
  top: auto;
  margin: 50px auto;
  transform: inherit;
  height: calc(100vh - 150px);
}

.congratulationPopup2 .popupBody{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.congratulationPopup2 .popupBody .feilds-instruction h1{
  font-size: 44px;
  font-family: 'Seravek-Medium';
}

.congratulationPopup .congratilation-circle{
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  border: 5px solid #76c4ff;
  border-radius: 50%;
  padding: 10px;
}


.congratulationPopup .congratilation-circle svg{
  width: 100px;
  height: 100px;
}

.feilds-instruction input, .feilds-instruction textarea{
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.17);
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 14px;
}

.setup-list-design > ul > li {
  list-style: none;
  position: relative;
  padding-left: 30px;
  padding-bottom: 30px;
}

.setupScriptCodeArea {
  background: #f1f6f8;
}

.scriptCodeSetup {
  background: transparent;
  border: none;
  width: 100%;
  outline: none;
  color: #636363;
  font-size: 12px;
}

.trouble-shotes > ol > li, .trouble-shotes > ul > li{
  font-size: 14px;
}

.setup-list-design > ul > li:before {
  content: "";
  position: absolute;
  left: -15px;
  width: 30px;
  height: 30px;
  font-size: 12px;
  border: 2px solid #979797;
  text-align: center;
  line-height: 28px;
  border-radius: 100%;
  background: #fff;
  z-index: 1;
  color: #979797;
  font-weight: bold;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.setup-list-design > ul > li.active:before {
  content: "";
  border: 2px solid #6bc259;
  color: #6bc259;
}

.setup-list-design > ul > li:first-child::before {
  content: "1";
}

.setup-list-design > ul > li:nth-child(2)::before {
  content: "2";
}

.setup-list-design > ul > li:nth-child(3)::before {
  content: "3";
}

.setup-list-design > ul > li:after {
  content: "";
  position: absolute;
  border-left: 2px dashed #979797;
  height: 100%;
  top: 20px;
  left: 0px;
}

.setup-list-design > ul > li.active:after {
  content: "";
  border-left: 2px dashed #6bc259;
}  

.setup-list-design > ul > li:last-child:after {
  border-left: 0;
}

.widgetWarning{
  border: 1px solid #fd023a;
  color: #fd023a;
  font-weight: normal;
  padding: 6px 20px;
  font-size: 12px;
  margin-left: 5px;
  border-radius: 3px;
}

.widgetWarningImage {
  width: 24px;
}
   
.widgetsucess{
  border: 1px solid #6bc259;
  color: #6bc259;
  font-weight: normal;
  padding: 6px 20px;
  font-size: 12px;
  margin-left: 5px;
  border-radius: 3px;
}

.congratilation-circle {
  display: inline-block;
}

// .Toastify__toast-container--top-right, .Toastify__toast-container--top-center {
//   top:4em !important;
// }

.website-verifylink-feilds{
  width: 50%;
}

.left-widgetsetings-box{
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}


.chatReportCol .chatReport-total-chats{
  padding: 20px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.chatReportCol .chatReport-total-chats .online-time {
  justify-content: center;
  align-items: baseline;
}

.widget-seetings-right{
  position: fixed;
  right: 0;
  width: 423px;
  height: calc(100% - 50px);
  background: #fff;
  box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.06);
  z-index: 49;
}

.backgrounds-color-tab > span{
  display: inline-block;
  border: 1px solid #4D4E5C;
  font-size: 14px;
  width: 100px;
  text-align: center;
  height: 35px;
  line-height: 33px;
  cursor: pointer;
}


.backgrounds-color-tab.backgrounds-color-agent span{
  width: 130px;
}

.backgrounds-color-tab .active, .backgrounds-color-tab > span:hover{
  background: #2d3049;
  color: #fff;
  border-color: #2d3049;
}

.backgrounds-color-tab .enabled, .background-color-prechat > span:hover{
  background: #2d3049;
  color: #fff;
  border-color: #2d3049;
}

.backgrounds-color-tab > span:first-child{ border-radius:4px 0px 0px 4px; -webkit-border-radius:4px 0px 0px 4px; -moz-border-radius:4px 0px 0px 4px; -ms-border-radius:4px 0px 0px 4px; -o-border-radius:4px 0px 0px 4px; }
.backgrounds-color-tab > span:last-child{ border-radius:0px 4px 4px 0px; -webkit-border-radius:0px 4px 4px 0px; -moz-border-radius:0px 4px 4px 0px; -ms-border-radius:0px 4px 4px 0px; -o-border-radius:0px 4px 4px 0px; }

div.custom-control-right .custom-control-label::after{
  right: -1.5rem;
  left: auto;
}
div.custom-control-right .custom-control-label::before {
  right: -2.35rem;
  left: auto;
}
div.custom-control-right .custom-control-label{
  padding-top: 3px;
  font-size: 12px;
  cursor: pointer;
  font-family: 'HelveticaNeueLight';
  color: #000;
}

div.custom-control-right{
  padding-right: 40px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4BB772;
  background-color: #4BB772;
}

.widthget-setup-emaile-livechat p:hover{
  color: #1B85FC;
  cursor: pointer;
}

.widthget-setup-emaile-livechat p:hover svg{
  fill: #1B85FC;
}

.message-message-first{
  width: 100%;
  max-width: 80%;
  margin: auto;
}

.question-sections-first{
  width: 100%;
}

.message-message-first2{
  min-width: 100%;    
  max-width: 100%;
  width: 100%;
}



.idle-section .settings-form-feilds{
  background: #f2f5fa;
  margin-bottom: 20px;
  border-radius: 5px;
  width: calc(100% - 65px);
}

// .__react_component_tooltip {
//   max-width: 60vw !important;
// }

.__react_component_tooltip{
  max-width: 300px;
}


// .info-his-icon{
//   position: absolute;
//   right: -30px;
//   top: 7px;
//   z-index: 9999999999;
// }


.mobile-menu{
  display: none;
}



.search-feilds-history .wrapperInfo .customArrow{
  border: 0;
  width: 130px !important;
  border-radius: 0;
  border-right: 1px solid #e6e6e6 !important;
  font-size: 14px;
}


.search-feilds-history{
  width: calc(100% - 300px);
  border: 1px solid #dcdcdc;
  height: 50px;
  border-radius: 5px;
  margin-right: 5px;
}
.search-feilds-history.cstm-widthfull {
  width: calc(100% - 60px);
}
.histroy-filter {
  margin-right: auto;
}

.search-feilds-history #searchvisitor{
  border-radius: 0;
  font-size: 14px;
  border: 0px;
  font-family: 'HelveticaNeueLight';
  padding: 0px 10px;
  outline: 0 !important;
  color: #000;
  height: 48px;
}

.search-feilds-history input#searchvisitor:focus{
  border-radius: 0;
  font-size: 14px;
  border: 0px;
  font-family: 'HelveticaNeueLight';
  padding: 0px 10px;
  outline: 0 !important;
  color: #000;
}

.search-feilds-history .select-search-drpdown{
  border-left: 1px solid #ddd;
}

.search-feilds-history .select-search-drpdown .input-group-append .btn-secondary{
  background-color: #4092fa !important;
  border: 0px !important;
  border-radius: 0px 3px 3px 0px !important;
  width: 50px;
  -webkit-border-radius: 0px 3px 3px 0px !important;
  -moz-border-radius: 0px 3px 3px 0px !important;
  -ms-border-radius: 0px 3px 3px 0px !important;
  -o-border-radius: 0px 3px 3px 0px !important;
}

.search-feilds-history .select-search-drpdown .input-group-append .btn-secondary:focus{ 
  width: 50px !important;
}
.search-feilds-history .select-search-drpdown .input-group-append .btn-secondary:hover,
.search-feilds-history .select-search-drpdown .input-group-append .btn-secondary:focus {
  background-color: #4092fa !important;
}

.chatReportCol .chatReport-total-chats span.title-total-chat{
  color: #333333;
  font-size: 14px;
}

.chart_center_M{
  width: 75%;
  margin: auto;
}

.chatReportCol .chatReport-total-chats .numberReport{
  font-family: 'HelveticaNeueBold';
  font-size: 28px;
  color: #000;
  margin-right: 5px;
}

span.gridTileOption {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #efefef;
  line-height: 50px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;  
}

span.gridTileOption:hover{
  background: #4092f9 !important;
}

span.gridTileOption:hover svg{
  fill: #fff;
}

span.gridTileOption:hover svg path{
  fill: #fff;
}

.search-feilds-history .historey-serch-drp{
  width: 250px;
}

.search-feilds-history .historey-serch-drp .dropdown-toggle{
  background: transparent;
  border-radius: 0;
  border: 0;
  padding: 0px 10px;
  font-size: 14px;
  text-align: left;
  color: #000;
  display: block;
  cursor: pointer;
}  

.dropdown-item{
  color: #000;
  font-size: 13px;
}

.dropdown-item:hover, .dropdown-item:focus{
  background-color: #CEE3FF;
  border-radius: 0;
  color: #000;
}

.historey-serch-drp .dropdown-menu{
  border: 1px solid #CCCCCC;
  box-shadow: initial;
  width: 100%;
  color: #7F808D;
  background: #fbfaff;
}
.dropdown-item {
  color: #7F808D;
  font-size: 13px;
}

input[readonly] {
  background-color: #e9ecef;
}

textarea[readonly], select[disabled] {
  background-color: #e9ecef !important;
}

.disabled-checkbox {
  opacity: 0.6;
}

#setuppage textarea#myInputCopy {
  background: transparent !important;
}
.forem-rightPanel .rightFeildsTitle {
  cursor: pointer;
  position: relative;
  padding: 10px 0px;
}

.forem-rightPanel .rightFeilds {
  margin-left: 20px;
  width: calc(100% - 20px);
  margin-bottom: 20px;
}

.forem-rightPanel .rightFeildsTitle > span {
  display: inline-block;
  font-size: 14px;
  color: #000000;
  font-weight: normal;
  position: absolute;
  left: 20px;
}

.forem-rightPanel .containerCheckBox .title {
  font-size: 13px;
  display: inline-block;
  vertical-align: super;
  position: relative;
      top: 4px;
}

.from-to-date label{
  font-size: 14px;
  margin: 0;
  display: block;
}

.date-from-inpto input{
  font-size: 12px;
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.ml-auto{
  margin-left: auto;
}

.date-from-inpto .react-datepicker-wrapper, .date-from-inpto{
  width: 100%;
}

.mr-5px{
  margin-right: 10px;
}


.message-content-his{
  width: 100%;
}
.no-recordFound p {
  font-family: 'Roboto', sans-serif;
  margin: 20px 0px;
  font-size: 16px;
  font-weight: 700;
  color: #2C2C2C;
}


.editpicture {
  display: inline-block;
  width: 100%;
  padding: 10px 0px;
  background: #fff;
  margin: 4px 0px;
  border-radius: 2px;
  position: relative;
  min-height: 80px;
}

.editpictureInner {
  width: 60px;
  height: 60px;
  position: relative;
  border-radius: 100%;
  display: inline-block;
}

.editpictureInner > img {
  width: 100%;
  object-fit: cover;
  border-radius: 100%;
  height: 100%;
  box-shadow: 0px 2px 5px 2px rgba(128, 128, 128, 0.65);
  -webkit-box-shadow: 0px 2px 5px 2px rgba(128, 128, 128, 0.65);
  -moz-box-shadow: 0px 2px 5px 2px rgba(128, 128, 128, 0.65);
  -o-box-shadow: 0px 2px 5px 2px rgba(128, 128, 128, 0.65);
}


.clearIMG {
  cursor: pointer;
  position: absolute;
  background: #1b223a;
  color: #fff;
  width: 20px;
  height: 20px;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  border-radius: 100%;
  right: -8px;
  top: -8px;
}

.veryfy-your-mail-form{
  width: 70%;
  margin: auto;
}

.veryfy-your-mail-form div.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal{
  width: 100%;
}

.veryfy-your-mail-form-cutome .form-group input.form-control{
  background: transparent;
  border-radius: 0;
  font-size: 14px;
  border: 0;
  border-bottom: 1.5px solid #000;
  color: #000;
  padding: 0px 0px;
  padding-right: 40px !important;
}

.veryfy-your-mail-form-cutome .form-group input.form-control::placeholder { 
  color: #000;
}
.veryfy-your-mail-form-cutome .form-group input.form-control:-ms-input-placeholder { 
  color: #000;
}
.veryfy-your-mail-form-cutome .form-group input.form-control::-ms-input-placeholder { 
  color: #000;
}
.veryfy-your-mail-form-cutome .main-checkbox-all .containerCheckBox .checkmark{    border: 1px solid #000;}

.veryfy-your-mail-description p{
  text-align: center;
  color: #000;
  font-family: 'HelveticaNeueLight';
}

.signup-description.veryfy-your-mail-description h5 {
  font-size: 42px;
}

.recnet-verification-code{
  font-family: 'HelveticaNeueLight';
  font-size: 14px;
  color: #000;
  font-weight: bold;
}

.recnet-verification-code a{
  color: #4092fa;
  font-family: 'HelveticaNeueLight';
}

.recnet-verification-code a:hover{
  text-decoration: underline;
}


.profile-crose{
  background: #fff;
  width: 25px;
  height: 25px;
  line-height: 21px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
  border: 1.5px solid #333;
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 1;
}
/*  start visitore loader */
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.incomingSection .spinner > div, .ongoingSection .spinner > div{
  background-color: #fff;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
/*  end visitore loader */

.overflow-x-hidden{
  overflow-x: hidden;
}


.refresh-icon-visitor:hover svg{
  fill: #242939;
}

.refresh-icon-visitor:hover svg path{
  fill: #242939;
}


.visiter-refresh-procesing > img{
  width: 16px;
}

.rightPanelTab:hover svg, .rightPanelTab.active svg{
  fill: #1b223a;
}

.rightPanelTab:hover svg path,  .rightPanelTab.active svg path{
  fill: #1b223a;
}

.rightPanelTabSecActive .rightPanelTabSec:hover svg {
  fill: #1b223a;
}


.rightPanelTabSecActive .rightPanelTabSec:hover svg path{
  fill: #1b223a;
}


.rightPanelTabSecActive.active .rightPanelTabSec svg {
  fill: #1b223a;
}

.rightPanelTabSecActive.active .rightPanelTabSec svg path{
  fill: #1b223a;
}

.notesChatNot{
  box-shadow: 0px 0px 10px 4px rgba(128, 128, 128, 0.15);
  padding: 20px 20px;
  border-radius: 8px;
  background: #fff;
}

.notTextarea, .notTextarea:focus{
  border-radius: 6px;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e6e6e6;
}

.notesDate{
  font-size: 12px;
  color: #474747;
  font-weight: 700;
}

.notesDescrption{
  font-size: 13px;
  color: #505050;
  padding: 5px 0px;
  word-break: break-word;
  font-weight: normal;
}

.notesBy{
  font-size: 10px;
  color: #5b5b5b;
  font-weight: 400;
}

.text-notes{
  font-size: 14px;
}

.text-notes:focus{
  font-size: 14px;
}

.editedBtnTooltip{
  display: inline-block;
}

.editedBtnTooltip:hover .titleShowHover{
  display: block;
  left: 0;
  right: auto;
  top: 40px;
}

.editedBtnTooltip span.titleShowHover::before {
  content: '';
  position: absolute;
  border: 10px solid #333;
  border-width: 10px;
  border-color: transparent transparent white transparent;
  top: -20px;
  z-index: 9;
  left: 10px;
}

.editedBtnTooltip span.titleShowHover span::after{
  content: '';
  position: absolute;
  border: 10px solid #333;
  border-width: 11px;
  border-color: transparent transparent rgba(0, 0, 0, 0.18) transparent;
  top: -22px;
  left: 9px;
}
 
.editedBtnTooltip span.titleShowHover::after {
  content: '';
  position: absolute;
  border: 10px solid #333;
  border-width: 10px;
  border-color: transparent transparent white transparent;
  top: -20px;
}
.visitorPathRightPanel > ul > li{
  list-style: none;
  font-size: 14px;
  padding: 8px 0px;
}

.visitorPathRightPanelCircle > ul > li{
  list-style: circle;
}

.dataManagement-section h5.card-title{
  font-weight: bold;
  line-height: 1.6;
  color: #242939;
  font-size: 14px;
  font-family: 'HelveticaNeueMedium';
}

.constantProvided{
  font-weight: normal;
  color: #636363;
  font-family: 'HelveticaNeueLight';
  vertical-align: top;
}

.dataManagement-section p.card-body-text{
  font-weight: normal;
  color: #636363;
  font-family: 'HelveticaNeueLight';
}

.btndataMangement{
  width: 230px;
  background: #DEDEDE;
  border: 1px solid #DEDEDE;
  font-size: 14px;
  padding: 5px 5px;
  border-radius: 2px;
  font-weight: normal;
  font-family: 'HelveticaNeueLight';
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.btndataMangement:hover, .btndataMangement:focus{
  font-size: 14px;
  font-family: 'HelveticaNeueLight';
}

.revoke-consent p.card-body-text {
  font-family: 'HelveticaNeueLight';
}

.revoke-consent p.card-body-text strong{
  font-family: 'HelveticaNeueBold';
}

.deleteOTPfeilds input{
  padding: 8px 10px;
  font-size: 14px;
  color: #000;
  font-weight: normal;
  border: 1px solid #dfdfdf;
}


.deleteAccountChoose .wrapperInfo p.card-body-text{
  font-family: 'HelveticaNeueLight';
  font-weight: bold;
}

.deleteAccountChoose .wrapperInfo textarea{
  font-family: 'HelveticaNeueLight';
  padding: 10px;
}
.w200px{width: 200px;}

.iframeUrl{
border:0px;
width:100%;}

.main-center-para{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
// .breadcapPayment .breadcrumb-item a{
//   color: #1b85fc;
// }

// .breadcapPayment .breadcrumb{
//   background: transparent;
// }

// .paymetFeilds input{
//   width: 100%;
//   border: 1px solid #BABABA;
//   height: 40px;
//   font-size: 14px;
//   padding: 0px 10px;
//   background-color: #fff;
// }

// .paymetFeilds textarea{
//   width: 100%;
//   border: 1px solid #BABABA;
//   height: auto;
//   font-size: 14px;
//   padding: 10px 10px;
//   background-color: #fff;
// }

// .paymetFeilds select{
//   width: 100%;
//   border: 1px solid #BABABA;
//   height: 40px;
//   font-size: 14px;
//   padding: 0px 10px;
//   -webkit-appearance: none;
//   background-color: #fff;
// }

// .custArrowSelect{
 
//   background-position: 97% center;
//   background-repeat: no-repeat;
// }

// .billingLabel h6{
//   font-family: 'HelveticaNeueMedium';
//   font-size: 14px;
//   padding: 10px 0px 0px 0px;
// }

// .billingLabel p.comapines{
//   font-family: 'HelveticaNeueUltraLight';
//   font-size: 11px;
// }

// .DetsilsTitle{
//   font-family: 'HelveticaNeueLight';
//   font-size: 20px;
// }

// .title-main-heading h2{
//   font-family: 'HelveticaNeueMedium';
//   text-align: center;
// }


/*-----------start checkbox------------*/

/* The container */
// .checkboxPaymentFeilds {
//   display: block;
//   position: relative;
//   padding-left: 35px;
//   margin-bottom: 12px;
//   cursor: pointer;
//   font-family: 'HelveticaNeueLight';
//   font-size: 14px;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

/* Hide the browser's default checkbox */
// .checkboxPaymentFeilds input {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
//   height: 0;
//   width: 0;
// }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #eee;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

// /* On mouse-over, add a grey background color */
// .checkboxPaymentFeilds:hover input ~ .checkmark {
//   background-color: #ccc;
// }

// /* When the checkbox is checked, add a blue background */
// .checkboxPaymentFeilds input:checked ~ .checkmark {
//   background-color: #2196F3;
// }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
// .checkboxPaymentFeilds input:checked ~ .checkmark:after {
//   display: block;
// }

/* Style the checkmark/indicator */
// .checkboxPaymentFeilds .checkmark:after {
//   left: 9px;
//   top: 3px;
//   width: 8px;
//   height: 16px;
//   border: solid white;
//   border-width: 0 2px 2px 0;
//   -webkit-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   transform: rotate(45deg);
// }

/*---------end checkbox--------------*/

.billingDetails > ul > li{
  list-style: none;
  padding: 10px 0px;
  border-bottom: 1px solid #EDEDED;
}

.billing-text h6{
  font-family: 'HelveticaNeueMedium';
  font-size: 16px;
}
.billing-text p{
  font-family: 'HelveticaNeueLight';
  font-size: 16px;
}

.keyFeaturesList > ul > li {
  list-style: none;
  padding: 10px 0px;
  float: left;
  width: calc(50% - 20px);
  margin-right: 15px;
  font-size:14px;
}

.amountPay{
  background: #EDEDED;
}

.amountPay h6{
  font-family: 'HelveticaNeueMedium';
  font-size: 16px;
}

.amountPay p{
  font-family: 'HelveticaNeueLight';
  font-size: 16px;
}


.keyFeaturesList{
  display: inline-block;
  width: 100%;
}

// .privacyPolicyCheckbox p{
//   font-family: 'HelveticaNeueLight';
//   font-size: 14px;
// }

// .checkboxPaymentFeilds a{
//   color: #1a85fc;
// }

.error{
  display: inline-block;
  width: 100%;
  font-size: 12px;
  color: #DD6A6A;
  font-family: 'HelveticaNeueLight';
  margin: 5px 0px 0px 0px;
}

// .borderRightPayment{
//   border-right: 1px solid #ACACAC;
// }

// .enterBillingDetails, .planTablae{
//   padding: 20px 50px;
// }

.plan-section-left{
    width: 240px;
    text-align: left;
}
.billing-text .plan-section-left h6, .billing-text .plan-section-left p {
    font-size: 14px;
    font-family: 'HelveticaNeueLight';
    color: #000;
}
.plane-section-right{
    text-align: left;
}
.plane-section-right input {
  width: 95px;
}
.plane-section-right #billing_information {
    width: 95px;
    min-height: 26px;
    border: 1px solid #000;
}
.plane-section-right .no-border #billing_information {
  border: none;
  min-height: auto;
}
.plane-section-right .material-input input {
  width: 100%;
}

.CallingPopupHeader{
  background-color: #1B223C;
  border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  -moz-border-radius: 20px 20px 0px 0px;
  -ms-border-radius: 20px 20px 0px 0px;
  -o-border-radius: 20px 20px 0px 0px;
  cursor: move;
} 

.CallingPopupHeader h5.card-title{
  font-weight: normal;
  text-align: center;
}

.call-animation {
  background: #fff;
  width: 110px;
  height: 110px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  animation: play 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

}
.call-animation img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
  }
@keyframes play {

  0% {
      transform: scale(1);
  }
  15% {
      box-shadow: 0 0 0 5px #C3E0FF;
  }
  25% {
      box-shadow: 0 0 0 10px #C3E0FF, 0 0 0 20px #E9F4FF;
  }
  25% {
      box-shadow: 0 0 0 15px #C3E0FF, 0 0 0 30px #E9F4FF;
  }

}

.btn-calling{
  border-radius: 100px;
  padding: 10px 30px;
  height: 47.50px;
}

.btn-accept{
  background: #5CC36C;
  border: 1px solid #5CC36C;
  color: #fff;
}

.btn-reject{
  background: #FD444D;
  border: 1px solid #FD444D;
  color: #fff;
}

.btn-mute{
  background: #64B5F6;
  border: 1px solid #64B5F6;
  color: #fff;
}

#incomingCallPopup{
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  width: 400px;
}

.close-call-popup{
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 24px;
  border-radius: 100px;
  cursor: pointer;
  z-index: 9;
  transform: translateY(16px) translateX(-12px);
}

.collapse-call-popup{
  position: absolute;
  left: 30px;
  top: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 24px;
  border-radius: 100px;
  cursor: pointer;
  z-index: 9;
  transform: translateY(16px) translateX(-12px);
}

.maximize-minimize{
  position: absolute;
  right: 10px;
  top: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 24px;
  border-radius: 100px;
  cursor: pointer;
  z-index: 9;
  transform: translateY(16px) translateX(-12px);
}

.datePic_transform .react-datepicker-popper{
  transform: translate3d(185px, 417px, 0px) !important;
  -webkit-transform: translate3d(185px, 417px, 0px) !important;
  -moz-transform: translate3d(185px, 417px, 0px) !important;
  -ms-transform: translate3d(185px, 417px, 0px) !important;
  -o-transform: translate3d(185px, 417px, 0px) !important;
}

.call-muted {
  color: #000000;
  font-size: 16px;
  padding-left: 6px;
}
.call-muted span.call_status {
  font-weight: bold;
  color: #000000;
  font-size: 14px;
}
.phonecall-icon-round{
  background-image: url(../images/livechat/phone-call.svg);
}
.phonesms-icon-round{
  background-image: url(../images/livechat/phone-sms.svg);
}
.visitorcolor-bg-call{ background-color: #f4f4f4; }
.sendbysms { margin: 8px; opacity: 0.52; }


.main-btn-accept{
  background: #5CC36C;
  border: 1px solid #5CC36C;
  color: #fff;
}

.main-btn-reject{
  background: #FD444D;
  border: 1px solid #FD444D;
  color: #fff;
}

/*payment style css */

.main-center-para{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
// .breadcapPayment .breadcrumb-item a{
//   color: #1b85fc;
// }

// .breadcapPayment .breadcrumb{
//   background: transparent;
// }

// .paymetFeilds input{
//   width: 100%;
//   border: 1px solid #BABABA;
//   height: 40px;
//   font-size: 14px;
//   padding: 0px 10px;
//   background-color: #fff;
// }

// .paymetFeilds textarea{
//   width: 100%;
//   border: 1px solid #BABABA;
//   height: auto;
//   font-size: 14px;
//   padding: 10px 10px;
//   background-color: #fff;
// }

// .paymetFeilds select{
//   width: 100%;
//   border: 1px solid #BABABA;
//   height: 40px;
//   font-size: 14px;
//   padding: 0px 10px;
//   -webkit-appearance: none;
//   background-color: #fff;
// }

// .custArrowSelect{
 
//   background-position: 97% center;
//   background-repeat: no-repeat;
// }

// .billingLabel h6{
//   font-family: 'HelveticaNeueMedium';
//   font-size: 14px;
//   padding: 10px 0px 0px 0px;
// }

// .billingLabel p.comapines{
//   font-family: 'HelveticaNeueUltraLight';
//   font-size: 11px;
// }

.DetsilsTitle{
  font-family: 'HelveticaNeueLight';
  font-size: 20px;
}

// .title-main-heading h2{
//   font-family: 'HelveticaNeueMedium';
//   text-align: center;
// }


/*-----------start checkbox------------*/

/* The container */
// .checkboxPaymentFeilds {
//   display: block;
//   position: relative;
//   padding-left: 35px;
//   margin-bottom: 12px;
//   cursor: pointer;
//   font-family: 'HelveticaNeueLight';
//   font-size: 14px;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

/* Hide the browser's default checkbox */
// .checkboxPaymentFeilds input {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
//   height: 0;
//   width: 0;
// }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #eee;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

/* On mouse-over, add a grey background color */
// .checkboxPaymentFeilds:hover input ~ .checkmark {
//   background-color: #ccc;
// }

// /* When the checkbox is checked, add a blue background */
// .checkboxPaymentFeilds input:checked ~ .checkmark {
//   background-color: #2196F3;
// }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
// .checkboxPaymentFeilds input:checked ~ .checkmark:after {
//   display: block;
// }

// /* Style the checkmark/indicator */
// .checkboxPaymentFeilds .checkmark:after {
//   left: 9px;
//   top: 3px;
//   width: 8px;
//   height: 16px;
//   border: solid white;
//   border-width: 0 2px 2px 0;
//   -webkit-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   transform: rotate(45deg);
// }

/*---------end checkbox--------------*/

.billingDetails > ul > li{
  list-style: none;
  padding: 8px 0px;
  border: none;
}

.billingDetails > ul > li:first-child{
  border-bottom: 1px solid #EDEDED;
}
.billing-text h6{
  font-family: 'HelveticaNeueMedium';
  font-size: 16px;
}
.billing-text p{
  font-family: 'HelveticaNeueLight';
  font-size: 14px;
}

.keyFeaturesList > ul > li {
  list-style: none;
  padding: 10px 0px;
  float: left;
  width: calc(50% - 20px);
  margin-right: 15px;
  font-size:14px;
}

.amountPay{
  background: #EDEDED;
}

.amountPay h6{
  font-family: 'HelveticaNeueMedium';
  font-size: 16px;
}

.amountPay h6, .amountPay p{
  font-family: 'HelveticaNeueLight';
  font-size: 14px;
}


.keyFeaturesList{
  display: inline-block;
  width: 100%;
}

// .privacyPolicyCheckbox p{
//   font-family: 'HelveticaNeueLight';
//   font-size: 14px;
// }

// .checkboxPaymentFeilds a{
//   color: #1a85fc;
// }

.error{
  display: inline-block;
  width: 100%;
  font-size: 12px;
  color: #DD6A6A;
  font-family: 'HelveticaNeueLight';
  margin: 5px 0px 0px 0px;
}

// .borderRightPayment{
//   border-right: 1px solid #ACACAC;
// }

// .enterBillingDetails, .planTablae{
//   padding: 20px 50px;
// }

.plan-section-left{
    text-align: left;
    flex: 1;
}

.plane-section-right{
    text-align: left;
}
.CallingPopupHeader{
  background-color: #1B223C;
  border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  -moz-border-radius: 20px 20px 0px 0px;
  -ms-border-radius: 20px 20px 0px 0px;
  -o-border-radius: 20px 20px 0px 0px;
}

.CallingPopupHeader h5.card-title{
  font-weight: normal;
  text-align: center;
}

.call-animation {
  background: #fff;
  width: 110px;
  height: 110px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  animation: play 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

}
.call-animation img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
  }
@keyframes play {

  0% {
      transform: scale(1);
  }
  15% {
      box-shadow: 0 0 0 5px #C3E0FF;
  }
  25% {
      box-shadow: 0 0 0 10px #C3E0FF, 0 0 0 20px #E9F4FF;
  }
  25% {
      box-shadow: 0 0 0 15px #C3E0FF, 0 0 0 30px #E9F4FF;
  }

}

/* payment style css end*/

.hoverA:hover{
color:#ffffff;
text-decoration:none;
}

.appyicon-indian-rupee:before {
    content: "\42";
}

[class*=" appyicon-"]:before, [class*=" iconzu-"]:before, [class^=appyicon-]:before, [class^=iconzu-]:before {
    font-family: customappyicon!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-variant: normal!important;
    text-transform: none!important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.calling-circle-left{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.accept-green{
  background: #77C075;
}
.reject-red{
  background: #E95454;
}
.mute-black{
  background: #000;
}

.calling-incoming {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  //box-shadow: 0px 4px 5px 1px rgba(128, 128, 128, 0.15);
  border-radius: 4px 4px 8px 8px;
  z-index: 99;
  -webkit-border-radius: 4px 4px 8px 8px;
  -moz-border-radius: 4px 4px 8px 8px;
  -ms-border-radius: 4px 4px 8px 8px;
  -o-border-radius: 4px 4px 8px 8px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.table-in-longPara{
  overflow: hidden;
  text-overflow: ellipsis;
  clear: both;
  max-width: 150px;
  display: inline-block;
  font-size: 13px;
}

.key-features-h h5{
  font-family: 'HelveticaNeueMedium';
}
/*.paid-client { 
  background-image: url(../images/livechat/paid.svg); 
  background-size: 45px 45px;
  background-repeat: no-repeat;
  background-position: center;
} */
.paid-client { 
  background-color: #e71e62;
}

.inner-calling-inc { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

.agent_callbtn, .agent_callbtn:focus{
  width: 30px;
  height: 30px;
  background: #3E4360;
  padding: 8px 6px;
  border-radius: 100%;
  line-height: 0;
  text-align: center;
  cursor: pointer;
  border: 1px solid #3E4360;
  margin-left: 5px;
}

.call-para h4{
  font-size: 14px;
  margin: 0;
}

.call-para p {
  font-size: 12px;
  color: #ababab;
}

.call-para{
  margin-left: 10px;
}

.header-calling-icon.header-calling-icon2 .btn-calling {
  border-radius: 100px;
  padding: 5px 0px;
  width: 105px;
  height: auto;
  font-size: 14px;
}

.calling_pra_header{
  font-size: 14px;
  margin-right: 10px
}

.header-calling-icon .btn-calling svg{
  width: 12px;
  height: 12px;
}

.header-calling-icon .main-btn-reject svg{
  width: 18px;
  height: 18px;
}

.header-calling-icon .btn-mute svg{
  width: 15px;
  height: 15px;
}

.header-calling-icon .btn-mute img{
  width: 11.6px;
}

#mainPhoneCall {
    position: fixed;
    z-index: 99;
    top: 8px;
    right: 20px;
    background: #fff;
    text-align: right;
    padding-right: 20px;
}

/*
.agent-voip-call {
  background-image: url(../images/livechat/employee.png); 
  background-size: 60px 60px;
  background-repeat: no-repeat;
  background-position: center;
}
.trial-client { 
  background-image: url(../images/livechat/trial.png);
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center;
} */

.agent-voip-call {
  background-color: #dd16ff;
}
.trial-client {
  background-color: #f7473a;
}

.btn-social{
  display: flex;
  align-items: center;
  width: 300px;
  height: 50px;
}
  
.btnsocial-FB{
  background: #4267b2;
  background-color: #3b5998;
  border-radius: 1px;
  text-decoration: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  border: 1px solid #3b5998;
}
  
.btnsocial-gp{
  line-height: 1;
  letter-spacing: 0.21px;
  text-align: center;
  font-weight: 500;
  color: #757575;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background: #FFF;
  border: 1px solid #FFF;
}
  
.btn-social .sLo-1{
  padding: 10px;
  height: inherit;
}
  
.btn-social .sLo-2{
  padding: 15px;
  height: inherit;
}
  
.btn-social p{
  width: 100%;
  text-align: center;
}
.cusArroLan{
  width: 150px;
  border-radius: 6px;
  height: 35px;
  font-size: 14px;
  color: #848490;
  border: 1px solid #4d4e5c;
  background-position: 95% !important;
}

.select-languages label.sta-langu-label{
  margin: 0;
  position: absolute;
  left: 7px;
  top: -9px;
  padding: 0px 3px;
  font-size: 12px;
  background: #fff;
}

.PhoneInputCountry{border: 1px solid #dfdfdf;
  padding: 0px 10px;
  margin-right: 0 !important;
  border-right: 0;
  border-radius: 5px 0px 0px 5px;}

  .PhoneInputCountry select{
    font-size: 14px;
  }

  .PhoneInputCountryIcon{
    width: 24px !important;
    height: 16px !important;
  }

  .number_dialedRight li.search{
    padding: 10px 10px 6px 10px !important;
    display: flex;
    align-items: center;
  }

.number_dialedRight .react-tel-input .country-list .search-box{
  height: 35px;
}

.number_dialedRight .react-tel-input .country-list .country{
  font-size: 14px;
  font-weight: normal;
}

.react-tel-input .country-list {
    width: 340px !important;
}


.agnt_online_icon .agent_smsbtn:hover svg, .agnt_online_icon .agent_smsbtn:hover svg path, .agnt_online_icon .agent_smsbtn:hover svg circle{
  fill: #1B85FC;
}

.agnt_online_icon .agent_callbtn_hover:hover{
  background: #1B85FC;
  border: 1px solid #1B85FC;
}
.vibrate_calling_ani{ 
  animation: pulse 1s infinite;
  animation-timing-function: linear;   
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.agentnumIcons { opacity: 0; }
.outline-1-black tr:hover .agentnumIcons { opacity: 1; }

.apexcharts-tooltip h5{
  font-size: 14px;
  font-weight: bold;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
}

.apexcharts-tooltip p{
  font-size: 14px;
  padding: 5px 10px;
}

.apexcharts-tooltip p span:nth-child(2){
  background-color: #77c66d;
  float: right;
  padding: 2px 8px;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: bold;
}

.nps-satis .numberReport{
  font-family: 'HelveticaNeueBold';
  font-size: 28px;
  color: #000;
  margin-right: 5px;
}
.nps-satis .iconTop {
  top: 8px;
  position: relative;
} 

.nps-satis .title-total-chat{
  color: #333333;
  font-size: 14px;
}

.MuiFormControl-marginNormal {
  width: 100%;
}
.message .message-content-his .message-content-new {
  // float: left;
  max-width: calc(100% - 0px);
}
.chat-his-name .bodyRightPanel, .chat-his-name .addNoteHight {padding-right: 0;}

.message.message-right .message-content-his .message-content-new {
  max-width: calc(100% - 50px);
  float: none;
}

.message .message-content-his {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.message.message-right .message-content-his {
  flex-direction: row;
}
.message .sendbysms {
  display: flex;
  align-items: flex-end;
}
.message .message-content-his .message-content-new + .mt-0 {
  float: left;
  width: 100%;
  flex-basis: 100%;
}

.chat-his-chat.chat-history .message-content-his {
  display: initial;
}
.chat-his-chat.chat-history .message.message-right .message-content-his .message-content-new {
  max-width: initial;
}

.chat-his-chat .chat-content .message.message-right .message-content-his .message-content-new {
  max-width: initial;
}

.agent-table-header.user-table-main-header {
  padding: 0 20px;
}
.user-table-main-header .search-bar {
  width: 250px;
}
.agent-table-header.user-table-main-header .fltr-view {
  width: calc(100% - 250px);
}

.liveCgatUserTable .media .media-body.align-self-center {max-width: 170px;}
.wrapperInfo .custom-select.cstm-addagent {
  max-height: 60px;
  height: 100%;
  font-size: 15px;
}
.MuiFormControl-marginNormal {
  width: 100%;
}

.popup-scroll {
  overflow-y: auto;
  max-height: calc(100vh - 50px);
  height: auto;
}
.drop-scroll {
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  height: auto;
}

.viewTranscript{
  color: #008aff;
  cursor: pointer;
  background: transparent;
  padding: 6px 10px;
  border-radius: 4px;
}
.agent-table-header.banned-fltr .input-group {
  max-width: 200px;
  width: 100%;
  float: left;
}
.agent-table-header.banned-fltr .aside-agent {
  width: calc(100% - 200px);
  justify-content: flex-end;
  display: flex;
  margin-right: 0 !important;
}
.mt--25 {margin-top: -25px;}

.mobile-Menu-RightPanel{display: none;}
.removeCancel{display: none;}
.mobileAddCNew .removeCancel{display: block;}
.mobileAddCNew .addobiCancel{display: none;}
.agent-table-header.user-table-main-header.input-border input {
  border-right: 1px solid #edeef6;
}

// .agent-table-header.phone-fltr {float: left;}
.agent-table-header.phone-fltr .input-group {
  width: 174px;
  margin-right: 20px;
}
.agent-table-header.phone-fltr .input-group .wrapperInfo {width: 100%;}
.setting-footer-fixed.notif-view {width: calc(100% - 140px);}
.analytics-datepicker {
  position: absolute;
  top: 100%;
  left: auto;
  z-index: 9;
  right: 0;
  background: #fff;
  box-shadow: 0 1px 7px #d0d0d0;
}

.visitor-datepicker {
  position: absolute;
  top: 100%;
  left: 90px;
  z-index: 9;
  right: 0;
  background: #fff;
  box-shadow: 0 1px 7px #d0d0d0;
}
.visitor-datepicker.cstm-right {
  right: -480px;
  left: 0;
}

.rdrDefinedRangesWrapper {display: none;}
.label-style .drop-value {margin-left: 15px;}
.label-style .manage-icon + .drop-value {margin-left: 25px;}

/* Added by AG Start for mobile */
.mob_calling_inc .footer-calling-popup{
  width: 250px;
}

.mob_calling_inc #calling_from_number{
  font-size: 10px;
}

.mob_calling_inc .btn-calling{
  border-radius: 100px;
  padding: 9px 16px;
  height: 47.50px;
}
.forem-rightPanel .rightFeilds .customArrow {padding-right: 20px;}
/* Added by AG end for mobile */
.left-widgetsetings-box .noDatafound {width: 100%;}
.label-style .drop-value {margin-left: 15px;}
.label-style .manage-icon + .drop-value {margin-left: 25px;}
.liveCgatUserTable.mob-tbl-view .bg-darksecound tr th {position: relative;}
.scroll-view-tbl .liveCgatUserTable.mob-tbl-view tbody tr td{
  white-space: nowrap;
}
.scroll-view-tbl .liveCgatUserTable.mob-tbl-view .bg-darksecound tr th{white-space: nowrap;}
.scroll-view-tbl .liveCgatUserTable.mob-tbl-view tbody tr td.agent_name{white-space: pre-wrap !important;}
.scroll-view-tbl .liveCgatUserTable.mob-tbl-view tbody tr td.agent_name .table-in-longPara {
  word-break: break-word;
}
.customArrow2 {
  width: 10px;
  height: 20px;
  background: url(../images/whiteCustomeArrow.svg) no-repeat !important;
  position: absolute;
  margin-left: 8px;
  display: inline-block;
  cursor: pointer;
  display: none;
}

.chartTitle.cstm-drop {
  float: left;
  width: 100%;
  padding-bottom: 20px;
}
.chartTitle.cstm-drop h5 {
  width: 40%;
  float: left;
}
.chartTitle.cstm-drop .drop-value {
  width: calc(100% - 40%);
  float: right;
}
.online-cont tr th, .online-cont tr td {
  padding: 15px 0;
}
.liveCgatUserTable.mob-tbl-view .bg-darksecound tr th:hover .customArrow2, .liveCgatUserTable.mob-tbl-view .bg-darksecound tr .customArrow2.show-sort {
  display: inline-block;
}
.bg-darksecound.bg-lightother {background: #5a4b8a;}
.icon-owner img {margin-top: -3px;}

.nowrap-text {
  width: calc(100% - 65px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pos-abs {position: absolute;}
.mobile-Menu-RightPanel{display: none;}
.removeCancel{display: none;}
.mobileAddCNew .removeCancel{display: block;}
.mobileAddCNew .addobiCancel{display: none;}

.message-divider .center-width {width: 100%;text-align: center;}
.Toastify__toast {z-index: 99999;}

.categoryRightPanel .rightPanelHeader .agent-profile-rightPanel h6 {
  width: calc(100% - 60px);
}
.agent_online_bar { 
  height: 20px; 
  background-color : #06b942;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  margin-top: 4px;
}

.graph-custom-theme {
  border: 1px solid #e3e3e3;
  background: rgba(255, 255, 255, 0.96);
}




.liveCgatUserTable.mob-tbl-view.online-agent tbody tr td:first-child {
  padding: 2px 5px;
  max-width: 80px;
}

.liveCgatUserTable.mob-tbl-view.online-agent tbody tr td:last-child {
  width: 130px;
  padding: 2px 5px;
}

.liveCgatUserTable.mob-tbl-view.online-agent tbody tr td:first-child p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.liveCgatUserTable.mob-tbl-view.online-agent tbody tr td {
  padding: 0;
}
.liveCgatUserTable.mob-tbl-view.online-agent tbody tr td,
.liveCgatUserTable.mob-tbl-view.online-agent thead tr th {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.liveCgatUserTable.mob-tbl-view.online-agent tbody tr td:last-child,
.liveCgatUserTable.mob-tbl-view.online-agent thead tr th:last-child {
  border-right: none;
}
.liveCgatUserTable.mob-tbl-view.online-agent tbody {
  border: 1px solid #ddd;  
  border-bottom: none;
}
.hover-title {
  border: 1px solid #e3e3e3;
  background: rgba(255, 255, 255, 1);
  box-shadow: 2px 2px 6px -4px #999;
  cursor: default;
  font-size: 14px;
  left: 62px;
  pointer-events: none;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  z-index: 12;
  transition: 0.15s ease all;
  border-radius: 8px;
}
// .hover-title::after {
//   position: absolute;
//   content: "";
//   border-left: 10px solid transparent;
//   border-right: 10px solid transparent;
//   border-top: 10px solid #ffffff;
//   bottom: -10px;
//   left: 0;
//   right: 0;
//   margin: 0 auto;
//   width: 10px;
// }

.hover-title h5 {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
}
.hover-title p {
  font-size: 14px;
  padding: 5px 10px;
}
.hover-title p span:nth-child(2) {
  background-color: #77c66d;
  float: right;
  padding: 2px 8px;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: bold;
}


.setup-heading.tabs-view {
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
}
.setup-heading.tabs-view button {
  border: none;
  background: none;
  padding: 20px 30px;
  position: relative;
}
.setup-heading.tabs-view button.active {
  color: #1d84f7;
  font-weight: bold;
}
.setup-heading.tabs-view button.active::after {
  content: "";
  position: absolute;
  background: #1d84f7;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: -2px;
}

.setup-list-design .title-card li {
  padding: 0;
}
.setup-list-design .title-card li::after, 
.setup-list-design .title-card li::before {
  display: none;
}
.setup-list-design .title-card li span {
  text-align: center;
  display: block;
}
.setup-list-design .title-card li .socialAlign {
  display: flex !important;
}
.setup-list-design .title-card li img {
  width: 100%;
  max-width: 150px;
  display: block;
  margin: 0 auto 20px;
}
.setup-list-design .title-card li .social-img img {
  margin-bottom: 0;
  max-width: 120px;
}

.setup-list-design .title-card li .social-img.zoom img {
  height: 100px;
  margin-bottom: 12px;
}

.setup-list-design .title-card li .social-img span {
  color: #000000;
  font-weight: bold;
  font-size: 14px;
}
.setup-list-design .title-card li .social-img svg {
  display: block;
  height: 96px;
  margin-bottom: 0;
  padding-top: 0px;
}
.setup-list-design .title-card li .social-img.heightEqual svg {
  height: 96px;
}
.setup-list-design .title-card {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.setup-list-design .title-card li {
  padding: 0;
  width: 31%;
  box-shadow: 0px 1px 4px 0px #1c223a78;
  margin: 10px;
  border-radius: 20px;
  padding: 20px;
  max-width: 450px;
  // display: inline-block;
  height: 260px;
}

.setup-list-design .title-card li button {
  border: none;
  background: no-repeat;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 10px 0;
}
.link-style {
  color: #0176ff;
  cursor: pointer;
}
.link-style:hover{
  text-decoration: underline;
  color: #0176ff;
}
.bold {
  font-weight: bold;
}

.setup-list-design .title-card.box-4 {
  width: 100%;
  flex-wrap: wrap;
}
.setup-list-design .title-card.box-4 li {
  width: calc(25% - 20px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.setup-list-design .title-card.box-4 li button {text-align: center;}
// .setup-list-design .title-card.box-4 li img {
//   max-height: 40px;
// }
.yellowBtn {background: #ff7f00;color: #fff;border: 1px solid #ff7f00;}
.grayBtn {background: #a7a7a7;border: 1px solid #a7a7a7;color: #fff;padding: 8px 40px;border-radius: 0;}
.no-border{border: none;}
.after-none li::after {
  display: none;
}
.setup-list-design > ul.after-none > li.active:before {
  border-color: inherit;
  color: inherit;
}

.congratulationPopup.congratulationPopup2 {
  width: 100%;
  max-width: 500px;
  height: auto;
  position: absolute !important;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 44vh;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}
.congratulationPopup.congratulationPopup2 .popupBody {
  position: static;
  top: 0;
  left: 0;
  transform: translateY(0);
}
.congratulation-overlay{
  position: relative;
}
.congratulation-overlay::after{
  content: "";
  position: absolute;
  background: #000;
  top: 0;
  left: -50px;
  right: 0;
  height: 100vh;
  opacity: 0.5;
  width: 140%;
  z-index: -1;
}
.congratulationPopup.congratulationPopup2.bg-img{
  background-image: url(../../assets/images/congratc-bg.svg);
  width: 100%;
}
.chatReportCol .chatReport-total-chats.user-profile .user-pic {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: #2c3049;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  margin-right: 15px;
  color: #fff;
  font-size: 20px;
}
.chatReportCol .chatReport-total-chats.user-profile .user-pic img {
  width: 100%;
  border-radius: 50%;
  margin-top: -3px;
  height: 100%;
}

.chatReportCol .chatReport-total-chats .online-time .big, .chatReportCol .chatReport-total-chats .no-data,
.chatReport-total-chats .online-time .no-data {
  font-size: 30px;
  font-weight: bold;
  color: #000;
  padding-right: 2px;
}
.chatReport-total-chats .online-time .no-data.normal-text {
  font-size: 20px;
  padding: 8px 0;
}
.chatReportCol .chatReport-total-chats .online-time .small {
  color: #6d6d6d;
  vertical-align: bottom;
  display: inline-block;
  padding-right: 5px;
}

.agent-online-names { color: #333 !important; cursor: pointer; }
.agent-online-names:hover { text-decoration: underline;}
.profilename-agent { font-size:  20px; color: #000; }

.custmize-your-widget.pos-fixed, .chat-sidebar .test-drive {
  position: absolute;
  top: 25%;
  // z-index: 1;
}
.form-query-subhading { font-weight: bold; color: #0000009e;}





.tab-view {
  position: relative;
  float: left;
  width: 100%;
}


.tab-view .cstm-tab {
  padding-bottom: 5px;
}
.tab-view .cstm-tab input {
  display: none;
}

.tab-view .cstm-tab button {
  float: right;
  margin-top: -6px;
}
.tab-view .cstm-tab {
  position: relative;
}
.tab-view .cstm-tab label {
  padding: 0 20px;
  cursor: pointer;
  position: relative;
}
.tab-view .cstm-tab::after {
  content: "";
  background: #ccc;
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -5px;
}
.tab-view .content-container .info-icon:first-child {
  margin-left: 0 !important;
}
.tab-view .cstm-tab label::after {
  content: "";
  position: absolute;
  background: #1b85fc;
  width: 90%;
  height: 3px;
  bottom: -19px;
  left: 0;
  z-index: 1;
  right: 0;
  margin: 0 auto;
  display: none;
}
.tab-view .cstm-tab input:checked + label::after {
  display: block;
}


.full-width {
  float: left;
  width: 100%;
}

.call-text-table tbody tr td a {
  color: #1b85fc;
}
.call-text-table tbody tr td a.underline {
  text-decoration: underline;
}
.call-text-table thead tr th, 
.call-text-table tbody tr td {
  text-align: center;
}
.call-text-table tbody tr td img {
  margin-top: -5px;
  padding-left: 5px;
}


.apexcharts-legend-text, .apexcharts-legend-text{
  display: flex;
}

.apexcharts-legend-text .avggbig{padding-left: 5px;}
.apexcharts-legend-text span.k-flex span.avggbig{padding-left: 0} 

.apexcharts-legend-marker{
  width: 18px !important;
  height: 18px !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
  margin-right: 5px;
}

.apexcharts-legend.apexcharts-align-center.position-bottom{bottom: 0 !important;}

.agent_breadcrumb .breadcrumb li{
  display: inline-block;
  font-size: 14px;
  color: #b3b3b3;
  position: relative;
  padding: 0px 10px;
}

.agent_breadcrumb .breadcrumb li:first-child{
  padding-left: 0;
}

.agent_breadcrumb .breadcrumb li::after{
  content: '/';
  position: absolute;
  right: -3px;
  color: #b3b3b3;
}

.agent_breadcrumb .breadcrumb li:last-child::after{
  content: '';
}
.scriptCodeSetup textarea#myInputCopy {
  overflow: hidden;
}

.feedBackCommntOptional{margin-top: 10px;}

.feedBackCommntOptional textarea{
  width: 100%;
  font-size: 12px;
  padding: 5px;
  border: 1px solid #d0d0d0;
  height: 90px;
}


.feedBackCommntOptional button {
  font-size: 12px;
  background: #1a85fc;
  color: #fff;
  border: 1px solid #1a85fc;
  padding: 3px 20px;
  float: right;
  border-radius: 4px;
}

.feedbackHistory{
  left: auto !important;
  top: 20px !important;
  right: 0px !important;
  transform: inherit !important;
  -webkit-transform: inherit !important;
  -moz-transform: inherit !important;
  -ms-transform: inherit !important;
  -o-transform: inherit !important;
  background: #fff !important;
  position: absolute !important;
}

/* .analytics-left { width: 170px; }
.analytics-left + header + div.sidebar-collapse {  margin-left: 233px; } */

.chatReportCol.cstm-box-time {
  padding: 20px;
  min-height: 230px;
}
.chatReportCol.cstm-box-time .online-time, .chatReportCol.cstm-box-time .wait-time-others {
  flex: 50%;
  position: relative;
}
.chatReportCol.cstm-box-time .online-time {
  flex: 40%;
  padding-right: 40px;
}
.chatReportCol.cstm-box-time .wait-time-others::before {
  content: "";
  position: absolute;
  left: -38px;
  background: #ccc;
  width: 1px;
  height: 78%;
  top: 20px;
}
.chatReportCol.cstm-box-time .wait-time-others.line-diff::before {
  height: 100px;
  top: -8px;
} 
.chatReportCol.cstm-box-time .chatReport-total-chats {
  padding: 0;
  padding-top: 12px;
}


.chatReport-total-chats .online-time .numberReport .big, .chatReport-total-chats .online-time .numberReport .small  {
  font-family: 'HelveticaNeueLight';
  font-size: 50px;
  font-weight: normal;
}

.numberReport .big, .chatReport-total-chats .online-time .numberReport .small {
  font-size: 30px;
}
.chatReport-total-chats .wait-time-others .big {
  color: #000;
  font-size: 30px;
  padding-right: 2px;
}
.chatReport-total-chats .wait-time-others .small {
  font-size: 16px;
  padding-right: 2px;
}

.wait-time-others p .left {
  margin-right: auto;
  padding-right: 10px;
}

.chatform-Preview .title-total-chat {
  color: #000;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.chatReportCol.cstm-box-time .dot {
  position: relative;
  padding-left: 10px;
}
.chatReportCol.cstm-box-time .dot::before {
  content: "";
  position: absolute;
  background: #0184f8;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 26px;
  left: -5px;
}
.chatReportCol.cstm-box-time .dot.green::before {
  background: #4bb772;
}
.wait-time-others p .info-icon {
  width: 40px;
  padding-right: 20px;
  margin-bottom: 10px;
}
.wait-time-others p .info-icon img {
  width: 100%;
}
.wait-time-others p .satisfaction-result {
  width: 50px;
}

// guide css

.tour_guide {
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
  padding: 15px;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99999;
  background: #fff;
  cursor: default;
}
.tour_guide p {
  color: #000;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  text-align: left !important;
}
.tour_guide .title {
  text-align: left;
  padding-bottom: 10px;
  font-size: 16px;
  color: #868686;
  font-weight: bold;
  margin-bottom: 0;
  border-bottom: 0;
  font-family: sans-serif;
}

.tour_guide button {
  float: right;
  margin: 0 15px;
}
.tour_guide .btn-next {
  border: none;
  background: #0984f6;
  color: #fff;
  border-radius: 3px;
  padding: 5px 12px;
}
.tour_guide .end-btn {
  border: 0;
  background: none;
  color: #000;
  font-weight: bold;
  text-transform: capitalize;
  padding: 5px;
  font-family: sans-serif;
}

.tour_guide::before {
  content: "";
  position: absolute;
  background: #fff;
  width: 30px;
  height: 20px;
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.tour_guide::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  background: #fff;
  z-index: -1;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.overlay-guide {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  height: 100%;
}
.overlay-guide::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.5), rgba(0, 0, 0, 0.5));
}
.addTextArea .addArea.position-top {
  position: relative;
}

.card-body-text.position-top .btnOrangeSettings, .ongoingSection.position-top .incoming-outgoing-btn,
.incomingSection.position-top .incoming-outgoing-btn.accordion, .chat-sidebar.position-top .upper-div,
.card-active-listener.position-top, .startChatNew.position-top .startChatBtn, .chat-header ul.position-top li#headerbtn-endchat,
.incomingSection .position-top, .setup-list-design .position-top .top-icon, .setup-list-design .position-top .website-verifylink-feilds,
.setup-list-design .position-top .link-style, .chat-header ul.position-top li.mobile-upper .dropdown span.nav-link,
.chat-header ul.position-top li.mobile-upper .dropdown .dropdown-menu, .addTextArea .addArea.position-top .upper-div,
.search-feilds-history.position-top .upper-div, .agent-table .position-top .upper-div, .chat-middle.position-top .upper-div,
.idle-section .position-top .upper-div  {
  position: relative;
  z-index: 99999;
}
.idle-section .position-top .upper-div input {
  border-radius: 5px;
}
.addTextArea .addArea.position-top .upper-div {
  color: #fff;
}
.chat-header ul.position-top li.mobile-upper .dropdown .dropdown-menu {
  z-index: 999999;
}

.setup-list-design .position-top .top-icon {
  background: #fff;
  display: inline-block;
  height: 30px;
  left: 4px;
  width: 25px;
  padding: 0 4px !important;
}
.setup-list-design .position-top .top-icon.calc-width {
  width: 35px;
  left: 8px;
}
.chat-sidebar.position-top .icon-pos-top {
  z-index: 99999
}
.card-body-text.position-top .tour_guide {
  left: 10px;
  right: auto;
  margin-top: 5px;
}
.card-body-text.position-top .tour_guide::before, 
.card-body-text.position-top .tour_guide::after {
  right: 0;
  left: 0px;
}
.card-body-text.position-top{
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
}
.startChatNew.position-top .tour_guide {
  top: -170px;
}
.startChatNew.position-top .tour_guide::after {
  top: auto;
  bottom: -10px;
}
.startChatNew.position-top .tour_guide::before{
  top: auto;
  bottom: 0;
}

.chat-header ul.position-top .tour_guide {
  top: 45px;
  right: 0;
  left: auto;
}
.chat-header ul.position-top .tour_guide::after {
  left: auto;
  right: 68px;
}
.chat-header ul.position-top .tour_guide::before {
  left: auto;
  right: 62px;
}
.incomingSection .position-top + .tour_guide {
  left: 400px;
  right: auto;
  top: 120px;
}
.incomingSection .position-top + .tour_guide::after {
  left: -10px;
  right: auto;
  top: 60px;
}
.incomingSection .position-top + .tour_guide::before {
  left: 0;
  right: auto;
  top: 50px;
  height: 40px;
  width: 15px;
}
.setup-list-design .position-top .tour_guide {
  left: auto;
  right: 36px;
  top: -50px;
}
.setup-list-design .position-top .tour_guide::after {
  left: auto;
  top: 55px;
  right: -10px;
}
.setup-list-design .position-top .tour_guide::before {
  left: auto;
  top: 50px;
  height: 30px;
  width: 20px;
}
.chat-sidebar.position-top .tour_guide {
  margin-top: 20px;
  max-width: 350px;
}
.ongoingSection.position-top .tour_guide, .incomingSection.position-top .tour_guide {
  left: 400px;
  right: auto;
  top: 25px;
}
.incomingSection.position-top .tour_guide {
  top: 70px;
}
.ongoingSection.position-top .tour_guide::after, .incomingSection.position-top .tour_guide::after {
  left: -10px;
  right: auto;
  top: 46px;
}
.ongoingSection.position-top .tour_guide::before, .incomingSection.position-top .tour_guide::before {
  left: 0px;
  right: auto;
  top: 42px;
  width: 14px;
  height: 28px;
}
.ongoingSection.position-top .tour_guide p.text-center, .incomingSection.position-top .tour_guide p.text-center {
  text-align: left !important;
}

.setup-list-design .guide-pos .tour_guide {
  right: 75px;
}

.setup-list-design .position-top .website-verifylink-feilds + .tour_guide {
  left: 30px;
  right: auto;
  top: 92px;
}

.setup-list-design .position-top .website-verifylink-feilds + .tour_guide::after {
  left: 0;
  top: -10px;
  right: 0;
}
.setup-list-design .position-top .website-verifylink-feilds + .tour_guide::before {
  left: 0;
  top: 0;
  height: 20px;
  width: 30px;
  right: 0;
}
.chat-sidebar.position-top .list-group.visitor-top div:first-child {
  position: relative;
  z-index: 99999;
  background: #fff;
}

.chat-sidebar.position-top .list-group .listing-right-panel-information, 
.chat-sidebar.position-top .list-group .listing-right-panel-information div {
  background: transparent !important;
  z-index: 9 !important;
}

.chat-sidebar.position-top .icon-pos-top .tour_guide::before {
  right: 70px;
  left: auto;
}
.chat-sidebar.position-top .icon-pos-top .tour_guide::after {
  left: auto;
  right: 75px;
}
.chat-sidebar.position-top .oneClik2.icon-pos-top .tour_guide::after {
  right: 40px;
}
.chat-sidebar.position-top .oneClik2.icon-pos-top .tour_guide::before {
  right: 35px;
}
.chat-sidebar.position-top .visitore-search.icon-pos-top + .tour_guide {
  margin-top: 5px;
}

.chat-sidebar.position-top .premium-visitor .tour_guide {
  margin-top: 80px;
}
.chat-sidebar.position-top .premium-visitor ul div:first-child {
  background: #fff;
  position: relative;
  z-index: 99999;
}


.setting-section .fb-connect {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 2px #d4d4d4;
  padding: 20px;
  background: #fff;
}

.setting-section .fb-connect .fb-details {
  flex: 3;
  padding-right: 10px;
}
.setting-section .fb-btn {
  flex: 1;
  text-align: right;
}
.setting-section .fb-btn button {
  background: #1e364f;
  border: none;
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  min-width: 120px;
}

.setting-section .fb-connect .fb-details h2 {
  color: #425993;
  font-weight: 900;
}
.setting-section .fb-connect .fb-details a {
  color: #425993;
}


.setting-section .fb-tbl-view {
  max-width: 1000px;
  width: 100%;
  margin: auto;
}
.setting-section .fb-tbl-view tbody tr {
  background: #fff;
}

.setting-section .fb-btn button img {
  width: 18px;
  margin-right: 8px;
  margin-bottom: 2px;
}
.fb-details .link-type {
  color: #425993;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: auto;
  max-width: 120px;
}
.fb-details .link-type img {
  width: 13px;
  margin-right: 7px;
}

.chatReportCol.cstm-box-time .online-time p + p {
  font-size: 12px;
}

.analytics-title-size { font-size: 18px !important;}
.preview-btn, .close-icon {display: none;}

.radius-bottom {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

/*start anas design guide*/
.widget-design .position-top .btnBlue.mr-2.yellowBtn{
  position: relative;
  z-index: 99999;
}

.position-top .guideWidget{
  position: relative;
  z-index: 99999;
} 

.widget-design .position-top .tour_guide {
  left: auto;
  right: 150px;
  top: -50px;
}

.widget-design .position-top .tour_guideWidgetDeveloper{
  left: auto;
  right: 249px;
  top: 60px;
}

.position-top .wrapperInfo, .position-top .verifyBtn{
  position: relative;
  z-index: 99999;
}


.widget-design .position-top .torgideNew .tour_guide {
  left: auto;
  right: 250px;
  top: 60px;
}

.setup-list-design .position-top .torgideNew .link-style {
  background: #fff;
  padding: 0 4px;
}
.setup-list-design .position-top .tour_guide.guide-position::before {
  left: 0;
  top: 0;
}
.setup-list-design .position-top .tour_guide.guide-position::after {
  left: 0;
  top: -10px;
}

.setup-list-design .position-top .tour_guide.guide-position {
  left: 0;
  bottom: -201px;
  top: auto;
  right: 0;
}
.setup-list-design .website-verifylink-feilds.position-top {
  position: relative;
  background: #fff;
  z-index: 99999;
  padding: 5px;
}
.setup-list-design .position-top .error {
  width: initial;
}
/*end anas design guide*/

.verification-popup {
  position: relative;
}
.verification-popup::after {
  content: "";
  position: fixed;
  background: rgba(0, 0, 0, .4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
}
.verification-popup .cstmpopup {
  background: #fff;
  max-width: 400px;
  box-sizing: border-box;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999999;
  padding: 10px 0;
  width: calc(100% - 20px);
}
.verification-popup .popup-body {
  padding: 20px;
  margin-top: 15px;
}
.verification-popup .cstmpopup .popup-header {
  position: relative;
  padding: 10px 20px;
}
.verification-popup .cstmpopup .popup-header::after {
  content: "";
  position: absolute;
  background: #ccc;
  bottom: -10px;
  width: 100%;
  height: 1px;
  left: 0;
}
.input-control label {
  display: block;
}
.verification-popup .popup-body .input-control label {
  color: #000;
}
.verification-popup .popup-body .error  {
  display: flex;
  align-items: center;
}
.download-status img {
  width: 25px;
  margin-right: 2px;
}
.verification-popup .popup-body .error img {
  max-width: 15px;
  width: 100%;
  height: 15px;
  margin-right: 5px;
}
.input-control .cstm-input {
  width: 100%;
  border: 1px solid #ccc;
  min-height: 34px;
  border-radius: 3px;
  padding: 0 10px;
}
.input-control .cstm-input-resend{
  width: auto;
  padding: 5px 15px;
  cursor: pointer;
  color: #ffffff;
  margin: 20px 10px 0;
}

.verification-popup .cstmpopup .popup-header strong {color: #000;}
.verification-popup .cstmpopup .popup-header .close {
  font-weight: bold;
  color: #000;
  opacity: 1;
}
.close-icon-cstm {
  position: absolute;
  right: 35px;
  top: 20px;
  cursor: pointer;
}
.close-icon-cstm::before {
  content: "";
  position: absolute;
  background: #000000;
  width: 18px;
  height: 3px;
  top: 0;
  left: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.close-icon-cstm::after {
  content: "";
  position: absolute;
  background: #000;
  width: 18px;
  height: 3px;
  top: 0;
  left: 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.download-btn {
  position: relative;
  float: right;
}
.download-instruction {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background: #ffffff;
  z-index: 99;
  width: 200px;
  left: -95%;
  padding: 20px;
  box-shadow: 0px 1px 3px 1px #d1d1d1;
  font-size: 12px;
  border-radius: 5px;
  margin-top: 15px;
}
.download-btn:hover .download-instruction {
  opacity: 1;
  visibility: visible;
}
.planwrap {
  background: #F2F5FA;
  border-radius: 10px;
  padding: 30px;
}
.planwrap.shadow-view {
  background: #f2f5fa;
}


.planwrap.shadow-view .plantop, .planwrap.shadow-view .plandfeature {
  box-shadow: 0 0px 6px #d0e6ff
}
.planwrap.shadow-view {
  background: #f2f5fa;
}
.plantab{
  display: flex;
  border-bottom: 1px solid #99A5B9
}
.plantab > a{
  color: #99A5B9;
  border: 1px solid #99A5B9;
  padding: 2px 20px; 
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  margin-right: 4px;
  align-self: flex-end;
  text-decoration: none;
}
.plantab > a.active{
  background: #99A5B9;
  padding: 4px 20px;
  color: #fff;
}
.annualplan{border: 0.1px solid #e0e9f5;}
.plantop, .plandfeature{
  background: #fff;
  padding: 20px;
}
.planfeaturedetail .row > div {
  margin-bottom: 30px;
  width: 100%;
}

.planbtn{
  display: flex;
  padding: 20px;
}
.planbtn > a {
  flex: 0 0 50%;
  text-align: center;
  background: #0484F8;
  color: #fff;
  padding: 10px 10px; 
  border: 2px solid #0484F8;
  font-weight: bold;
  text-decoration: none;
}
.planbtn > a.active{
  background: #fff;
  color: #0483f8 !important;
}
.planbtn > a:first-child{
  border-radius: 30px 0 0 30px;
}
.planbtn > a:last-child{
  border-radius: 0 30px 30px 0;
}

.plandfeature>.list-view {
  margin-left: 20px;
}
.plandfeature>.list-view>li {
  padding-left: 0px;
  margin-bottom: 5px;
  font-size: 12px;
  list-style: disc;
  color: #000;
}

.plan-iconwrap {
  border: 2px solid #E2EFFF;
  border-radius: 20px;
  padding: 20px 10px;
  height: 100%;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 3px 6px #d0e6ff;
  transition: all .3s ease-in-out;
  cursor: pointer;
}
.plan-iconwrap img {
  filter: grayscale(1);
  max-width: 60px;
  margin-bottom: 20px;
}
.plan-iconwrap p {
  color: #000;
  margin-bottom: 0;
  font-size: 12px;
}

/*end anas design guide*/

.cursor-default {
  cursor: default;
}

.btn-single a {
  border-radius: 30px !important;
  margin: 0 auto;
  max-width: 200px;
}
.btn-single a:hover {
  background: #036dce;
}

// upgrade popup new design css
.warning-ms {
  position: relative;
  top: 0;
  font-size: 14px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #bf3a31;
  z-index: 99;
  color: #fff;
  padding: 10px;
  width: 100%;
}
.warning-ms p {
  font-family: 'HelveticaNeueLight';
}
.warning-ms + .popupHeader {
  border-radius: 0;
}
.popupLivechat .popupHeader .card-title {
  font-size: 14px;
  font-family: 'HelveticaNeueLight';
}
.billing-text .two-input {
  border: 1px solid #000;
  max-width: 240px;
  float: left;
}
.billing-text .two-input input {
  width: 98px;
  border-radius: 0;
  border: none;
  border-right: 1px solid #000;
  padding: 0 5px;
  min-height: 33px;
}
.billing-text .two-input input:last-child {
  border-right: 0;
  border-left: 1px solid #000;
}
.billing-text .two-input .add-icon {
  padding: 0 10px;
  font-weight: bold;
  font-size: 20px;
}

.billing-text .two-input.no-border {
  border: none;
}
.billing-text .two-input.no-border input {
  border: 1px solid #000;
  width: 95px;
  min-height: 28px;
}


.keyFeaturesList.features ul li {
  width: 100%;
  padding: 6px 0px;
}

.warning-ms .info-icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 8px;
  line-height: 17px;
  text-align: center;
}
.warning-ms .plan-txt {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: middle;
}

.keyFeaturesList.features ul li p {
  font-size: 14px;
  font-family: 'HelveticaNeueLight';
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}
.sidemenutext {
  font-size: 8px;
  display: block;
  color: #fff;
  width: 100%;
  position: relative;
  top: -6px;
  line-height: 1.5;
}

// material design custom css
.material-input .form-control, .material-select .MuiSelect-select:focus {
  background: #fff;
}
.material-input .input-group-append {
  position: absolute;
  right: 1px;
  top: 2px;
  width: 50px;
  height: 43px;
}
.material-input .input-group-append.agent {
  top: 0;
  height: 47px;
}

.material-input .input-group-append .btn {
  border: none;
}
.material-select .makeStyles-formControl-5 {
  margin: 0 8px;
}
.material-input .MuiInputLabel-outlined, .material-select .MuiInputLabel-outlined {
  transform: translate(14px, 15px) scale(1);
  -webkit-transform: translate(14px, 15px) scale(1);
  -moz-transform: translate(14px, 15px) scale(1);
}
.material-input .form-control .MuiOutlinedInput-input, .material-select .MuiOutlinedInput-input {
  padding: 14px;
}
.material-input .form-control textarea.MuiOutlinedInput-input {
  padding: 0;
}

.material-outlined { min-width: 120px !important; }

.material-input .MuiButtonBase-root.MuiButton-root:focus {
  font-size: 0.875rem;
  line-height: 1.75;
}
.input-group.material-input > .form-control {
  flex: 1 1 auto;
  height: auto;
}
.material-input .avatar {
  background: none;
  margin-right: 20px;
}
.material-input .MuiButtonBase-root, .material-input .MuiButtonBase-root:hover,
.material-input .MuiButtonBase-root:focus {
  flex: 1;
  background: none;
  box-shadow: none;
  border: 1px solid #ccc;
}

.input-group.multi-select label {
  margin-left: 10px;
  margin-top: -7px;
  background: #fff;
  z-index: 1;
  padding: 0 10px;
}
.input-group.material-input.widget-input label {
  color: inherit;
  font-size: 14px;
}
.input-group.material-input.widget-input .PrivateNotchedOutline-legendLabelled-3 {
  font-size: 11px;
}
.input-group.material-input input{
  border: inherit !important;
}

.color-change .MuiFormLabel-root, .color-change .MuiSelect-icon {
  color: rgba(255, 255, 255, 1);
}
.color-change .MuiInputBase-root {
  color: rgba(255, 255, 255, 0.87);
}
.color-change .MuiOutlinedInput-notchedOutline,
.color-change .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 1);
}

.roleWithStatus .MuiOutlinedInput-notchedOutline{
    border-color: transparent;
}

.roleWithStatus .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .roleWithStatus .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline{
  border-color: transparent;
}

.material-select.color-change .MuiSelect-select:focus {
  background: #000 !important;
}
.input-group.material-input.widget-input textarea {
  border: 0;
}
.MuiPopover-root, .MuiAutocomplete-popper {
  z-index: 999999 !important;
}
.width-clear {
  width: 100%;
  display: block;
  text-align: center;
  position: absolute;
  top: 120px;
  left: 0;
  color: #000;
}
.Toastify__toast-container--top-right {
  z-index: 9999999 !important;
}
.flex-none {
  flex: none !important;
  z-index: 9999999 !important;
}
.profile-rightpanel, .rightPanelSection {
  z-index: 999999 !important;
}

.try-now .btnBlueSettings {
  font-weight: bold;
  border: 2px solid #1B85FC;
  width: 40%;
}
.try-now > .btnBlueSettings:first-child {
  border-radius: 30px 0 0 30px;
  -webkit-border-radius: 30px 0 0 30px;
  -moz-border-radius: 30px 0 0 30px;
  -ms-border-radius: 30px 0 0 30px;
  -o-border-radius: 30px 0 0 30px;
}
.try-now .btnBlueSettings:last-child {
  background: #fff;
  color: #1B85FC;
  border-radius: 0 30px 30px 0;
}

.z-index1 { z-index: 1;}

.agent-table-header .input-group-append button.btn-cus:hover,
.agent-table-header .input-group-append button.btn-cus:focus, 
.categoryRightPanel .input-group-append button.btn-cus:hover,
.categoryRightPanel .input-group-append button.btn-cus:focus,
.newselectOptionSerchSearch .input-group-append button.btn-cus:hover,
.newselectOptionSerchSearch .input-group-append button.btn-cus:focus {
  background-color: #fff !important;
}

.material-select .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 5px 4px;
}
.material-select .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment button {
  margin: 0 2px;
}

.material-select .small-drop .MuiOutlinedInput-input {
  padding: 11px;
}
.search-select .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding: 5px 4px !important;
}

.drop-ongoing {
  padding: 0 10px;
  height: 40px;
}

.drop-ongoing:focus {
  font-size: 0.9375rem;
}

// upgrade plan css

.planPhoneScreen {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2em 1em;
  width: 300px;
  padding: 25px 18px;
  border-radius: 12px;
  float: left;
}
.planPhoneScreen h4,
.planPhoneScreen .bigTxt {
  font-weight: bold;
  text-align: center;
  color: #000;
}

.trialWithoutCC:hover{
  transform: scale(1.1highlightBtn mt-4);
  transition: 0.45s;
  display: inline-block;
}

.planWrapperTop {
  max-width: 1200px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 40px;
  display: table;
  margin-bottom: 70px;
}
.planWrapperTop > h4 {
  color: #000;
  margin-bottom: 30px;
  text-align: center;
  font-size: 3vw;
  font-weight: normal;
}
.planPhoneScreen .bigTxt {
  font-size: 35px;
}
.planPhoneScreen .bigTxt .currencySymbol {
  font-size: 35px;
  position: relative;
  top: 0;
  left: 6px;
  font-weight: normal;
}
.planPhoneScreen .bigTxt .smallTxt {
  font-size: 14px;
}
.planPhoneScreen .highlightBtn {
  background: #257ef0;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  transition: 0.2s ease-in-out;
  transform: scale(1.05);
}
.planPhoneScreen .highlightBtn:hover {
  transform: scale(1.09);
  background: #0070ff;
}
.planPhoneScreen .bigTxt .smallMulti {
  font-size: 14px;
  position: relative;
  top: -21px;
  right: 6px;
}

.planPhoneScreen .highlightBtn strong {
  display: block;
}
.planPhoneScreen .highlightBtn .smallBtnText {
  font-size: 10px;
  display: block;
}
.planPhoneScreen ul,
.planDetailServices ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;
}
.planPhoneScreen .termRule {
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
}
.planPhoneScreen ul li {
  padding: 4px 0 4px 20px;
  color: #000;
  position: relative;
}

.planPhoneScreen ul li::after {
  content: "";
  position: absolute;
  display: inline-block;
  transform: rotate(45deg);
  height: 15px;
  width: 7px;
  border-bottom: 2px solid #3bc1aa;
  border-right: 2px solid #3bc1aa;
  left: 0;
  top: 4px;
}
.planDetailServices {
  width: calc(100% - 310px);
  float: right;
}
.planDetailServices ul {
  float: left;
  width: 100%;
  margin-top: 70px;
}
.planDetailServices ul li {
  width: calc(33.33% - 20px);
  margin: 10px;
  float: left;
  text-align: center;
  min-height: 190px;
  color: #000;
  font-weight: bold;
}

.planDetailServices ul li img, .planDetailServices ul li svg {
  display: block;
  margin: 0 auto 20px;
  width: 80px;
  height: 80px;
}

.bold-text {
  font-weight: bold;
}


.trialPopup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 99;
}
.trialPopup.fixed {
  position: fixed;
  left: 0;
}
.loginBG.minHeight {
  min-height: 100vh;
  height: auto;
}
.trialPopup .trialPopupBody {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  background: #fff;
  max-width: 500px;
  padding: 50px;
  border-radius: 20px;
  text-align: center;
}
.trialPopup .trialPopupBody img {
  width: 130px;
}

.trialPopupBody .btnBlueSettings {
  border-radius: 8px;
  margin-top: 10px;
  max-width: 300px;
  width: 100%;
}
.trialPopupBody h4 {font-weight: bold;}
.trialPopupBody p {color: #000;}


.trialPopupBody .closeBtn {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #000;
  cursor: pointer;
}
.trialPopupBody .closeBtn::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background: #000;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.trialPopupBody .closeBtn::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background: #000;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.agent-form .posBottom{
  position: absolute;
  bottom: 5px;
}
.btn-disable {
  pointer-events: none;
  opacity: 0.7;
}

li.draggableList {
  cursor: all-scroll;
  border: 1px solid transparent;
}

li.draggableList:hover {
  border: 1px solid #ddd;
}


// canned text css
.addTextArea .addArea {
  width: calc(33.33% - 20px);
  margin: 0 20px 0 0;
}
.addTextArea .addArea > label {
  display: block;
  font-weight: bold;
  color: #000;
}
.addTextArea .addArea textarea, .addTextArea .addArea input {
  width: 100%;
}

.addTextArea .main-checkbox-all {
  top: -7px;
}

.addTextArea .table.liveCgatUserTable tr td img {
  max-width: 20px;
}



.cannedPop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.59);
  z-index: 9;
}

.cannedPop .CPopupBody {
  position: absolute;
  top: 50%;
  max-width: 450px;
  background: #fff;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}
.cannedPop .CPopupBody .heder-icon-img {
  position: absolute;
  right: 10px;
  top: 10px;
}

.cannedPop .CPopupBody .btnBlueSettings.del {
  background: #f54a4a;
  border: none;
  border-radius: 4px;
  padding: 5px 25px;
}
.cannedPop .CPopupBody .btnBorderSettings, .cannedPop .CPopupBody .btnBlueSettings {
  padding: 5px 25px;
}

.cannedPop .CPopupBody .addArea > label {
  font-weight: bold;
  color: #000;
  font-size: 14px;
}
.cannedPop .CPopupBody .radioCheckmark {
  top: 3px;
}


// hasgtag popup css

.cannedPop .CPopupBody.hashtagPop {
  padding: 0;
  max-width: 200px;
}
.cannedPop .CPopupBody.hashtagPop .hashtagHeader {
  background: #1c223a;
  color: #fff;
  padding: 5px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 14px;
}
.cannedPop .CPopupBody.hashtagPop ul {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 300px;
  overflow: auto;
}
.cannedPop .CPopupBody.hashtagPop ul li {
  padding: 5px 10px 0;
  cursor: pointer;
}
.cannedPop .CPopupBody.hashtagPop ul li:hover {
  background: rgba(0, 0, 0, 0.07);
}

.cannedPop .CPopupBody.hashtagPop ul li span {float: right;}
.cannedPop .CPopupBody.hashtagPop ul li span img {
  max-width: 12px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
.cannedPop .CPopupBody.hashtagPop .hashtagFooter {
  background: #1c223a;
  color: #fff;
  padding: 5px 15px;
  text-align: center;
  font-size: 14px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.cannedPop .CPopupBody.hashtagPop ul li .hashtagText {
  float: none;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 25px);
  display: inline-block;
  text-overflow: ellipsis;
}

.cannedPop.chatHaspop {
  position: absolute;
  background: none;
  max-height: 364px;
  top: auto;
  bottom: 100px;
}
.cannedPop.chatHaspop .CPopupBody.hashtagPop {
  top: auto;
  transform: translateY(0px);
  bottom: 0;
  left: 30px;
  right: auto;
  width: 100%;
  box-shadow: 0px 2px 5px #a8a8a8;
}



// canned text chatform

.input-group.magSuggest .textviewSuggest.suggest-open #msg.textAreaChat {
  background: #fff !important;
  border-radius: 20px;
  // min-height: 120px !important;
}
.textviewSuggest {
  flex: 1 1;
  position: relative;
}
.textviewSuggest .absSuggest {
  position: absolute;
  width: 100%;
  color: #000;
  max-height: 80px;
  padding: 0;
  overflow: auto;
  top: -90px;
  z-index: 9;
  background: #fff;
  box-shadow: 0px 0px 2px 1px #e9e9e9;
  border-radius: 5px;
}
.textviewSuggest .absSuggest.list-1 {
  top: -35px;
}
.textviewSuggest .absSuggest.list-2 {
  top: -65px;
}
.textviewSuggest .absSuggest ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.textviewSuggest .absSuggest ul li {
  padding: 2px 7px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
.textviewSuggest .absSuggest ul li:hover {
  background: #ededed;
}
.textviewSuggest .absSuggest ul li:last-child{
  border: none;
}
.textviewSuggest .absSuggest ul li .redText {
  color: #f00;
  font-weight: bold;
}

.textviewSuggest .absSuggest ul li .blueText {
  color: #9fc8fd;
  font-weight: bold;
}

/* For Smart Compose Start */

[contentEditable=true]:empty:before {
  content:attr(placeholder);
  cursor: text;
  opacity: 0.5;
}
.cstmSmartCompose {
  border-radius: 20px !important;
  overflow: auto !important;
  height: auto !important;
  scrollbar-width: thin;
}

//#autocomplete { opacity: 0.6; background: transparent; position: absolute; box-sizing: border-box; cursor: text; pointer-events: none; color: black; width: 400px; } .vc_textarea{ padding: 10px; min-height: 100px; resize: none; } #mainInput{ background: transparent; color: black; opacity: 1; width: 400px; } #autocomplete{ opacity: 0.6; background: transparent; }

/* For Smart Compose End */
.text-underline {
  text-decoration: underline;
}
.socialAlign .social-img {
  //width: 50%;
  width: 33%;
}
.socialAlign .social-img span {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}
.socialAlign .social-img.heightEqual span {
  padding-right: 10px;
}

.verification-popup.zoomDelete .cstmpopup {
  max-width: 350px;
}
.verification-popup.zoomDelete h5{
  font-weight: bold;
}
.verification-popup.zoomDelete .popup-header::after{display: none;}
.verification-popup.zoomDelete .close-icon-cstm {
  right: 30px;
  top: 8px;
}
.zoomDelete .input-control .cstm-input-resend,
.zoomDelete .input-control .cstm-input-resend:active {
  background: none;
  border: none;
  color: #000;
  border-radius: 3px;
}
.zoomDelete .input-control .cstm-input-resend {
  background-color:#f1f1f1;
}
.zoomDelete .input-control .cstm-input-resend.red, 
.zoomDelete .input-control .cstm-input-resend.red:active {
  background-color: #f54a4a;
  border-color: #f54a4a;
  color: #fff;
}

.integration-card .title-card li {
  width: calc(25% - 20px);
}
.integration-card .title-card li span {
  text-align: center;
  display: block;
}
.integration-card .title-card li span p{
  display: none;
}
.integration-card .title-card li .InstalledApp span p{
  display: block;
}
.integration-card .title-card li img {
  max-width: 60px!important;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
}
.setup-list-design .title-card li .titleInstall {
  background: #4a8cff;
  color: #fff;
  max-width: 80px;
  border-radius: 8px;
  padding: 6px 0;
  margin-top: 15px;
  font-size: 14px;
}
.integration-card .title-card li .InstalledApp .titleInstall {
  // visibility: hidden;
}
.integration-card .title-card li .InstalledApp p {
  position: absolute;
  top: 0;
  right: 0;
  background: #4caf50;
  color: #fff;
  padding: 4px 15px;
  padding-left: 35px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 12px;
}
.integration-card .title-card li .InstalledApp p::before {
  content: "";
  position: absolute;
  background: #fff;
  width: 15px;
  height: 15px;
  z-index: 1;
  border-radius: 50%;
  right: 70px;
  top: 5px;
  font-size: 320px;
  outline: none;
  border: none;
}
.integration-card .title-card li .InstalledApp p::after {
  content: "";
  position: absolute;
  right: 75px;
  top: 6px;
  width: 6px;
  height: 10px;
  border: solid #4caf50;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  z-index: 2;
}
.sidePopup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0px 0px 8px 0px #dbdbdb;
}
.sidePopup::after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
}
.sidePopup .sidePopupBody {
  max-width: 500px;
  position: absolute;
  width: 100%;
  right: 0;
  z-index: 9999;
  background: #fff;
  height: 100%;
}

.sidePopup .sidePopupBody .cross {
  position: absolute;
  z-index: 99;
  right: 20px;
  top: 10px;
  cursor: pointer;
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
}
.popHead {
  font-weight: bold;
  font-size: 14px;
  background: #1c223a;
  padding: 15px 20px;
}
.popHead h5 {color: #fff;}
.sidePopup .sidePopupBody .cross img {
  width: 11px;
}
.sidePopup .contentPop {
  padding: 20px;
}

.chartCss .apexcharts-canvas {
  margin: 0 auto !important;
}

// .chartCss .apexcharts-legend.position-bottom .apexcharts-legend-series, 
// .chartCss .apexcharts-legend.position-top .apexcharts-legend-series {
//   justify-content: center !important;
// }
.nowrap-text {
  white-space: nowrap;
}
.moneyBanner .bannerText {
  width: calc(100% - 130px);
  padding: 20px 30px;
}
.moneyBanner .bannerText strong {
  color: #000;
}
.moneyBanner .moneyImg {
  width: 130px;
  background: #eff2f9;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px 30px;
}
.moneyBanner .moneyImg img {
  width: 100%;
}

.moneyBanner {
  background: #f8f9fd;
  border: 2px solid #eff2f9;
  border-radius: 20px;
}
.addTextArea .addArea.step1.position-top .tour_guide {
  right: auto;
  top: 50px;
}
.addTextArea .addArea.step1.position-top .tour_guide::after, 
.addTextArea .addArea.step1.position-top .tour_guide::before {
  right: auto;
  left: 15px;
}

.addTextArea .addArea.step2.position-top .tour_guide::after,
.addTextArea .addArea.step2.position-top .tour_guide::before,
.addTextArea .addArea.step3.position-top .tour_guide::after,
.addTextArea .addArea.step3.position-top .tour_guide::before {
  right: auto;
  left: 30px;
}
.addTextArea .addArea.step2.position-top .tour_guide {
  top: 45px;
  left: 0;
  right: auto;
}

.addTextArea .addArea.step3.position-top .tour_guide {
  left: 0;
  top: 50px;
  right: auto;
}
.search-feilds-history.position-top .tour_guide {
  top: 70px;
}
.agent-table .position-top .tour_guide {
  left: auto;
  right: 0;
  top: -130px;
}
.agent-table .position-top .tour_guide::after {
  left: auto;
  bottom: -10px;
  right: 80px;
  top: auto;
}
span.d-flex.position-top.posED {
  position: relative;
  max-width: 100px;
}
.agent-table .first .position-top.posED .tour_guide {
  right: 0;
}
.agent-table .first .position-top.posED .tour_guide::after{
  right: 55px;
}
.agent-table .position-top.posED .tour_guide {
  width: 400px;
  top: -190px;
  right: -60px;
}
.addTextArea .table.liveCgatUserTable tr td img.upper-div {
  background: #fff;
}

.agent-table .position-top .upper-div {
  background: #fff;
}



.fb-insta {
  background: url('../images//livechat/56px-Instagram_icon.png');
  background-size: 100% 100%;
}

.launch-live {
  position: absolute;
  left: 0;
  right: 0;
  top: 150px;
  // transform: translateY(-50%);
  background: #fbfaff !important;
}
.launch-live img {
  max-width: 400px;
}
.launch-live .tour_guide {
  margin-top: 20px;
}

.visitor-info {
  box-shadow: 0px 1px 4px 0px #a9a9a978;
  padding: 40px 60px;
  border-radius: 10px;
  color: #2c3049;
}
.visitor-info ul, .visitor-info ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.visitor-info .user-iconVisitor {
  position: relative;
  left: -5px;
  font-size: 14px;
}
.visitor-info .user-iconVisitor img {
  position: absolute;
  left: -45px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  top: -6px;
}

.visitor-info ul li {
  width: 50%;
  padding: 5px 0;
  color: #2c3049;
  font-size: 13px;
}
.visitor-info ul li svg {
  margin-right: 5px;
}

// Triggers page css


.main-checkbox-all.triggers-check {
  top: -20px;
}
.main-checkbox-all.triggersTop-0 {
  top: 0;
}

span.checkIcon {
  position: relative;
  display: block;
}
span.checkIcon::after {
  left: 9px;
  top: 5px;
  width: 8px;
  height: 17px;
  border: solid #0d84f6;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  display: block;
  text-align: center;
  margin: 0 auto;
}

.triggers-design .user-table-main-header .search-bar {
  width: 70%;
}
.triggers-design .user-table-main-header .search-bar .search-div {
  width: calc(100% - 175px);
  position: relative;
  float: left;
}
.triggers-design .user-table-main-header .fltr-view {
  width: 30% !important;
  text-align: right;
}

.triggers-design .btnBlueSettings, .triggers-design .btnBlueSettings:focus, .triggers-design.btnRestore {
  font-size: 14px;
  color: #fff;
  padding: 11px 30px;
}
.triggers-design .card-info {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  position: relative;
}
.triggers-design .card-info .helper-info {
  font-size: 11px;
}
.triggers-design .card-info .link-style {
  color: #0176ff !important;
  cursor: pointer;
  text-decoration: underline !important;
  float: right;
  font-size: 13px;
  margin-top: 15px;
}

.triggers-design .card-info .crossAbs {
  position: absolute;
  top: 0;
  cursor: pointer;
  right: 8px;
  z-index: 1;
}
.triggers-design .card-info .crossAbs svg {
  width: 16px;
}
.triggers-design .setting-section strong {
  color: #4a4a4a;
  font-size: 14px;
}
.material-select .pad-0 textarea.MuiOutlinedInput-input {
  padding: 0;
}
.border-bottom {
  border-bottom: 1px solid #ebebeb !important;
}
.no-event {
  pointer-events: none;
  opacity: 0.4;
}

.visitor-btnGrp {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  background: #fff;
  box-shadow: 10px 10px 26px rgba(128, 128, 128, 0.12);
}
.visitor-btnGrp button {
  width: 50%;
  border: none;
  background: #fff;
  padding: 10px;
}
.visitor-btnGrp button.active {
  position: relative;
}
.visitor-btnGrp button.active::before {
  content: "";
  position: absolute;
  background: #0176ff;
  width: 90px;
  height: 6px;
  bottom: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}




// Pop up widget functionality css
.imageList {
  width: calc(100% - 270px);
  padding-right: 10px;
  position: relative;
}
.imageList::after{
  content: "";
  position: absolute;
  background: #ddd;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
}
.text-img-view {
  width: 250px;
  padding-left: 20px;
  position: relative;
}
.imageList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.imageList ul li {
  width: calc(25% - 20px);
  margin: 5px;
  cursor: pointer;
  padding: 8px 2px;
  text-align: center;
  min-width: 80px;
  max-width: 100px;
}
.imageList ul li svg {
  width: 50px;
}
.imageList ul li p {
  font-size: 12px;
}
.imageList ul li.active {
  background: #efefef;
  color: #000;
  border-radius: 10px;
}
.text-img-view .default-view {
  border-radius: 10px;
  color: #fff;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2em 1em;
  pointer-events: none;
}
.text-img-view .default-view .default-head {
  display: block;
  text-align: center;
  font-size: 11px;
  position: relative;
  padding: 10px;
}
.text-img-view .default-view .default-head::after {
  content: "";
  position: absolute;
  background: #fff;
  width: 15px;
  height: 4px;
  right: 10px;
  top: 15px;
}

.default-view .inputView {
  background: #fcfcfc;
  padding: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 12px;
  text-align: left;
  color: #a9a9a9;
  font-weight: 500;
  position: relative;
}
.default-view .inputView img {
  width: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.default-view .view p{
  font-size: 12px;
  word-break: break-word;
  width: calc(100% - 50px);
  text-align: left;
  padding-right: 10px;
  padding-left: 10px;
}

.default-view .view img {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto 10px;
  border-radius: 10px;
}
.color-picker label{
  display: block;
  color: #4f4f4f;
  font-weight: bold;
}
.color-picker .picker-input {
  display: inline-block;
  max-width: 300px;
  width: 100%;
  border: 1px solid #ccc;
  height: 40px;
  border-radius: 4px;
  position: relative;
  padding: 5px 10px;
}

.color-picker .picker-input input {
  margin: 0;
  width: 100%;
  position: absolute;
  height: 40px;
  z-index: 9;
  padding: 0;
  opacity: 0;
}
span.picker-text {
  width: calc(100% - 300px);
  padding-left: 10px;
  display: flex;
  align-items: center;
}
span.picker-text input, span.picker-text label {
  cursor: pointer;
}
.picker-text label{
  padding-left: 10px;
  margin-top: 10px;
}
.upload-view label{
  display: block;
}
.upload-view .upload-box {
  position: relative;
  width: 150px;
}
.upload-view .upload-box input {  
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 120px;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}

.upload-box .upImg-view{
  width: 150px;
    height: 120px;
    display: inline-block;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    font-size: 11px;
    text-align: center;
    position: relative;
}
.upload-box .upImg-view .up-icon {
  display: block;
  text-align: center;
  font-size: 35px;
  line-height: 1;
}
.upload-box .upImg-view .up-icon + span{
  color: #1b85fc;
  font-weight: bold;
  cursor: pointer;
}
.upload-view .upload-box img {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
}
.upload-box .delete-btn {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 9;
}
.upload-box .delete-btn img {
  width: 100%;
}



.loader-overflow {
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.loader-overflow::after {
  content: "";
  position: absolute;
  background: rgba(191, 192, 200, 0.72);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 1;
}
.loader-overflow img {
  z-index: 9;
}
// .badge-icon img{
//   width: 15px;
//   height: 15px;
//   border-radius: 50%;
//   background: #efefef;
//   margin-left: 5px;
// }
.badge-icon svg {
  width: 20px;
  height: 20px;
  fill: #ffa500;
  background: #055dc1;
  border-radius: 50%;
  margin-left: 8px;
  padding: 2px;
}

.font-12 {
  font-size: 12px;
}

.agent-table.scroll-view-tbl {
  overflow-x: auto;
  max-height: calc(100vh - 280px);
}

.popupInstallLivechat.popupLivechat.feedbackHistory {
  white-space: pre-wrap;
}

.idle-section .position-top .tour_guide {
  top: 65px;
}
.position-top.bg-area .upper-div {
  background: #fff;
  border-radius: 5px;
}

.idle-section .top-area.position-top.bg-area .tour_guide {
  top: 90px;
}
.tour_guide.condation-view{
  left: 0;
  right: auto;
  top: 85px;
}
.tour_guide.condation-view::after {
  left: 80px;
  right: auto;
}
.tour_guide.condation-view.add-new {
  left: auto;
  right: 0;
  top: 65px;
}
.tour_guide.condation-view.add-new::after {
  left: auto;
  right: 60px;
}

.widget-message.time_zone h6{
  font-size: 12px;
  max-width: 180px;
  font-weight: bold;
  width: 100%;
  color: #000;
}

.widget-message.time_zone > div {
  width: calc(100% - 190px);
  position: relative;
}
.widget-message.time_zone p.error {
  color: #ff0000;
  position: absolute;
  left: 0;
  margin: 0;
  font-size: 10px;
}
.widget-message.time_zone > p {
  color: #000;
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
}

.trialPopup.timezone-popup {
  position: fixed;
  z-index: 99999;
}
.trialPopup.timezone-popup p {
  font-size: 14px;
}
.trialPopup.timezone-popup .highlight {
  color: #cf201e;
  margin-bottom: 20px;
}
.trialPopup.timezone-popup .btnBlueSettings {
  max-width: 200px;
  font-size: 16px;
}

.trialPopup.timezone-popup .widget-message.time_zone h6 {
  text-align: left;
}
.perfect.Feilds.accordian-view {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
}
.perfect.Feilds.accordian-view:hover {
  background: #f7f7f7;
  border-color: #ebebeb !important;
}

.down-arrow {
  position: absolute;
  right: 0;
  top: 24px;
}
.down-arrow::after {
    content: "";
    position: absolute;
    right: 14px;
    width: 10px;
    height: 2px;
    background: #000;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
}
.down-arrow::before {
  content: "";
  position: absolute;
  right: 20px;
  width: 10px;
  height: 2px;
  background: #000;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.down-arrow.active::after {
  transform: rotate(45deg);
}

.down-arrow.active::before {
  transform: rotate(135deg);
}


.free_user {
  background: #fff;
  max-width: 600px;
  position: absolute;
  top: 50%;
  width: calc(100% - 10px);
  left: 0;
  right: 0;
  margin: 5px auto;
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.free_user img {
  max-width: 100px;
}
.free_user h4 {
  font-weight: bold;
  color: #000;
}
.free_user p {
  color: #000;
}

.feedback-view .column {
  width:50%;
}
.chatReportCol.cstm-box-time .chatReport-total-chats.average-rating {
  padding-top: 5px;
}
.chatReportCol.cstm-box-time .average-rating .online-time p {
  font-size: 12px;
}
.chatReportCol.cstm-box-time .average-rating .online-time p .big{
  font-size: 24px;
}
.chatReportCol.cstm-box-time .average-rating .wait-time-others.line-diff::before {
  top: 0;
  left: -20px;
}

.ticket-section {
  background: #fff;
  padding: 15px;
}
.ticketing-cont {
  max-width: 650px;
  width: 100%;
}
.ticket-section .ticket-subject textarea {
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.ticket-row label {
  display: block;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
.ticket-row select {
  width: 100%;
  padding: 6px;
  border-color: #ccc;
  border-radius: 5px;
}
.priority-view {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 380px;
}
.priority-view .pri-btn {
  position: relative;
}
.priority-view .pri-btn input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.priority-view .pri-btn {
  position: relative;
  padding: 5px 8px;
  border: 1px dotted #ccc;
  border-radius: 5px;
  color: #000;
}
.priority-view .pri-btn input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  left: 0;
  top: 0;
}
.priority-view .pri-btn label {
  margin: 0;
  font-size: 14px;
}
.ticket-row .small-font {
  font-size: 12px;
  display: inline-block;
  margin: 5px 0 15px;
}
.description textarea{
  border-color: #ccc;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}
.description input{
  margin-top: 10px;
  float: right;
  border-radius: 5px;
}
.link-btn{
  border: none;
  background: no-repeat;
  color: #1e90ff;
}
.link-btn.assigned {
  background-color: #1e90ff;
  padding: 5px 10px;
  border-radius: 100px;
  color: #fff;
}
.priority-view .pri-btn button {
  border: 0;
  background: none;
}
.top-sec div {
  padding-right: 20px;
}
.top-sec div input {
  transform: scale(1.5);
  cursor: pointer;
}
.top-sec div label {
  padding-left: 10px;
  cursor: pointer;
}
.ticket-history {
  width: 100%;
  float: left;
}
.ticket-history .ticket-list {
  background: #f1f0f0;
  padding: 8px;
  margin-bottom: 12px;
  cursor: pointer;
}
.ticket-history .ticket-list:hover{
background-color: #DFF0FD;
}
.ticket-list .ticket-title {
  max-width: 185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #000;
}
.ticket-list .ticket-title strong{
  color: #000;
}
.ticket-list .ticket-title small{
  color: #858181;
}
.ticket-list .priority {
  font-size: 10px;
  color: #1e90ff;
  margin-left: auto;
}
.ticket-list .edit-view {
  position: relative;
  background: #1c223a;
  color: #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 10px;
  text-align: center;
  line-height: 30px;
  margin-left: 5px;
}

.ticket-list .edit-view img {
  position: absolute;
  top: 0;
  right: -2px;
  cursor: pointer;
}


// add dropdown css

.navbarDropdown.dropdown-menu {
  display: block;
}

.navbarDropdown {
  position: absolute !important;
  padding: 0;
  margin: 0;
  right: 0;
  top: 85%;
  width: 250px;
  border-radius: 9px 0px 9px 9px;
  box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.07);
  -o-box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.07);
  border: 0;
  z-index: 9;
  left: 0px;
}

.w-300 {
  width: 300px;
}

.top60 {
  top: 60px !important;
}

.agentTeamTab {
  border-bottom: 1px solid #e2e2e2;
  position: relative;
}

.agentTeamTab span {
  font-size: 14px;
  color: #b2b2b2;
  padding: 6px 10px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.agentTeamTab span.active {
  color: #64b5f6;
}

.agentTeamTab span.active::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #63b5f4;
  width: 100%;
  left: 0;
  bottom: -2px;
}

.md-form {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.md-form.md-form-custom {
  margin-top: 0;
  margin-bottom: 0;
}

.ps-relative {
  position: relative;
}

.searchIcon-dep {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.cursor-pointer {
  cursor: pointer;
}

.md-form.md-form-custom .form-control {
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
}

.md-form .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-sizing: border-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.department-acc-height {
  max-height: 200px;
}

.scrollbar {
  overflow-y: auto;
  padding-right: 10px;
  scrollbar-width: thin;
}

.department-acc .partment-row {
  padding: 10px 0;
  cursor: pointer;
}

.clr-grey {
  color: #848484;
}

.fnt-13 {
  font-size: 13px;
}
.department-acc .partment-row img {
  width: 35px;
}
.msgfilesNew audio, .message-row audio { width: 170px; display: inline-block; }

.form-group.eyepass {
  position : relative;
}

.form-group.eyepass div {
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 1;
  cursor: pointer;
}

.sdkInstall-view .tabs-view {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}
.sdkInstall-view .tablink {
  list-style: none;
  display: inline-block;
  width: 120px;
  margin-right: -4px;
  text-align: center;
  color: #000;
  cursor: pointer;
}
.sdkInstall-view .tabs-view .tablink.on {
  background: #e8e7e8;
  padding: 5px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-weight: bold;
}
.sdkInstall-view .content {
  display: none;
  opacity: 0;
  color: #000;
}

.content-section {
  padding:  0 20px;
}
.content-section .on {
  display: block;
  opacity: 1;
}
.content-section ol {
  padding-left: 20px;
  margin-top: 10px;
}
.content-section ol li {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: normal;
  color: #4f566b;
  font-family: 'HelveticaNeueMedium';
}

.content-section ol li .btnBlue {
  position: absolute;
  right: 0;
  top: -6px;
}
.content-section ol li .btnBlue:hover{
  color:#fff
}
.content-section ol li .btnBlue svg {
  margin-left: 8px;
}
.content-section ol li:first-child .code-view{
  margin-top: 25px;
}
.trial-features .btnBlue:hover {
  color: #fff;
}

.content .code-viewHeader{
  background: #3c4257;
  border-radius: 8px 8px 0px 0px;
  padding: 5px 20px;
}

.codeViewHeaderLeft p{
  color: #a3acb9;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 12px !important;
}

.content .code-view {
  background: #4f566b;
  padding: 15px 20px;
  border-radius: 0px 0px 8px 8px;
  color: #c1c9d2;
  margin-top: 0px;
  position: relative;
  padding-right: 40px;
  font-size: 14px;
  font-family: 'Menlo Regular';
}
.content .code-view .copy-icon {
  position: absolute;
  right: 10px;
  background: #ffffff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer; 
  box-shadow: 0px 2px 7px #80808052;
}
.content .code-view span {
  font-size: 14px;
  white-space: nowrap;
}

.aquaCodeText{
  color: #a4cdfe;
}

.greenCodeText{
  color: #85d996;
}

.redCodeText{
  color: #f8b886;
}

.whiteCodeText{
  color: #f5fbff;
}

.content .code-view .code-info {
  // white-space: nowrap;
  overflow-x: auto;
}
.content .code-view .copy-icon svg {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}
.myp{
  position: relative;
}

.preview {
  position: absolute;
  right: 5px;
  top: 0;
  border-bottom-style: dotted;
}
.upgrade, .upgrade:focus{
  background: #4384EF;
  border: 0;
  color: #fff; 
  font-size: 10px;
  padding: 4px 15px 4px 4px;
  border-radius: 100px;
  line-height: 1.8;
  left: 10px;
    position: relative;
    top: -5px;
}

.upgrade:hover{
  opacity: 0.9;
}

.upgrade span{
  width: 20px;
  height: 20px;
  background: #fff;
  display: inline-block;
  border-radius: 100%;
  margin-right: 5px;
}

.myProfilePopups .my-profile-list, .myProfilePopups .logout-mob{
  width: 100%;
  text-align: center;
  padding: 10px 10px;
  color: #BEBEBE;
  font-size: 14px;
}

.myProfilePopups .my-profile-list svg, .myProfilePopups .logout-mob svg{
  margin-right: 5px;
}

.myProfilePopups .my-profile-list:hover, .myProfilePopups .logout-mob:hover{
  background-color: #D0D5E8;
  color: #333;
  cursor: pointer;
}

.myProfilePopups .my-profile-list:hover svg, .myProfilePopups .logout-mob:hover svg, 
.myProfilePopups .my-profile-list:hover svg circle, .myProfilePopups .logout-mob:hover svg circle, 
.myProfilePopups .my-profile-list:hover svg path, .myProfilePopups .logout-mob:hover svg path{
  fill: #428BFD;
}

.roleWithStatus .role, .roleWithStatus .status{
  width: 50%;
  padding: 10px 10px;
}

.roleWithStatus .role{
  margin-right: auto;
}

.roleWithStatus .role p{
  text-align: left;
  color: #939393;
  font-weight: 100;
  font-size: 12px;
}

.roleWithStatus .role .OutPutRole h6{
  text-align: left;
  font-weight: 500;
  line-height: 1.1;
  font-size: 14px;
}

.matLabOnlStatus .MuiSelect-selectMenu {
  height: auto;
  overflow: hidden;
  min-height: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  border: 0;
  padding: 0;
}

.matLabOnlStatus .MuiInputLabel-outlined{
  position: relative;
    display: inline-block;
    text-align: left;
    width: 100px;
    margin: 0;
    top: 7px;
    left: 0px;
    color: #939393;
    font-weight: 100;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

.matLabOnlStatus .MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent;
}


.matLabOnlStatus .PrivateNotchedOutline-root-1{
  border: 0px;
}

.my-profile-section-rightP{
  background-color: #F6F8FB;
}

.my-profile-section-rightP .avatar{
  background: #07f;
  color: #fff;
}

.profile-status .online{
  position: absolute;
  display: block;
  left: 0px;
  bottom: 5px;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background: #2bc02b;
}

.profile-status .offline{
  position: absolute;
  display: block;
  left: 0px;
  bottom: 5px;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background: #d3d3d3;
}

.matLabOnlStatus .MuiSelect-outlined.MuiSelect-outlined {
  outline: 0;
}

.matLabOnlStatus .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}
.table-borderless .border-radius-table tr td{
  vertical-align: middle;
}

.table-borderless .border-radius-table tr, .outline-1-black tr{
  border-bottom: 1px solid #dfdfe6;
}

.trialPopup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.65);
}
.trialPopup .trialPopupBody {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  background: #fff;
  max-width: 500px;
  padding: 50px;
  border-radius: 20px;
  text-align: center;
}
.trialPopup .trialPopupBody img {
  width: 130px;
}

.trialPopupBody .btnBlueSettings {
  border-radius: 8px;
  margin-top: 10px;
  max-width: 300px;
  width: 100%;
}
.trialPopupBody h4 {font-weight: bold;}
.trialPopupBody p {color: #000;}


.trialPopupBody .closeBtn {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #000;
  cursor: pointer;
}
.trialPopupBody .closeBtn::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background: #000;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.trialPopupBody .closeBtn::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background: #000;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.trialPopupBody .btnBlueThm{
  background: #1B85FC;
  border: 1px solid #1B85FC;
  font-size: 14px;
  color: #fff;
  padding: 11px 25px;
  box-shadow: 0px 6px 12px rgba(27, 133, 252, 0.34);
  border-radius: 8px;
  margin-top: 10px;
  max-width: 300px;
  width: 100%;
}
// SHUBHAM Agent login page
.vr-line {
  border-right: 1px solid #000;
  height: 30px;
  display: inline-block;
  margin: 0px 23px;
  position: relative;
  top: 8px;
}

button.otp-btn {
  padding: 0px 18px !important;
  padding-left: 6px !important;
}


.card-body .avatar {
  // background-color: goldenrod;
  line-height: 2.8rem;
  text-align: center;
  color: #fff;
}

.media-body .small.timevalue {
  position: absolute;
  top: 12px;
  right: 0;
}

.conversationLC::before{
  content: attr(data-time-date);
  position: absolute;
  color: #fff;
  font-size: 9px;
  right: 19px;
  top: 19px;
}

.conversationCount::after{
  content: attr(count);
  position: absolute;
  color: #fff;
  font-size: 10px;
  right: 2px;
  top: 3px;
  background-color: #e87b2e;
  width: 19px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  border-radius: 100%;
}



.conversationLC .media-body::after{
  content: attr(msg);
  color: #fff;
    font-size: 12px;
    font-family: "HelveticaNeueLight";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;
    display: block;
  }


.conversationLC .media-body::before{
  content: attr(name);
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.3px;
    display: block;
}


.visitore_heding_size::before{
  content: attr(visitorId);
} 

.listing-right-panel-information::after{
  content: attr(curPagUrl);    
  font-family: "HelveticaNeueLight";
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #474747!important;
  font-weight: 400;
}

.socialIconVis{
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  left: 0;
  bottom: -15px;
  max-width: 20px !important;
  min-width: 20px !important;
}

.session-expire {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  max-width: 500px;
  padding: 40px;
  border-radius: 20px;
  z-index: 99999;
  background: #fff;
  box-shadow: 0 6px 30px #5d5d5d70;
  transform: translateY(-50%);
}

.session-expire h4 {
  padding-top:0px;
  padding-bottom: 30px;
  margin-top: 0px;
  color: #3594e2;
}

.session-expire p {
  margin: 0;
  color: #000;
  font-style: 12px;
}

.session-expire .ref-btn {
  margin: 0;
}

.session-expire .ref-btn:hover {
  box-shadow: 0 1px 2px #5d5d5d70;
  background: #f5f5f5;
}

.session-expire .ref-btn {
  margin-top: 35px;
  border: 1px solid #ccc;
  background: no-repeat;
  padding: 15px 30px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.session-popup.call-allow {
  position: relative;
}
.call-allow .session-expire .crossIcon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 50px;
}
.session-popup.call-allow::after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(44, 44, 44, 0.6);
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
}
.call-allow .session-expire {
  max-width: 400px;
    padding: 40px 20px;
    border-radius: 15px;
}
.call-allow .session-expire img {
  width: 100%;
  max-width: 300px;
}
.call-allow .session-expire h3 {
  font-size: 21px;
  margin-top: 20px;
}
.call-allow .session-expire p{
  font-size: 12px;
}
.bg-img {
  background: url('../img/bg.svg') no-repeat center;
  width: 100%;
  min-height: 50px;
  position: relative;
  background-size: cover;
}

.flex-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  color: #fff;
  gap: 25px;
}

.store-btn {
  background: no-repeat;
  outline: none;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 0px 15px;
  text-decoration: none;
}

.p-top-6 {
  padding-top: 6px;
}

.store-btn .flex-div {
  min-height: 36px;
  gap: 10px;
}

.store-btn img {
  width: 15px;
}

.btn-text .store {
  font-size: 14px;
}

.btn-text small {
  font-size: 10px;
  text-align: left;
}

.crossIcon {
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
}

.testWedget-bg {
  background: url('../images/livechat/backbg-wedget.png') no-repeat center;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
}

.headerWedget {
  box-shadow: 0px 3px 16px #0000001C;
  padding: 15px;
  background: #fff;
}

.headerWedget img {
  width: 150px;
}
.headerWedget .btn-primary {
  background: transparent;
  color: #0783F8;
  border: 2px solid #0783F8;
  font-weight: bold;
  white-space: nowrap;
}

.companyName {
  position: absolute;
  left: 13%;
  top: 20%;
  color: #2C3049;
}
.companyName h1 {
  font-size: 42px;
}


// / access-denied  Popup design /

.popupInstallLivechat.access-denied {
  position: fixed;
  width: calc(100% - 60px);
  height:100%;
  background: rgba(0,0,0, 0.8);
  border-radius: 0;
  max-height: 100vh;
  right: 0;
  left: auto;
  transform: translate(0%, 0%);
  top: 0px;
  z-index: 9;
}
.popupInstallLivechat.access-denied.sidePannel-view {
  width: calc(100% - 222px);
}
.popupInstallLivechat.access-denied .free_user {
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
  border-radius: 14px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  padding: 40px;
}
.popupInstallLivechat.access-denied img {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}
.access-denied .free_user p {
  font-size: 18px;
  max-width: 420px;
  margin: 0 auto 20px;
  width: 100%;
}
.access-denied .free_user .btnBlue {
  font-weight: bold;
  padding: 12px 30px;
  font-size: 18px;
}

@media (max-width:1200px) {
  .popupInstallLivechat.access-denied.sidePannel-view {
    width: calc(100% - 62px);
}
}
@media (max-width: 1199px) {
  .popupInstallLivechat.access-denied {
    width: calc(100% - 0px);
    height: calc(100% - 61px);
  }
  .popupInstallLivechat.access-denied.sidePannel-view {
    width: calc(100% - 0px);
    height: calc(100% - 61px);
}
}




@media (max-width:500px) {
  .bg-img {
      background-size: cover;
      padding-bottom: 10px;
  }

  .flex-div {
      flex-wrap: wrap;
      gap: 5px;
      padding-top: 0px;
      min-height: 110px;
  }

  .store-btn,
  .stars {
      margin-top: -40px;
  }

  .crossIcon {
      top: 15px;
      right: 8px;
  }
}

.phoneInput {
  position: relative;
}
.phoneInput select.customArrow {
  position: absolute;
  top: 0;
  width: 90px;
  left: 0;
  border: none;
}
.form-control.p-cstm {
  padding-left: 130px !important;
}
.phoneInput::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 30px;
  background: #ccc;
  top: 6px;
  left: 110px;
}

.verifyEmail-strip {
  background: #3E4460;
  padding: 10px 70px;
  gap: 20px;
  color: #fff;
  position: relative;
}
.verifyEmail-strip::after, .verifyEmail-strip::before {
  content: '';
  background: #FBBD05;
  width: 10px;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}
.verifyEmail-strip::before {
  left: auto;
  right: 0;
}

.verifyEmail-strip button {
  border: 1px solid #ccc;
  background: #fff;
  padding: 5px 20px;
  border-radius: 6px;
  color: #1C223A;
  font-weight: 500;
}

@media (max-width: 767px) {
.verifyEmail-strip, .verifyEmail-strip span {
  display: block !important;
}
}



.headerStrip .sidebar, .headerStrip .navigation {
  height: calc(100vh - 56px);
}
.headerStrip .main {
  height: calc(100vh - 56px);
}
.headerStrip #ongoing_collapse {
  height: calc(100vh - 210px) !important;
}
.headerStrip .topbar {
  position: sticky;
  top: 0;
}

.headerStrip .sidebarnavChild .tab-content {
  height: calc(100vh - 100px) !important;
  position: relative;
  top: 58px;
}
.headerStrip.layout-not-liveChat .navigation {
  top: 56px;
}


.visitor-info-repaet{
  color: #2c3049;
  width: 48.6%;
  flex: 0 0 auto;
  padding: 30px;
  background: #cddefd;
  border: 1px solid #1c223a;
  box-shadow: none;
}
.gap-3 {
  gap: 2rem;
}
.visitor-info-repaet ul li{
  width: 100%;
  word-break: break-all;
}
.fs-3{
  font-size: 3rem;
}
.view_icon {
  width: 25px;
  height: 25px;
  display: inline-flex;
  text-align: center;
  transition: all .3s ease;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: transparent;
}

.view_icon:hover {
  background-color: aliceblue;
  color: #017bff;
}

.view_icon > svg {
  margin: 0 !important;
}