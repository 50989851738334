

  @media (max-width: 1441px) {
    .chatReport-total-chats.satisfactionArea .online-time .numberReport .big {
      font-size: 30px;
    }
    .chatReportCol.cstm-box-time .satisfactionArea .wait-time-others::before {
      left: -10px;
    }
    .chatReportCol.cstm-box-time .satisfactionArea .online-time {
      padding-right: 10px;
    }

    .satisfactionArea .wait-time-others p .info-icon {
      width: 30px;
      padding-right: 10px;
    }
    .visitor-info ul li {
      width: 100%;
    }
    .chatReportCol.cstm-box-time .average-rating .wait-time-others.line-diff::before{
      left: -10px;
    }
    .chatReportCol.cstm-box-time .average-rating .online-time p .left {
      margin-right: inherit;
      padding-right: 30px;
    }

  }



/* 
  ##Device = Desktops  
  ##Screen = 1281px to higher resolution desktops
*/
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1280px) and (max-width: 1440px) {
    
    .code-widget-copy textarea {
      font-size: 14px;
      height: 120px;
    }


    .media-body.chat-header{   
      max-width: 165px;
    }

  }
  

  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1025px) and (max-width: 1280px) {
    .code-widget-copy textarea {
      font-size: 14px;
      height: 120px;
    }

    .media-body.chat-header{   
      max-width: 150px;
    }  


  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1199px) {


    h3.card-title {font-size: 18px !important;}
    h5.card-title { font-size: 14px !important;}
    .mobile-hide{display: none !important;}
    .mobile-display{display: block;}




    /*----start right panel---*/
    .page-wrapper{    margin-left: 0;}
    // .rightPanelSection{width: 100%;}
    .chat-status .chat-status-rating-col { width: calc(50% - 10px);}
    .pagingnation { width: 100%;}

    .page-wrapper{    height: calc(100vh - 50px); overflow: auto;}
    .responsive-btn{
      display: inline-block;
      height: 40px;
      width: 40px;
      background: #fff;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
      cursor: pointer;
      font-size: 14px;border: 0px;}
    /*----end right panel-----*/

    .chat-body .chat-content-New.scroll-4 {
      overflow-x: hidden;
    }

    .mobile-leftRightPanelDisplay{
      display: block;
    }


    /*------start left menu-------*/
    .mobile-menu{ display: block;}
    .navigation{    min-height: 50px;}
    .navigation-not-liveChat, .navigation-visible-overflow{bottom: 0;width: 100%;top: auto; height: auto; padding: 0 !important;    position: fixed; background: #1C223A;z-index: 9999; overflow: visible;    border-top: 0px solid rgba(245, 246, 250, 0.85);}
    .navigation-not-liveChat > a, .navigation-visible-overflow > a{display: none;}
    .navigation-not-liveChat ul.navbar-nav > li, .navigation-visible-overflow ul.navbar-nav > li{display: inline-block;}
    // .navigation-not-liveChat ul.navbar-nav > li:nth-of-type(1n+6), .navigation-visible-overflow ul.navbar-nav > li:nth-of-type(1n+6){display: none}
    // .navigation-not-liveChat ul.navbar-nav > li:last-child, .navigation-visible-overflow ul.navbar-nav > li:last-child{display: inline-block;float: right;}
    // .navigation-not-liveChat.mobileAddCNew  ul.navbar-nav > li, .navigation-visible-overflow.mobileAddCNew ul.navbar-nav > li{display: inline-block !important}
    .navbar-light .navbar-nav a.nav-link{display: inline-block; padding: 6px 20px !important;}
    .navigation-not-liveChat .navbar-nav, .navigation-visible-overflow .navbar-nav{display: block; text-align: left; padding: 0 !important;}
    .sidebarnavChild{z-index: 99;height: auto;right: 0;left: auto; display: none;}
    .activeNavigation > span::before, .activeNavigation > span::after{display: none;}
    .left-menu-title-logo{display: none;}
    /*------end left menu-------*/

     /*------start topbar menu-------*/
     .topbar {width: 100%; margin-left: 0;}
     .sidebar-collapse {margin-left: 0;width: 100%;}
     /*------end topbar menu-------*/
     .custom-active-chat-user .badge-top-right{top: -8px;}
    /*-------start Login/signup page design------*/
    .login-header img.loginHeaderLogo {width: 155px;}
    .loginBody { width: 100%;  margin: auto;position: relative;left: 0;top: 0;transform: inherit;}
    .signup-description h5 {font-size: 22px !important; line-height: 1.2;}
    .forgotBody{width: 100%;    }
    .login-body .login-form { width: 100% !important; padding: 10px 10px 10px 10px !important;}
    .loginFormOrlienparent{display: inline-block; width: 100%;}
    .login-body .login-social { width: 100%;float: none;padding: 10px;  }
    .socialBtnCenter {  height: auto; }
    .socialMediaBtnLink {margin-left: auto;margin-top: 20px;}
    .orLine::after{ content: ''; }
    .loginFooterReview {  position: relative; }
    .reviewcompaniesimg {  width: 50px;}
    .loginImgFilter .reviewcompaniesimg img { width: 100%; }
    .registrationImgFilter .reviewcompaniesimg img{ width: 100%; }
    /*-------start Login/signup page design------*/

    /*-------start enter your company info design------*/
    .company-information1 .login-description h5 { font-size: 22px !important;}
    .company-information1 .login-description{
        padding-bottom: 0px !important;
        padding-top: 50px;
    }
    .company-information { position: relative; left: 0; top: 0; transform: inherit;}
    .company-information1 { width: 100%;}
    .catfeacture-section-2 {width: 100%;  }
    .catfeacture-section-2 ul {padding-left: 0 !important; padding-top: 0 !important;}
    .catfeacture-section-2 ul li {display: inline-block; float: none !important; padding: 4px 5px !important;}
    .catfeacture-section-2 ul li img {height: 20px !important;}
    .footerStepbyStep > .container{padding: 0;}
    /*-------end enter your company info design------*/

    /*-------start Customize your widget design------*/
    .custmize-your-widget-heading{width: 100%;}
    .custmize-your-widget h1 {font-size: 22px;}
    .custmize-your-widget p.text-mutede{font-size: 14px;}
    .clicntLogo-heading{font-size: 15px;color: #9c9c9c;}
    /*-------end Customize your widget design------*/

    /*-------start Congratulations! design------*/
    .verificationCodePara22 h4 { font-size: 18px;}
    .verificationCodePara p { font-size: 12px;}
    .verifictionCodeFeilds { width: 100%;}
    .verifictionDownEnter p {font-size: 11px;}
    .verifictionDownEnter h5 {font-size: 12px;}
    .feildsInputs input[type="text"]{height: 50px;font-size: 1.5rem;}
    /*-------end Congratulations! design------*/

    /*-------start Create agent profile design------*/
    .custmize-your-widget h1 { font-size: 22px !important;}
    .custmize-your-widget p.card-body-text { font-size: 12px;}
    /*-------end Create agent profile design------*/

    /*-------start Install your livechat's widget design------*/
    .verificationCodePara h4 { font-size: 18px;}
    .custimize-wraper {width: 100% !important;}
    .code-widget-copy textarea {padding: 10px;font-size: 12px;height: 100px;}
    /*-------end Install your livechat's widget design------*/

    /*---------start settings design----------*/
    .chatform-Preview .container-fluid{padding: 0px;}
    .idle-section .settings-form-feilds{margin-left: 10px !important; margin-right: 10px !important;margin-bottom: 10px; display: inline-block;}
    .settings-form-feilds .settings-feld .main-checkbox-all {   top: -10px;  position: relative; width: 30px; float: left;}
    .setting-section h5.card-title {  width: calc(100% - 30px); float: left;}
    .chatform-Preview .setting-section.idle-section .settings-form-feilds .settings-feld.form-feilds .wrapperInfo.mx-4{ margin: 10px 0px !important;display: inline-block; width: 100%;}
    .chatform-Preview .setting-section.idle-section .settings-form-feilds .settings-feld.form-feilds .wrapperInfo textarea{width: 100% !important;}
    .setting-footer-fixed, .setting-footer-fixed-RightPreview { width: 100% !important;left: 0;}
    .btnBlueSettings {font-size: 12px;padding: 5px 15px;}
    .btnWhiteSettings { font-size: 12px; padding: 5px 15px;}
    .btnRestore {font-size: 12px;padding: 5px 15px;}
    /*
    End Timeout settings
    */
    .setup-heading{display: block !important;}
    .setup-list-design.pt-5 ul li .align-items-center{display: block;}
    .setup-list-design.pt-5 ul li .align-items-center div.ml-auto{text-align: right;}
    // .scriptCodeSetup{    height: 80px !important; width: 100%!important; word-break: break-all!important;}
    .website-verifylink-feilds {width: 100%;}
    .widgetsucess {
      padding: 6px 10px;
      font-size: 12px;
      display: inline-block;
      margin-top: 5px;
  }
  .end-chat-shadow {
    top: 115px;
    width: calc(100% - 0px);
    height: calc(100% - 180px);
    margin: 0;
    padding: 0;
    max-width: 100%;
    min-width: 100%;
}


#endchatwindow.chat-sidebar{
  max-width: 100%;
min-width: 100%;
width: 100%;
}


#stickynotes.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
} 

#visitorpaths.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#add_stickynotes.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#transferagent.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
} 

#chat1-members.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#addagentdiv.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#leavechatwindow.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
} 

#filterByOptions.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
} 

#filterByList.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
} 

#orderByOptionsList.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#chatHistorySee.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#visitarPathNews.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

      /*
    End Setup
    */
    .mixListingPreview {right: 6px;}
    /*
     End  Chat forms
     */

    /*
    End Widget Settings
    */
    /*---------end settings design----------*/
    .catfeacture-section-2 {
        width: 100% !important;
        bottom: 0px;
    }

    .company-information {
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      transform: inherit !important;
      -webkit-transform: inherit !important;
      -moz-transform: inherit !important;
      -ms-transform: inherit !important;
      -o-transform: inherit !important;
    }

    .company-information1 {
      width: 100% !important;
    }

    .veryfy-your-mail-form {
      width: 100%;
      margin: 50px auto;
    }

    .forgotBody .login-description h5{font-size: 27px;}
    .forgotBody .login-description p, .veryfy-your-mail-description p{
      font-size: 14px;
    }
    /*---------end verification design----------*/
    .agent-table .avatar-sm, .agent-table .avatar-sm > span > img {
        height: 2rem;
        min-height: 2rem;
        width: 2rem;
        min-width: 2rem;
    }

    //  /*---------start table design----------*/

     .liveCgatUserTable > thead{display: none;}
     .liveCgatUserTable > tbody > tr > td{display: none;}
     .liveCgatUserTable > tbody > tr > td:first-child{display: table-cell;}
     .liveCgatUserTable > tbody > tr > td:last-child{display: table-cell;}
     .liveCgatUserTable .media-body.align-self-center.text-truncate{text-align: left;}
     .liveCgatUserTable .outline-1-black{    outline: 0px solid transparent;}
     .liveCgatUserTable > tbody > tr{ border-bottom: 1px solid rgba(51, 51, 51, 0.12);}
     .user-table-main-header .aside-agent {display: block;margin-bottom: 10px !important; margin-right: auto !important;}
     #resposnive-table-class .user-table-main-header{display: none;}
     .responsiveTbaleHeaderFilter .user-table-main-header{display: block !important;}
     .search-feilds-history .historey-serch-drp {width: 130px;}
     .sideMenue{display: block !important}
     #ongoing_collapse {  max-height: calc(100vh - 250px);}
     #incoming_collapse { max-height: calc(100vh - 270px);}
     .profile-nav {  left: auto; right: 80px;}
     .profile-nav:before {left: auto; right: -20px;border-color: transparent transparent transparent white;}
     .media-body{text-align: left;}
     /*---------end table design----------*/


    .forgotBody .login-description h5{font-size: 22px;}
    .forgotBody .login-description p, .veryfy-your-mail-description p{
      font-size: 14px;
    }

    .veryfy-your-mail-form .k-flex.checkBoxM{display: block}
    .checkBoxM .settings-feld{margin-bottom: 10px;}
    /*---------end verification design----------*/


     /*---------start pagingnation design----------*/
     .pagingnation-list li:first-child {
          width: 55px;
      }
      .pagingnation-list li:last-child {
          width: 55px;
      }

      .pagingnation-list li {
          width: 33px;
          height: 33px;
          line-height: 34px;
      }
      
      /*---------end pagingnation design----------*/


      /*---------start congratulation design----------*/
      .congratulationPopup2{width: 100%;    margin: 20px auto;     padding: 20px;}
      .congratulationPopup2 .popupBody .feilds-instruction h1 {
        font-size: 24px;}
        .congratulationPopup .congratilation-circle {
          width: 200px;
          height: 200px;
      }
      .congratulationPopup .congratilation-circle svg {
        width: 60px;
        height: 60px;
    }
      /*---------end congratulation design----------*/


      .popupLivechat{
        width: 90%;
        z-index: 99999;
      }

      /*---department start ---------*/

      .usr-mm-btn .btnBlueSettings.add-agent-clik span{display: none;}
      .usr-mm-btn .btnBlueSettings.add-agent-clik{
          position: fixed;
          bottom: 70px;
          right: 10px;
          border-radius: 100%;
          width: 40px;
          height: 40px;
          font-size: 20px;
          padding: 0px 0px;
      }
      .departmentUsertable > thead{display: none;} 

      .departmentUsertable > tbody > tr > td {
          display: none;
      }
      .departmentUsertable > tbody > tr > td:first-child {
          display: table-cell;
      }
      .departmentUsertable > tbody > tr > td:last-child {
          display: table-cell;
      }

      .departmentUsertable > tbody > tr > td:nth-child(3) {
        display: table-cell;
    }

      .departmentUsertable .outline-1-black {
          outline: 0px solid transparent;
      }

      .departmentUsertable > tbody > tr {
          border-bottom: 1px solid rgba(51, 51, 51, 0.12);
      }

    /*---department end ---------*/

    .layout .liveChatHeadrMobil {
      display: flex;
  }

  .sidebar.IOLP {
      height: calc(100% - 50px);
      top: 50px;
  }

  .not-filter {
      height: calc(100vh - 203px) !important;
  }


  .VRP #filterByOptions,.VRP #filterByList{
    z-index: 9;
  }


  .selectOptionDetactLanguage {
    margin-right: 5px;
    width: 80px;
}

.languageFilter p.detactLanguage {
    font-size: 10px;
}

.translateChat {
  margin: 10px;
  padding: 10px 10px;
  float: left;
  width: calc(100% - 20px);
}




  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  @media (min-width: 768px) and (max-width: 1199px) and (orientation: landscape) {


 
    h3.card-title {font-size: 18px !important;}
    h5.card-title { font-size: 14px !important;}
    .mobile-hide{display: none !important;}
    .mobile-display{display: block;}




    /*----start right panel---*/
    .page-wrapper{    margin-left: 0;}
    // .rightPanelSection{width: 100%;}
    .chat-status .chat-status-rating-col { width: calc(50% - 10px);}
    .pagingnation { width: 100%;}
    .page-wrapper{    height: calc(100vh - 50px); overflow: auto;}
    .responsive-btn{
      display: inline-block;
      height: 40px;
      width: 40px;
      background: #fff;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
      cursor: pointer;
      font-size: 14px;border: 0px;}
    /*----end right panel-----*/

    .chat-body .chat-content-New.scroll-4 {
      overflow-x: hidden;
    }

    .mobile-leftRightPanelDisplay{
      display: block;
    }


    /*------start left menu-------*/
    .mobile-menu{ display: block;}
    .navigation{    min-height: 50px;}
    .navigation-not-liveChat, .navigation-visible-overflow{bottom: 0;width: 100%;top: auto; height: auto; padding: 0 !important;    position: fixed; background: #1C223A;z-index: 9999; overflow: visible;    border-top: 0px solid rgba(245, 246, 250, 0.85);}
    .navigation-not-liveChat > a, .navigation-visible-overflow > a{display: none;}
    .navigation-not-liveChat ul.navbar-nav > li, .navigation-visible-overflow ul.navbar-nav > li{display: inline-block;}
    // .navigation-not-liveChat ul.navbar-nav > li:nth-of-type(1n+6), .navigation-visible-overflow ul.navbar-nav > li:nth-of-type(1n+6){display: none}
    // .navigation-not-liveChat ul.navbar-nav > li:last-child, .navigation-visible-overflow ul.navbar-nav > li:last-child{display: inline-block;float: right;}
    // .navigation-not-liveChat.mobileAddCNew  ul.navbar-nav > li, .navigation-visible-overflow.mobileAddCNew ul.navbar-nav > li{display: inline-block !important}
    .navbar-light .navbar-nav a.nav-link{display: inline-block; padding: 6px 20px !important;}
    .navigation-not-liveChat .navbar-nav, .navigation-visible-overflow .navbar-nav{display: block; text-align: left; padding: 0 !important;}
    .sidebarnavChild{z-index: 99;height: auto;right: 0;left: auto; display: none;}
    .activeNavigation > span::before, .activeNavigation > span::after{display: none;}
    .left-menu-title-logo{display: none;}
    /*------end left menu-------*/

     /*------start topbar menu-------*/
     .topbar {width: 100%; margin-left: 0;}
     .sidebar-collapse {margin-left: 0;width: 100%;}
     /*------end topbar menu-------*/
     .custom-active-chat-user .badge-top-right{top: -8px;}
    /*-------start Login/signup page design------*/
    .login-header img.loginHeaderLogo {width: 125px;}
    .logo-section > img{width: 155px;}
    .loginBody { width: 100%;  margin: auto;position: relative;left: 0;top: 0;transform: inherit;}
    .signup-description h5 {font-size: 22px !important; line-height: 1.2;}
    .forgotBody{width: 100%;    }
    .login-body .login-form { width: 100% !important; padding: 10px 10px 10px 10px !important;}
    .loginFormOrlienparent{display: inline-block; width: 100%;}
    .login-body .login-social { width: 100%;float: none;padding: 10px;  }
    .socialBtnCenter {  height: auto; }
    .socialMediaBtnLink {margin-left: auto;margin-top: 20px;}
    .orLine::after{ content: ''; }
    .loginFooterReview {  position: relative; }
    .reviewcompaniesimg {  width: 50px;}
    .loginImgFilter .reviewcompaniesimg img { width: 100%; }
    .registrationImgFilter .reviewcompaniesimg img{ width: 100%; }
    /*-------start Login/signup page design------*/

    /*-------start enter your company info design------*/
    .company-information1 .login-description h5 { font-size: 22px !important;}
    .company-information1 .login-description{
        padding-bottom: 0px !important;
        padding-top: 50px;
    }
    .company-information { position: relative; left: 0; top: 0; transform: inherit;}
    .company-information1 { width: 100%;}
    .catfeacture-section-2 {width: 100%;  }
    .catfeacture-section-2 ul {padding-left: 0 !important; padding-top: 0 !important;}
    .catfeacture-section-2 ul li {display: inline-block; float: none !important; padding: 4px 5px !important;}
    .catfeacture-section-2 ul li img {height: 20px !important;}
    .footerStepbyStep > .container{padding: 0;}
    /*-------end enter your company info design------*/

    /*-------start Customize your widget design------*/
    .custmize-your-widget-heading{width: 100%;}
    .custmize-your-widget h1 {font-size: 22px;}
    .custmize-your-widget p.text-mutede{font-size: 14px;}
    .bodress-widgets{display: none;}
    .clicntLogo-heading{font-size: 15px;color: #9c9c9c;}
    /*-------end Customize your widget design------*/

    /*-------start Congratulations! design------*/
    .verificationCodePara22 h4 { font-size: 18px;}
    .verificationCodePara p { font-size: 12px;}
    .verifictionCodeFeilds { width: 100%;}
    .verifictionDownEnter p {font-size: 11px;}
    .verifictionDownEnter h5 {font-size: 12px;}
    .feildsInputs input[type="text"]{height: 50px;font-size: 1.5rem;}
    /*-------end Congratulations! design------*/

    /*-------start Create agent profile design------*/
    .custmize-your-widget h1 { font-size: 22px !important;}
    .custmize-your-widget p.card-body-text { font-size: 12px;}
    /*-------end Create agent profile design------*/

    /*-------start Install your livechat's widget design------*/
    .verificationCodePara h4 { font-size: 18px;}
    .custimize-wraper {width: 100% !important;}
    .code-widget-copy textarea {padding: 10px;font-size: 12px;height: 100px;}
    /*-------end Install your livechat's widget design------*/

    /*---------start settings design----------*/
    .chatform-Preview .container-fluid{padding: 0px;}
    .idle-section .settings-form-feilds{margin-left: 10px !important; margin-right: 10px !important;margin-bottom: 10px; display: inline-block;}
    .settings-form-feilds .settings-feld .main-checkbox-all {   top: -10px;  position: relative; width: 30px; float: left;}
    .setting-section h5.card-title {  width: calc(100% - 30px); float: left;}
    .chatform-Preview .setting-section.idle-section .settings-form-feilds .settings-feld.form-feilds .wrapperInfo.mx-4{ margin: 10px 0px !important;display: inline-block; width: 100%;}
    .chatform-Preview .setting-section.idle-section .settings-form-feilds .settings-feld.form-feilds .wrapperInfo textarea{width: 100% !important;}
    .setting-footer-fixed, .setting-footer-fixed-RightPreview { width: 100% !important;left: 0;}
    .btnBlueSettings {font-size: 12px;padding: 5px 15px;}
    .btnWhiteSettings { font-size: 12px; padding: 5px 15px;}
    .btnRestore {font-size: 12px;padding: 5px 15px;}
    /*
    End Timeout settings
    */
    .setup-heading{display: block !important;}
    .setup-list-design.pt-5 ul li .align-items-center{display: block;}
    .setup-list-design.pt-5 ul li .align-items-center div.ml-auto{text-align: right;}
    // .scriptCodeSetup{    height: 80px !important; width: 100%!important; word-break: break-all!important;}
    .website-verifylink-feilds {width: 100%;}
    .widgetsucess {
      padding: 6px 10px;
      font-size: 12px;
      display: inline-block;
      margin-top: 5px;
  }
  .end-chat-shadow {
    top: 135px;
    width: calc(100% - 0px);
    height: calc(100% - 211px);
    margin: 0;
    padding: 0;
    max-width: 100%;
    min-width: 100%;
}


#endchatwindow.chat-sidebar{
  max-width: 100%;
min-width: 100%;
width: 100%;
}


#stickynotes.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
} 

#visitorpaths.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#add_stickynotes.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#transferagent.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
} 

#chat1-members.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#addagentdiv.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#leavechatwindow.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
} 

#filterByOptions.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
} 

#filterByList.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
} 

#orderByOptionsList.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#chatHistorySee.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

#visitarPathNews.chat-sidebar{
max-width: 100%;
min-width: 100%;
width: 100%;
}

      /*
    End Setup
    */
    .mixListingPreview {right: 6px;}
    /*
     End  Chat forms
     */

    /*
    End Widget Settings
    */
    /*---------end settings design----------*/
    .catfeacture-section-2 {
        width: 100% !important;
        bottom: 0px;
    }

    .company-information {
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      transform: inherit !important;
      -webkit-transform: inherit !important;
      -moz-transform: inherit !important;
      -ms-transform: inherit !important;
      -o-transform: inherit !important;
    }

    .company-information1 {
      width: 100% !important;
    }

    .veryfy-your-mail-form {
      width: 100%;
      margin: 50px auto;
    }

    .forgotBody .login-description h5{font-size: 27px;}
    .forgotBody .login-description p, .veryfy-your-mail-description p{
      font-size: 14px;
    }
    /*---------end verification design----------*/
    .agent-table .avatar-sm, .agent-table .avatar-sm > span > img {
        height: 2rem;
        min-height: 2rem;
        width: 2rem;
        min-width: 2rem;
    }

    //  /*---------start table design----------*/

     .liveCgatUserTable > thead{display: none;}
     .liveCgatUserTable > tbody > tr > td{display: none;}
     .liveCgatUserTable > tbody > tr > td:first-child{display: table-cell;}
     .liveCgatUserTable > tbody > tr > td:last-child{display: table-cell;}
     .liveCgatUserTable .media-body.align-self-center.text-truncate{text-align: left;}
     .liveCgatUserTable .outline-1-black{    outline: 0px solid transparent;}
     .liveCgatUserTable > tbody > tr{ border-bottom: 1px solid rgba(51, 51, 51, 0.12);}
     .user-table-main-header .aside-agent {display: block;margin-bottom: 10px !important; margin-right: auto !important;}
     #resposnive-table-class .user-table-main-header{display: none;}
     .responsiveTbaleHeaderFilter .user-table-main-header{display: block !important;}
     .search-feilds-history .historey-serch-drp {width: 130px;}
     .sideMenue{display: block !important}
     #ongoing_collapse {  max-height: calc(100vh - 250px);}
     #incoming_collapse { max-height: calc(100vh - 270px);}
     .profile-nav {  left: auto; right: 80px;}
     .profile-nav:before {left: auto; right: -20px;border-color: transparent transparent transparent white;}
     .media-body{text-align: left;}
    //  /*---------end table design----------*/


    .forgotBody .login-description h5{font-size: 22px;}
    .forgotBody .login-description p, .veryfy-your-mail-description p{
      font-size: 14px;
    }

    .veryfy-your-mail-form .k-flex.checkBoxM{display: block}
    .checkBoxM .settings-feld{margin-bottom: 10px;}
    /*---------end verification design----------*/


     /*---------start pagingnation design----------*/
     .pagingnation-list li:first-child {
          width: 55px;
      }
      .pagingnation-list li:last-child {
          width: 55px;
      }

      .pagingnation-list li {
          width: 33px;
          height: 33px;
          line-height: 34px;
      }
      
      /*---------end pagingnation design----------*/


      /*---------start congratulation design----------*/
      .congratulationPopup2{width: 100%;    margin: 20px auto;     padding: 20px;}
      .congratulationPopup2 .popupBody .feilds-instruction h1 {
        font-size: 24px;}
        .congratulationPopup .congratilation-circle {
          width: 200px;
          height: 200px;
      }
      .congratulationPopup .congratilation-circle svg {
        width: 60px;
        height: 60px;
    }
      /*---------end congratulation design----------*/


      .popupLivechat{
        width: 90%;
        z-index: 99999;
      }



      /*---department start ---------*/

      .usr-mm-btn .btnBlueSettings.add-agent-clik span{display: none;}
      .usr-mm-btn .btnBlueSettings.add-agent-clik{
          position: fixed;
          bottom: 70px;
          right: 10px;
          border-radius: 100%;
          width: 40px;
          height: 40px;
          font-size: 20px;
          padding: 0px 0px;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
}
      .departmentUsertable > thead{display: none;} 

      .departmentUsertable > tbody > tr > td {
          display: none;
      }
      .departmentUsertable > tbody > tr > td:first-child {
          display: table-cell;
      }
      .departmentUsertable > tbody > tr > td:last-child {
          display: table-cell;
      }

      .departmentUsertable > tbody > tr > td:nth-child(3) {
        display: table-cell;
    }

      .departmentUsertable .outline-1-black {
          outline: 0px solid transparent;
      }

      .departmentUsertable > tbody > tr {
          border-bottom: 1px solid rgba(51, 51, 51, 0.12);
      }

    /*---department end ---------*/


    

  .sidebar.IOLP {
      height: calc(100% - 50px);
      top: 50px;
  }

  .not-filter {
      height: calc(100vh - 203px) !important;
  }



  .VRP #filterByOptions,.VRP #filterByList{
    z-index: 9;
  }

  .profile-rightpanel, .rightPanelSection {
    z-index: 999999;
  }



  .selectOptionDetactLanguage {
    margin-right: 5px;
    width: 80px;
}

.languageFilter p.detactLanguage {
    font-size: 10px;
}

.translateChat {
  margin: 10px;
  padding: 10px 10px;
  float: left;
  width: calc(100% - 20px);
}

    
    
  }





  
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  @media (min-width: 481px) and (max-width: 768px) {
    


    h3.card-title {font-size: 18px !important;}
    h5.card-title { font-size: 14px !important;}
    .mobile-hide{display: none !important;}
    .mobile-display{display: block;}

     /*----start right panel---*/
     .page-wrapper{    margin-left: 0;}
     .rightPanelSection{width: 430px;}
     .chat-status .chat-status-rating-col { width: calc(50% - 10px);}
     .pagingnation { width: 100%;}
     .page-wrapper{    height: calc(100vh - 50px); overflow: auto}
     .responsive-btn{
       display: inline-block;
       height: 40px;
       width: 40px;
       background: #fff;
       line-height: 40px;
       text-align: center;
       border-radius: 5px;
       box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
       cursor: pointer;
       font-size: 14px;border: 0px;}
     /*----end right panel-----*/
 
     .chat-body .chat-content-New.scroll-4 {
       overflow-x: hidden;
     }

     .chat-body .chat-content-New #messagesdata{
        margin-left: 20px;
      }

      .chat-footer .form-row > .col{
        padding-left: 0px;
        padding-right: 0px;
      }


      .chat-footer .form-row .col-auto{
        padding-left: 0px;
      }
 
     .mobile-leftRightPanelDisplay{
       display: block;
     }
   
 
     /*------start left menu-------*/
     .mobile-menu{ display: block;}
     .navigation{    min-height: 50px;}
     .navigation-not-liveChat, .navigation-visible-overflow{bottom: 0;width: 100%;top: auto; height: auto; padding: 0 !important;    position: fixed; background: #1C223A;z-index: 9999; overflow: visible;    border-top: 0px solid rgba(245, 246, 250, 0.85);}
     .navigation-not-liveChat > a, .navigation-visible-overflow > a{display: none;}
     .navigation-not-liveChat ul.navbar-nav > li, .navigation-visible-overflow ul.navbar-nav > li{display: inline-block;}
    //  .navigation-not-liveChat ul.navbar-nav > li:nth-of-type(1n+6), .navigation-visible-overflow ul.navbar-nav > li:nth-of-type(1n+6){display: none}
    // .navigation-not-liveChat ul.navbar-nav > li:last-child, .navigation-visible-overflow ul.navbar-nav > li:last-child{display: inline-block;float: right;}
    // .navigation-not-liveChat.mobileAddCNew  ul.navbar-nav > li, .navigation-visible-overflow.mobileAddCNew ul.navbar-nav > li{display: inline-block !important}
     .navbar-light .navbar-nav a.nav-link{display: inline-block; padding: 6px 12px !important;}
     .navigation-not-liveChat .navbar-nav, .navigation-visible-overflow .navbar-nav{display: block; text-align: left; padding: 0 !important;}
     .sidebarnavChild{z-index: 99;height: auto;right: 0;left: auto; display: none;}
     .activeNavigation > span::before, .activeNavigation > span::after{display: none;}
     .left-menu-title-logo{display: none;}
     /*------end left menu-------*/
 
      /*------start topbar menu-------*/
      .topbar {width: 100%; margin-left: 0;}
      .sidebar-collapse {margin-left: 0;width: 100%;}
      /*------end topbar menu-------*/
       
     /*-------start Login/signup page design------*/
     .login-header img.loginHeaderLogo {width: 155px;}
     .logo-section > img{width: 155px;}
     .loginBody { width: 100%;  margin: auto;position: relative;left: 0;top: 0;transform: inherit;}
     .signup-description h5 {font-size: 22px !important; line-height: 1.2;}
     .forgotBody{width: 100%;    }
     .login-body .login-form { width: 100% !important; padding: 10px 10px 10px 10px !important;}
     .loginFormOrlienparent{display: inline-block; width: 100%;}
     .login-body .login-social { width: 100%;float: none;padding: 10px;  }
     .socialBtnCenter {  height: auto; }
     .socialMediaBtnLink {margin-left: auto;margin-top: 20px;}
     .orLine::after{ content: ''; }
     .loginFooterReview {  position: relative; }
     .reviewcompaniesimg {  width: 50px;}
     .loginImgFilter .reviewcompaniesimg img { width: 100%; }
     .registrationImgFilter .reviewcompaniesimg img{ width: 100%; }
     .loginBG{overflow-y: auto;}
     /*-------start Login/signup page design------*/
 
     /*-------start enter your company info design------*/
     .company-information1 .login-description h5 { font-size: 22px !important;}
     .company-information1 .login-description{
         padding-bottom: 0px !important;
         padding-top: 50px;
     }
     .company-information { position: relative; left: 0; top: 0; transform: inherit;}
     .company-information1 { width: 100%;}
     .catfeacture-section-2 {width: 100%;  }
     .catfeacture-section-2 ul {padding-left: 0 !important; padding-top: 0 !important;}
     .catfeacture-section-2 ul li {display: inline-block; float: none !important; padding: 4px 5px !important;}
     .catfeacture-section-2 ul li img {height: 20px !important;}
     .footerStepbyStep > .container{padding: 0;}
     /*-------end enter your company info design------*/
 
     /*-------start Customize your widget design------*/
     .custmize-your-widget-heading{width: 100%;}
     .custmize-your-widget h1 {font-size: 22px;}
     .custmize-your-widget p.text-mutede{font-size: 14px;}
     .clicntLogo-heading{font-size: 15px;color: #9c9c9c;}
     /*-------end Customize your widget design------*/
 
     /*-------start Congratulations! design------*/
     .verificationCodePara22 h4 { font-size: 18px;}
     .verificationCodePara p { font-size: 12px;}
     .verifictionCodeFeilds { width: 100%;}
     .verifictionDownEnter p {font-size: 11px;}
     .verifictionDownEnter h5 {font-size: 12px;}
     .feildsInputs input[type="text"]{height: 50px;font-size: 1.5rem;}
     /*-------end Congratulations! design------*/
 
     /*-------start Create agent profile design------*/
     .custmize-your-widget h1 { font-size: 22px !important;}
     .custmize-your-widget p.card-body-text { font-size: 12px;}
     /*-------end Create agent profile design------*/
 
     /*-------start Install your livechat's widget design------*/
     .verificationCodePara h4 { font-size: 18px;}
     .custimize-wraper {width: 100% !important;}
     .code-widget-copy textarea {padding: 10px;font-size: 12px;height: 100px;}
     /*-------end Install your livechat's widget design------*/
 
     /*---------start settings design----------*/
     .chatform-Preview .container-fluid{padding: 0px;}
     .idle-section .settings-form-feilds{width: 95%;margin-left: 10px !important; margin-right: 10px !important;margin-bottom: 10px; display: inline-block;}
     .setting-section h5.card-title {  width: calc(100% - 30px); float: left;}
     .chatform-Preview .setting-section.idle-section .settings-form-feilds .settings-feld.form-feilds .wrapperInfo.mx-4{ margin: 10px 0px !important;display: inline-block; width: 100%;}
     .chatform-Preview .setting-section.idle-section .settings-form-feilds .settings-feld.form-feilds .wrapperInfo textarea{width: 100% !important;}
     .setting-footer-fixed, .setting-footer-fixed-RightPreview { width: 100% !important;left: 0;}
     .btnBlueSettings {font-size: 12px;padding: 5px 15px;}
     .btnWhiteSettings { font-size: 12px; padding: 5px 15px;}
     .btnRestore {font-size: 12px;padding: 5px 15px;}
     /*
     End Timeout settings
     */
     .setup-heading{display: block !important;}
     .setup-list-design.pt-5 ul li .align-items-center{display: block;}
     .setup-list-design.pt-5 ul li .align-items-center div.ml-auto{text-align: right;}
    //  .scriptCodeSetup{    height: 80px !important; width: 100%!important; word-break: break-all!important;}
     .website-verifylink-feilds {width: 100%;}
     .widgetsucess {
        padding: 6px 10px;
        font-size: 12px;
        display: inline-block;
        margin-top: 5px;
    }

    .end-chat-shadow {
      top: 115px;
      width: calc(100% - 0px);
      height: calc(100% - 180px);
      margin: 0;
      padding: 0;
      max-width: 100%;
      min-width: 100%;
  }


  #endchatwindow.chat-sidebar{
    max-width: 100%;
  min-width: 100%;
  width: 100%;
  }


#stickynotes.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
} 

#visitorpaths.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#add_stickynotes.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#transferagent.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
} 

#chat1-members.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#addagentdiv.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#leavechatwindow.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
} 

#filterByOptions.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
} 

#filterByList.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
} 

#orderByOptionsList.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#chatHistorySee.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#visitarPathNews.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

       /*
     End Setup
     */
    //  .widget-seetings-right{width: 423px}
     .mixListingPreview {right: 6px;}
     /*
      End  Chat forms
      */
 
     /*
     End Widget Settings
     */
     /*---------end settings design----------*/
     .catfeacture-section-2 {
         width: 100% !important;
         bottom: 0px;
     }
 
     .company-information {
       position: relative !important;
       left: 0 !important;
       top: 0 !important;
       transform: inherit !important;
       -webkit-transform: inherit !important;
       -moz-transform: inherit !important;
       -ms-transform: inherit !important;
       -o-transform: inherit !important;
     }
 
     .company-information1 {
       width: 100% !important;
     }
 
     .veryfy-your-mail-form {
       width: 100%;
       margin: 50px auto;
     }
 
     .forgotBody .login-description h5{font-size: 27px;}
     .forgotBody .login-description p, .veryfy-your-mail-description p{
       font-size: 14px;
     }
     /*---------end verification design----------*/
 
 
      // /*---------start table design----------*/
      .liveCgatUserTable > thead{display: none;}
      .liveCgatUserTable > tbody > tr > td{display: none;}
      .liveCgatUserTable > tbody > tr > td:first-child{display: contents;}
      .liveCgatUserTable > tbody > tr > td:last-child{display: table-cell;}
      .liveCgatUserTable .media-body.align-self-center.text-truncate{text-align: left;}
      .liveCgatUserTable .outline-1-black{    outline: 0px solid transparent;}
      .liveCgatUserTable > tbody > tr{ border-bottom: 1px solid rgba(51, 51, 51, 0.12);}
      .user-table-main-header .aside-agent {display: block;margin-bottom: 10px !important; margin-right: auto !important;}
      #resposnive-table-class .user-table-main-header{display: none;}
      .responsiveTbaleHeaderFilter .user-table-main-header{display: block !important;}
      .search-feilds-history .historey-serch-drp {width: 130px;}
      .sideMenue{display: block !important}
      #ongoing_collapse {  max-height: calc(100vh - 250px);}
      #incoming_collapse { max-height: calc(100vh - 270px);}
      .profile-nav {  left: auto; right: 80px;}
      .profile-nav:before {left: auto; right: -20px;border-color: transparent transparent transparent white;}
      .media-body{text-align: left;}
      // /*---------end table design----------*/
 
 
     .forgotBody .login-description h5{font-size: 22px;}
     .forgotBody .login-description p, .veryfy-your-mail-description p{
       font-size: 14px;
     }
 
     .veryfy-your-mail-form .k-flex.checkBoxM{display: block}
     .checkBoxM .settings-feld{margin-bottom: 10px;}
     /*---------end verification design----------*/
 
 
      /*---------start pagingnation design----------*/
      .pagingnation-list li:first-child {
           width: 55px;
       }
       .pagingnation-list li:last-child {
           width: 55px;
       }
 
       .pagingnation-list li {
           width: 33px;
           height: 33px;
           line-height: 34px;
       }
       /*---------end pagingnation design----------*/

      /*---------start congratulation design----------*/
      .congratulationPopup2{width: 100%;    margin: 20px auto;     padding: 20px;}
      .congratulationPopup2 .popupBody .feilds-instruction h1 {
        font-size: 24px;}
        .congratulationPopup .congratilation-circle {
          width: 200px;
          height: 200px;
      }
      .congratulationPopup .congratilation-circle svg {
        width: 60px;
        height: 60px;
    }
      /*---------end congratulation design----------*/
      .popupLivechat{
        width: 90%;
        z-index: 99999;
      }


        /*---department start ---------*/

        .usr-mm-btn .btnBlueSettings.add-agent-clik span{display: none;}
        .usr-mm-btn .btnBlueSettings.add-agent-clik{
            position: fixed;
            bottom: 70px;
            right: 10px;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            font-size: 20px;
            padding: 0px 0px;
        }
        .departmentUsertable > thead{display: none;} 
  
        .departmentUsertable > tbody > tr > td {
            display: none;
        }
        .departmentUsertable > tbody > tr > td:first-child {
            display: table-cell;
        }
        .departmentUsertable > tbody > tr > td:last-child {
            display: table-cell;
        }
  
        .departmentUsertable > tbody > tr > td:nth-child(3) {
          display: table-cell;
      }
  
        .departmentUsertable .outline-1-black {
            outline: 0px solid transparent;
        }
  
        .departmentUsertable > tbody > tr {
            border-bottom: 1px solid rgba(51, 51, 51, 0.12);
        }
  
      /*---department end ---------*/


    .sidebar.IOLP {
        height: calc(100% - 50px);
        top: 50px;
    }

    .not-filter {
        height: calc(100vh - 203px) !important;
    }



    .VRP #filterByOptions,.VRP #filterByList{
      z-index: 9;
    }

    .selectOptionDetactLanguage {
      margin-right: 5px;
      width: 80px;
  }

  .languageFilter p.detactLanguage {
      font-size: 10px;
  }

  .translateChat {
    margin: 10px;
    padding: 10px 10px;
    float: left;
    width: calc(100% - 20px);
  }

      

  }



  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  @media (min-width: 320px) and (max-width: 480px) {


    h3.card-title {font-size: 18px !important;}
    h5.card-title { font-size: 14px !important;}
    .mobile-hide{display: none !important;}
    .mobile-display{display: block;}




    /*----start right panel---*/
    .page-wrapper{margin-left: 0;}
    .rightPanelSection{width: 100%;}
    .chat-status .chat-status-rating-col { width: calc(50% - 10px);}
    .pagingnation {width: 100%;}
    .page-wrapper{height: calc(100vh - 60px); overflow: auto;}
    .responMaineDes .page-wrapper{height: calc(100vh - 0px); overflow: auto;    margin-left: 60px; width: calc(100% - 60px);}
    .responsive-btn{
      display: inline-block;
      height: 40px;
      width: 40px;
      background: #fff;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
      cursor: pointer;
      font-size: 14px;border: 0px;}
    /*----end right panel-----*/

    .chat-body .chat-content-New.scroll-4 {
      overflow-x: hidden;
    }


    .chat-body .chat-content-New #messagesdata{
      margin-left: 10px;
    }

    .chat-footer .form-row > .col{
      padding-left: 0px;
      padding-right: 0px;
    }

    .chat-footer .form-row .col-auto{
      padding-left: 0px;
    }
    
    .chat-body .chat-content-New{
      padding-left: 10px;
      padding-right: 10px;
    }

    .mobile-leftRightPanelDisplay{
      display: block;
    }


    /*------start left menu-------*/
    .mobile-menu{ display: block;}
    .navigation{    min-height: 50px;}
    .navigation-not-liveChat, .navigation-visible-overflow{bottom: 0;width: 100%;top: auto; height: auto; padding: 0 !important;    position: fixed; background: #1C223A;z-index: 9999; overflow: visible;    border-top: 0px solid rgba(245, 246, 250, 0.85);}
    .navigation-not-liveChat > a, .navigation-visible-overflow > a{display: none;}
    .navigation-not-liveChat ul.navbar-nav > li, .navigation-visible-overflow ul.navbar-nav > li{display: inline-block;}
    // .navigation-not-liveChat ul.navbar-nav > li:nth-of-type(1n+6), .navigation-visible-overflow ul.navbar-nav > li:nth-of-type(1n+6){display: none}
    // .navigation-not-liveChat ul.navbar-nav > li:last-child, .navigation-visible-overflow ul.navbar-nav > li:last-child{display: inline-block;float: right;}
    // .navigation-not-liveChat.mobileAddCNew  ul.navbar-nav > li, .navigation-visible-overflow.mobileAddCNew ul.navbar-nav > li{display: inline-block !important}
    .navbar-light .navbar-nav a.nav-link{display: inline-block; padding: 6px 15px !important;}
    .navigation-not-liveChat .navbar-nav, .navigation-visible-overflow .navbar-nav{display: block; text-align: left; padding: 0 !important;}
    .sidebarnavChild{z-index: 99;height: auto;right: 0;left: auto; display: none;}
    .activeNavigation > span::before, .activeNavigation > span::after{display: none;}
    .left-menu-title-logo{display: none;}
    
    
    /*------end left menu-------*/

     /*------start topbar menu-------*/
     .topbar {width: 100%; margin-left: 0;}
     .sidebar-collapse {margin-left: 0;width: 100%;}
     .responMaineDes .topbar {width: calc(100% - 60px); margin-left: 60px;}
     /*------end topbar menu-------*/
     .custom-active-chat-user .badge-top-right{top: -8px;}
    /*-------start Login/signup page design------*/
    .login-header img.loginHeaderLogo {width: 155px;}
    .logo-section > img{width: 155px !important;}
    .loginBody { width: 100%;  margin: auto;position: relative;left: 0;top: 0;transform: inherit;}
    .signup-description h5 {font-size: 22px !important; line-height: 1.2;}
    .forgotBody{width: 100%;}
    .login-body .login-form { width: 100% !important; padding: 10px 10px 10px 10px !important;}
    .loginFormOrlienparent{display: inline-block; width: 100%;}
    .login-body .login-social { width: 100%;float: none;padding: 10px;}
    .socialBtnCenter {height: auto;}
    .socialMediaBtnLink {margin-left: auto;margin-top: 20px;}
    .orLine::after{content: '';}
    .loginFooterReview {  position: relative; }
    .reviewcompaniesimg {  width: 50px;}
    .loginImgFilter .reviewcompaniesimg img { width: 100%; }
    .registrationImgFilter .reviewcompaniesimg img{ width: 100%; }
    /*-------start Login/signup page design------*/

    /*-------start enter your company info design------*/
    .company-information1 .login-description h5 { font-size: 22px !important;}
    .company-information1 .login-description{
        padding-bottom: 0px !important;
        padding-top: 50px;
    }
    .company-information { position: relative; left: 0; top: 0; transform: inherit;}
    .company-information1 { width: 100%;}
    .catfeacture-section-2 {width: 100%;  }
    .catfeacture-section-2 ul {padding-left: 0 !important; padding-top: 0 !important;}
    .catfeacture-section-2 ul li {display: inline-block; float: none !important; padding: 2px 5px !important;}
    .catfeacture-section-2 ul li img {height: 16px !important;}
    .footerStepbyStep > .container{padding: 0;}
    /*-------end enter your company info design------*/

    /*-------start Customize your widget design------*/ 
    .custmize-your-widget-heading{width: 100%;}
    .custmize-your-widget h1 {font-size: 22px;}
    .custmize-your-widget p.text-mutede, .checkbox-reg, .login-form > div, .clickHereVerfCode{font-size: 12px;}
    .clicntLogo-heading{font-size: 15px;color: #9c9c9c;}
    /*-------end Customize your widget design------*/

    /*-------start Congratulations! design------*/
    .verificationCodePara22 h4 { font-size: 18px;}
    .verificationCodePara p { font-size: 12px;}
    .verifictionCodeFeilds { width: 100%;}
    .verifictionDownEnter p {font-size: 11px;}
    .verifictionDownEnter h5 {font-size: 12px;}
    .feildsInputs input[type="text"]{height: 50px;font-size: 1.5rem;}
    /*-------end Congratulations! design------*/

    /*-------start Create agent profile design------*/
    .custmize-your-widget h1 { font-size: 22px !important;}
    .custmize-your-widget p.card-body-text { font-size: 12px;}
    /*-------end Create agent profile design------*/

    /*-------start Install your livechat's widget design------*/
    .verificationCodePara h4 { font-size: 18px;}
    .custimize-wraper {width: 100% !important;}
    .code-widget-copy textarea {padding: 10px;font-size: 12px;height: 100px;}
    /*-------end Install your livechat's widget design------*/

    /*---------start settings design----------*/
    .chatform-Preview .container-fluid{padding: 0px;}
    .idle-section .settings-form-feilds{margin-left: 10px !important; margin-right: 10px !important;margin-bottom: 10px; display: inline-block;}
    .settings-form-feilds .settings-feld .main-checkbox-all {   top: -10px;  position: relative; width: 30px; float: left;}
    .setting-section h5.card-title {  width: calc(100% - 30px); float: left;}
    .chatform-Preview .setting-section.idle-section .settings-form-feilds .settings-feld.form-feilds .wrapperInfo.mx-4{ margin: 10px 0px !important;display: inline-block; width: 100%;}
    .chatform-Preview .setting-section.idle-section .settings-form-feilds .settings-feld.form-feilds .wrapperInfo textarea{width: 100% !important;}
    .setting-footer-fixed, .setting-footer-fixed-RightPreview { width: 100% !important;left: 0;}
    .responMaineDes .setting-footer-fixed-RightPreview { width: calc(100% - 60px) !important; left: 60px;}
    .btnBlueSettings {font-size: 12px;padding: 5px 15px;}
    .btnWhiteSettings { font-size: 12px; padding: 5px 15px;}
    .btnRestore {font-size: 12px;padding: 5px 15px;}
    /*
    End Timeout settings
    */
    .setup-heading{display: block !important;}
    .setup-list-design.pt-5 ul li .align-items-center{display: block;}
    .setup-list-design.pt-5 ul li .align-items-center div.ml-auto{text-align: right;}
    // .scriptCodeSetup{height: 80px !important; width: 100%!important; word-break: break-all!important;}
    .website-verifylink-feilds {width: 100%;}
    .widgetsucess {
      padding: 6px 10px;
      font-size: 12px;
      display: inline-block;
      margin-top: 5px;
  }
  .end-chat-shadow {
    top: 116px;
    width: calc(100% - 0px);
    height: calc(100% - 180px);
    margin: 0;
    padding: 0;
    max-width: 100%;
    min-width: 100%;
}
      /*
    End Setup
    */
    .mixListingPreview {right: 6px;}
    /*
     End  Chat forms
     */

    /*
    End Widget Settings
    */
    /*---------end settings design----------*/
    .catfeacture-section-2 {
        width: 100% !important;
        bottom: 0px;
    }

    .company-information {
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      transform: inherit !important;
      -webkit-transform: inherit !important;
      -moz-transform: inherit !important;
      -ms-transform: inherit !important;
      -o-transform: inherit !important;
    }

    .company-information1 {
      width: 100% !important;
    }

    .veryfy-your-mail-form {
      width: 100%;
      margin: 50px auto;
    }

    .forgotBody .login-description h5{font-size: 27px;}
    .forgotBody .login-description p, .veryfy-your-mail-description p{
      font-size: 14px;
    }
    /*---------end verification design----------*/
    .agent-table .avatar-sm, .agent-table .avatar-sm > span > img {
        height: 2rem;
        min-height: 2rem;
        width: 2rem;
        min-width: 2rem;
    }

     /*---------start table design----------*/

     .liveCgatUserTable > thead{display: none;}
     .liveCgatUserTable > tbody > tr > td{display: none;}
     .liveCgatUserTable > tbody > tr > td:first-child{display: table-cell;font-size: 0.8em;}
     .liveCgatUserTable > tbody > tr > td:last-child{display: table-cell;padding: 10px 10px;}
     .liveCgatUserTable .media-body.align-self-center.text-truncate{text-align: left;}
     .liveCgatUserTable .outline-1-black{    outline: 0px solid transparent;}
     .liveCgatUserTable > tbody > tr{ border-bottom: 1px solid rgba(51, 51, 51, 0.12);}
     .user-table-main-header .aside-agent {display: block;margin-bottom: 10px !important; margin-right: auto !important;}
     #resposnive-table-class .user-table-main-header{display: none;}
     .search-feilds-history {width: calc(100% - 45px);margin-left: 0;}
     .responsiveTbaleHeaderFilter .user-table-main-header{display: block !important;}
     .search-feilds-history .historey-serch-drp {max-width: 130px;width: 100%;}
     span.gridTileOption {width: 35px; background: rgb(64, 146, 250) !important; height: 40px; line-height: 40px; margin-top: 5px;}
     span.gridTileOption svg path{fill: #fff;}
     .search-feilds-history #searchvisitor{border: 1px solid #dcdcdc; border-left: 0px;}
     .search-feilds-history .select-search-drpdown.material-input .input-group-append {
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
     } 
     .sideMenue{display: block !important}
     #ongoing_collapse {  max-height: calc(100vh - 250px);}
     #incoming_collapse { max-height: calc(100vh - 270px);}
     .profile-nav {  left: auto; right: 55px;width: 210px;bottom: -2px;}
     .profile-nav:before {left: auto; right: -20px;border-color: transparent transparent transparent white;}
     .media-body{text-align: left;}
     .user-profile-nav {width: 30px;height: 30px;line-height: 30px;font-size: 0.8em;}
     .nav-item.mob-icon img {bottom: 8px !important;}
     .message-message-first {max-width: 100%;padding-left: 10px;}
     .body-section {margin-bottom: 10px;}
     .rightPanelSection .bodyRightPanel {overflow: auto;height: calc(100vh - 105px);}
     .rightPanelSection .categoryRightPanel.chat-his-name .bodyRightPanel {height: calc(100vh - 195px);}
     .rightPanelSection .categoryRightPanel.chat-his-name.filter-view .bodyRightPanel {height: calc(100vh - 105px);}
     .departmentUsertable > tbody > tr:last-child, .liveCgatUserTable > tbody > tr:last-child {border: none;}
     .chat-footer .input-group #msg.textAreaChat {font-size: 0.8em !important;}
     .file-attched-complete-drop{width: 102px;height: 90px;}
     .attachedAddButton {height: 90px;}
     .scroll-4 {padding-right: 0;}
     .message .message-content-his .message-content-new {max-width: calc(100% - 0px);}
     /*---------end table design----------*/


    .forgotBody .login-description h5{font-size: 22px;}
    .forgotBody .login-description p, .veryfy-your-mail-description p{
      font-size: 14px;
    }

    .veryfy-your-mail-form .k-flex.checkBoxM{display: block}
    .checkBoxM .settings-feld{margin-bottom: 10px;}
    /*---------end verification design----------*/


     /*---------start pagingnation design----------*/
     .pagingnation-list li:first-child {
          width: 55px;
      }
      .pagingnation-list li:last-child {
          width: 55px;
      }

      .pagingnation-list li {
          width: 33px;
          height: 33px;
          line-height: 34px;
      }
      
      /*---------end pagingnation design----------*/


      /*---------start congratulation design----------*/
      .congratulationPopup2{width: 100%;    margin: 20px auto;     padding: 20px;}
      .congratulationPopup2 .popupBody .feilds-instruction h1 {
        font-size: 24px;}
        .congratulationPopup .congratilation-circle {
          width: 200px;
          height: 200px;
      }
      .congratulationPopup .congratilation-circle svg {
        width: 60px;
        height: 60px;
    }
      /*---------end congratulation design----------*/


      .popupLivechat{
        width: 90%;
        z-index: 99999;
      }

      /*---department start ---------*/

      .usr-mm-btn .btnBlueSettings.add-agent-clik span{display: none;}
      .usr-mm-btn .btnBlueSettings.add-agent-clik{
          position: fixed;
          bottom: 70px;
          right: 10px;
          border-radius: 100%;
          width: 40px;
          height: 40px;
          font-size: 20px;
          padding: 0px 0px;
          z-index: 9;
      }
      .responMaineDes .usr-mm-btn .btnBlueSettings.add-agent-clik{
        bottom: 12px;
      }
      .departmentUsertable > thead{display: none;} 

      .departmentUsertable > tbody > tr > td {
          display: none;
      }
      .departmentUsertable > tbody > tr > td:first-child {
          display: table-cell;
      }
      .departmentUsertable > tbody > tr > td:last-child {
          display: table-cell;
      }

      .departmentUsertable > tbody > tr > td:nth-child(3) {
        display: table-cell;
    }

      .departmentUsertable .outline-1-black {
          outline: 0px solid transparent;
      }

      .departmentUsertable > tbody > tr {
          border-bottom: 1px solid rgba(51, 51, 51, 0.12);
      }

    /*---department end ---------*/


    

    .sidebar.IOLP {
        height: calc(100% - 50px);
        top: 50px;
    }

    .not-filter {
        height: calc(100vh - 208px) !important;
    }

    .VRP #filterByOptions,.VRP #filterByList{
      z-index: 9;
    }

    .profile-rightpanel, .rightPanelSection{
      z-index: 999999;
    }
    .selectOptionDetactLanguage {
        margin-right: 5px;
        width: 80px;
    }

    .languageFilter p.detactLanguage {
        font-size: 10px;
    }

    .translateChat {
      margin: 10px;
      padding: 10px 10px;
      float: left;
      width: calc(100% - 20px);
    }




  #endchatwindow.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  }


#stickynotes.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
} 

#visitorpaths.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#add_stickynotes.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#transferagent.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
} 

#chat1-members.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#addagentdiv.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#leavechatwindow.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
} 

#filterByOptions.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
} 

#filterByList.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
} 

#orderByOptionsList.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#chatHistorySee.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

#visitarPathNews.chat-sidebar{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}


.dataManagement-section .dataMangmentRow .dataManegment-row.k-flex{
  display: block;
}

.dataManagement-section .dataMangmentRow .dataManegment-row.k-flex .dataManegment-right{margin-top: 10px;}

.widget-sec h4{font-size: 14px;}



      
  }

  // Responsive css media 17-march-2021
  @media (max-width:1599px) {
    .color-picker span.picker-text {
      width: 100%;
    }
    .color-picker span.picker-text input {
      margin-top: 3px;
    }
  }
  @media(max-width:1400px) {
    .imageList ul li{
      width: calc(50% - 20px);
      margin: 3px;
    }
    .imageList {
      width: calc(100% - 220px);
    }
    .text-img-view{
      width: 220px;
    }
  }

  @media(max-width:1299px) {
    .apexcharts-legend.position-bottom .apexcharts-legend-series {
      float: left;
      width: 100%;
      max-width: 100px;
    }
    .visitor-info ul li {
      width: 100%;
    }
  }
  @media(max-width:1260px) {  
    #resposnive-table-class .user-table-main-header.mobShow .fltr-view {
      width: calc(100% - 250px);
    }
    .avatar-sm, .avatar-sm > span > img {
      height: 2rem;
      min-height: 2rem;
      width: 2rem;
      min-width: 2rem;
  }
  .liveCgatUserTable .media .media-body.align-self-center {
    max-width: 110px;
  } 
  }

  @media(max-width:1200px) {
    .centerWidgetArrow h2 {font-size: 35px !important;}
    .liveCgatUserTable > tbody > tr > td, .liveCgatUserTable > thead{display: none;}
    .liveCgatUserTable > tbody > tr > td:first-child,
    .liveCgatUserTable > tbody > tr > td:nth-child(2), 
    .liveCgatUserTable > tbody > tr > td:nth-child(5),
    .liveCgatUserTable > tbody > tr > td:last-child { 
      display: table-cell;
  }
  .mobile-menu .responsive-btn {position: absolute;top: -60px;right: 0px;height: 35px;width: 35px;line-height: 35px;}
  .mobile-menu {display: block;}
  .responsive-btn{
    display: inline-block;
    height: 40px;
    width: 40px;
    background: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
    cursor: pointer;
    font-size: 14px;border: 0px;}
    #resposnive-table-class .user-table-main-header {
      display: none;
  }

  #resposnive-table-class .user-table-main-header.mobShow {
    display: block;
    padding: 0 5px;
}


  .responsiveTbaleHeaderFilter .user-table-main-header {
    display: block !important;
}
.user-table-main-header .aside-agent {
  display: block;
  margin-bottom: 10px !important;
  margin-right: auto !important;
}
.aside-agent .wrapperInfo .customArrow {
  max-width: inherit;
}
.page-wrapper .main-wrapper .main-heading.main-heading-title {
  width: calc(100% - 0px);
}
.user-table-main-header .search-bar, .user-table-main-header .material-select .makeStyles-formControl-5 {
   width: 100%;
  }
.sidebarnavChild {
  z-index: 99;
  height: auto;
  right: 0;
  left: auto;
  display: none;
}
.sidebarnavChild.sideMenue {
  display: block !important;
}
.analytics-left + header + div.sidebar-collapse {
  margin-left: auto;
}
.sidebar-collapse {
  margin-left: 60px;
  width: calc(100% - 60px);
}
.setting-section .setting-footer-fixed {width: calc(100% - 140px);}

.widgetDeskDesignRight{display: block}
.setting-footer-fixed-RightPreview {width: calc(100% - 136px);}


.table.table-borderless.liveCgatUserTable.mob-tbl-view {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.liveCgatUserTable.mob-tbl-view.online-agent {
  padding:  0 10px;
}
.liveCgatUserTable.mob-tbl-view.online-agent tbody tr td .company-owner.icon-owner img {
  width: 18px;
}
.table.table-borderless.liveCgatUserTable.online-agent tbody tr td:first-child {
  padding: 6px 5px;
}
.table.table-borderless.liveCgatUserTable.online-agent tbody tr td:first-child,
.table.table-borderless.liveCgatUserTable.online-agent tbody tr td:last-child {
  display: table-cell;
  border: none;
  border-radius: 0;
}
.table.table-borderless.liveCgatUserTable.mob-tbl-view.online-agent tr {
    box-shadow: none;

}


.mobile-agent-table {
  background: #fff;
  margin: 0px -15px;
}

.mobile-tranparent {
  background: transparent !important;
}

.table.table-borderless.liveCgatUserTable.mob-tbl-view tr {
  background: #fff;
  box-shadow: 0px 1px 3px 1px #D1D1D1;
}

.body-section.mob-view-cont {
  padding: 0 !important;
  background: transparent !important;
}

.viewTranscript {
  color: #FFA93A;
  cursor: pointer;
  background: #FFF5EA;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.setting-footer-fixed-RightPreview {width: calc(100% - 140px);}
.mob-mt-4 {margin-top: 2rem !important;}
.agent-table-header.phone-fltr .input-group {float: left;}
.layout .liveChatHeadrMobil {
  display: flex;
}
.main{height: calc(100vh - 50px); top: 50px; }
.sidebar .tab-content {
  top: 0px;
  position: relative;
  height: calc(100% - 50px) !important;
}
.liveCgatUserTable.past-chat > tbody > tr > td:nth-child(1) {
  display: table-cell;
}
.liveCgatUserTable.past-chat > tbody > tr > td:last-child {display: none;}

.planfeaturedetail {
  margin-top: 30px;
}
.incomingSection .position-top, .incomingSection .position-top + .tour_guide {
  z-index: 999;
}
.overlay-guide.bottom-div {
  z-index: 999;
}

.logout-class.deactivate-nav {
  bottom: -20px;
}
.addTextArea .liveCgatUserTable > thead {
  display: table-header-group;
}
.addTextArea .liveCgatUserTable > tbody > tr > td {
  display: table-cell;
}
.addTextArea .table.table-borderless.liveCgatUserTable.mob-tbl-view tr {
  background: inherit;
}
.addTextArea .table.table-borderless.liveCgatUserTable.mob-tbl-view tr th {width: 120px;}
.addTextArea .table.table-borderless.liveCgatUserTable.mob-tbl-view tr th:first-child{
  width: inherit;
}
.agent-table-header.user-table-main-header .fltr-view {
  float: left;
  width: 100%;
}
.fltr-view .MuiFormControl-root.material-outlined {
  width: 100% !important;
}
.usr-mm-btn .btnBlueSettings.add-agent-clik {
  z-index: 1;
}
.agent-table .first .position-top.posED .tour_guide::after {
  right: 75px;
}
.agent-table .position-top .tour_guide::after {
  right: 70px;
}
.agent-table .position-top.posED .tour_guide {
  right: -30px;
}
  
}


@media(max-width:1199px) {
  .mob-none {display: none;}
  .mobile-Menu-RightPanel{padding: 15px 12px; display: block;}
  .liveCgatUserTable > tbody > tr > td:nth-child(2), .liveCgatUserTable > tbody > tr > td:nth-child(5) {display: table-cell;}
  .rightPanelSection {height: calc(100% - 0px);}
  .chat-sidebar.rightPanelSection.profile-rightpanel {height: calc(100% - 0px);}
  .mobile-menu {
    display: block;
  }
  .idle-section .settings-form-feilds {width: calc(100% - 20px);}
  .sidebar-collapse {margin-left: 0; width: calc(100% - 0px);}
.setup-list-design > ul > li:before {left: -5px;}
.setup-list-design > ul > li:after {
  left: 9px;
}
.setup-list-design > ul > li {padding-left: 45px;}
.setting-footer-fixed {bottom: 55px !important;}
.setting-section .setting-footer-fixed {bottom: 50px;}
.responMaineDes .setting-section .setting-footer-fixed {bottom: 0px;}
.setting-footer-fixed {bottom: -85px;}
.setting-section .setting-footer-fixed, .setting-footer-fixed.notif-view {bottom: 50px;}
  .mob-mx-4 {margin-left: 0.75rem !important;}
  .mob-mr-4, .mob-mx-4 {margin-right: 0.75rem !important;}
  .mob-px-4 {padding-left: 1rem !important;}
  .mob-px-4 {padding-right: 1rem !important;}
  .chatform-Preview .setting-section.idle-section .settings-form-feilds .settings-feld {
    display: block;
    padding: 0!important;
}
  .chatform-Preview .setting-section.idle-section .settings-form-feilds .settings-feld.form-feilds .wrapperInfo.mx-4 {
    margin: 10px 0 0 !important;
    display: inline-block;
    width: 100%;
}
.chatform-Preview .setting-section.idle-section .settings-form-feilds.notification-settings .settings-feld {
    display: flex;
}
.widget-seetings-right {
  width: 100%;
}
.setting-footer-fixed-RightPreview{bottom: 50px !important;}
.agent-table-header.phone-fltr .input-group {
  width: calc(50% - 15px);
  margin: 7px;
}
.agent-table-header.phone-fltr .srch-btn {
  float: right;
}
.main{height: calc(100vh - 140px); top: 50px; }
.responMaineDes .setting-footer-fixed-RightPreview{bottom: 0px !important;}
.responMaineDes .widget-seetings-right {
  width: calc(100% - 60px);
  margin-left: 60px;
}



.navigation-not-liveChat ul.navbar-nav > li, .navigation-visible-overflow ul.navbar-nav > li{display: inline-block;position: relative;}
.sidemenutext {position: absolute;top: auto;bottom: 6px;left: 0;}
.navbar-light .navbar-nav a.nav-link, .navbar-light .navbar-nav .profile-icon a.nav-link,
.navbar-light .navbar-nav .profile-icon {height: 60px !important;}
.navbar-light .navbar-nav .profile-icon a.nav-link span {bottom: 0px;width: 30px;height: 30px;line-height: 30px;}
.navbar-light .navbar-nav .profile-icon a.nav-link .sidemenutext {width: 40px;height: 12px;left: 0;right: 0;margin: 4px auto;line-height: 2;}
.navbar-light .navbar-nav .profile-icon a.nav-link img {bottom: 18px !important;right: 20px !important;}
.navbar-light .navbar-nav .logout-mob .sidemenutext {top: auto !important;bottom: -17px;}
.navigation-not-liveChat ul.navbar-nav > li:nth-of-type(1n+5), .navigation-visible-overflow ul.navbar-nav > li:nth-of-type(1n+5){display: none}
.navigation-not-liveChat ul.navbar-nav > li:last-child, .navigation-visible-overflow ul.navbar-nav > li:last-child{display: inline-block;float: right;}
.navigation-not-liveChat.mobileAddCNew  ul.navbar-nav > li, .navigation-visible-overflow.mobileAddCNew ul.navbar-nav > li{display: inline-block !important}
.responMaineDes .navigation-not-liveChat.mobileAddCNew ul.navbar-nav > li, .responMaineDes .navigation-visible-overflow.mobileAddCNew ul.navbar-nav > li{display: none !important}
.responMaineDes .navigation-not-liveChat.mobileAddCNew  ul.navbar-nav > li:nth-of-type(1n+6), .responMaineDes .navigation-visible-overflow ul.navbar-nav > li:nth-of-type(1n+6), 
.responMaineDes .navigation-visible-overflow.mobileAddCNew ul.navbar-nav > li:nth-of-type(1n+5),
.responMaineDes .navigation-not-liveChat.mobileAddCNew ul.navbar-nav > li:nth-of-type(1n+5)  {display: inline-block !important; }

.responMaineDes .navigation-visible-overflow.mobileAddCNew ul.navbar-nav > li:nth-last-child(4) {margin-top: auto;}
.agent-table-header.phone-fltr .input-group {
  width: calc(50% - 15px);
  margin: 7px;
}
.agent-table-header.phone-fltr .srch-btn {
  float: right;
}
.main{height: calc(100vh - 140px); top: 50px; }
.chart_center_M {width: 100%;}

.rdrMonths {display: block;}
.rdrDefinedRangesWrapper {display: none;}
.profile-nav {left: 0;bottom: 55px;}
.profile-nav:before{
  bottom: -20px;
  left: 30px;
  transform: rotate(90deg);
  width: 10px;
}
.setup-list-design .title-card {width: 100%;}
.hide-scroll-bottom {overflow-x: hidden;}
.scriptCodeSetup textarea#myInputCopy {
  overflow: auto;
  max-height: 100px;
  height: auto !important;
}
.search-feilds-history {
  width: calc(100% - 200px);
}
.warning-ms{
  max-width: inherit;
}
.logout-class.deactivate-nav {
  bottom: 40px;
  right: 15px;
  left: auto;
}
.logout-class.deactivate-nav::before {
  left: auto;
  right: 2px;
  bottom: -19px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
}
.addTextArea .d-flex {
  flex-wrap: wrap;
}
.addTextArea .addArea{width: calc(50% - 20px);}
.addTextArea .btnRestore {
  margin-top: 15px;
}
.agent-table .position-top .tour_guide {
  top: -100px;
}
.ong-inc-chat{width: 90%;}
}

@media(max-width:991px){
  .sidebar-collapse .mob-res {
    padding: 0px !important;
    margin: 10px;
    width: calc(50% - 20px);
    flex: calc(50% - 20px);
    margin-bottom: 8px !important;
  }
  .planDetailServices ul li {
    width: calc(50% - 20px);
    min-height: 130px;
  }
  .widget-message.time_zone h6{
    width: 100%;
    max-width: inherit;
  }
  .widget-message.time_zone > div {
    width: 100%;
  }
  .widget-message.time_zone > p {
    margin-top: 20px;
  }
  .chatReportCol.cstm-box-time .average-rating .wait-time-others.line-diff::before {
    left: -5px;
  }
  .chatReportCol.cstm-box-time .average-rating .online-time p .left {
    margin-right: auto;
}
}
@media (max-width:800px) {
  .agent-table .first .position-top.posED .tour_guide {
    right: -20px;
  }
  .agent-table .position-top .tour_guide::after {
    right: 55px;
}
}
@media(max-width:767px) {
  .mob-mt-20 {margin-top: 20px;}
  .responMaineDes .sidebar-collapse, .responMaineDes .topbar {margin-left: 60px; width: calc(100% - 60px);}
  .responMaineDes .setting-footer-fixed {
    width: calc(100% - 60px) !important;
    margin-left: 60px;
    bottom: 0 !important;
  }
  .liveCgatUserTable > tbody > tr > td:nth-child(1) {
    display: none;
  }
  .liveCgatUserTable > tbody > tr > td.error-img {display: table-cell;} 
  .mob-pt15 {padding-top: 15px;}


  .screen-body {
    max-height: calc(80vh - 180px);
    height: 100%;
  } 
  .powerby img {width: 60px !important;}
  // .mobileScreeNew .screen-body {
  //   height: auto;
  //   max-height: calc(80vh - 211px);
  // }
  .chatforms-rightPanel-Phone .screen-body .form-introduce {padding-bottom: 40px;}
  
  .chatforms-rightPanel-Phone .screen {margin-left: 0px;}
  .chatforms-rightPanel-Phone .screen.screen-chatsetting {
    // width: calc(100% - 60px);
    margin-left: 30px;
    margin-top: 10px;
  }
  .mixListingPreview > ul > li {padding: 5px;}
  .screen-forems .screen-feilds {
    background: #f5f5f5;
    bottom: 29px;
    border-radius: 0;
  }
  .chatforms-rightPanel-Phone .mobileScreeNew {
    // width: calc(100% - 60px);
    margin-left: 30px;
    margin-top: 10px;  
  }
  .chatforms-rightPanel-Phone .screen.screen-chatsetting-mobile {width: calc(100% - 10px);}
  .widgetDeskDesignRight {position: fixed;right: 0;top: 60px;}
  .agent-table-header.banned-fltr .input-group {max-width: 100%;}
  .agent-table-header.banned-fltr .aside-agent {width: 100%;display: block;float: left;margin-top: 5px;}

  .datePic_transform .react-datepicker-popper {
    transform: translate3d(123px, 247px, 0px) !important;
    -webkit-transform: translate3d(123px, 247px, 0px) !important;
    -moz-transform: translate3d(123px, 247px, 0px) !important;
    -ms-transform: translate3d(123px, 247px, 0px) !important;
    -o-transform: translate3d(123px, 247px, 0px) !important;
}


.datePic_transform .react-datepicker-popper  .react-datepicker__triangle{
  left: 165px !important;
}


/*-----------start mobile menu------------*/
.mobileAddCNew {
  height: 100%;
  width: 60px;
}

.mobileAddCNew .navbar-nav{
  display: flex;
  height: 100%;
  align-items: center;
}


/*-----------end mobile menu------------*/

.mob-analytics, .mob-analytics .label-style {display: block !important;}
.mob-analytics .label-style {margin-bottom: 10px;}
.mob-analytics .label-style span select {margin-top: 8px;}
.analytics-datepicker {display: block;background: #fff;width: 100%;}
.rdrDateRangePickerWrapper {margin-left: 20px;width: calc(100% - 20px);}
.rdrDefinedRangesWrapper {display: none;}
.rdrMonths.rdrMonthsHorizontal {width: 100%;}
.rdrCalendarWrapper.rdrDateRangeWrapper {width: 100%;}
.mob-analytics .label-style label {
  display: block;
}
.mob-analytics {
  float: left;
  width: 100%;
}
.mob-analytics .label-style .mob-view {display: block;}
.mob-analytics .label-style .mob-view + span {
    margin: 0 !important;
    padding-top: 10px;
    float: left;
    width: 100% !important;
}
.label-style .mob-view.manage-icon .drop-view {
  float: left;
  width: calc(100% - 28px);
} 
.label-style .mob-view.manage-icon .date-icon {
  display: inline-block;
  padding-top: 16px !important;
  padding-right: 0 !important;
}
.analytics-datepicker {top: 80px;}
.apexcharts-tooltip {width: 100%;left: 0 !important;}
.layout .Toastify__toast {top: 50px;}
#ongoing_collapse .titleShowHover, #incoming_collapse .titleShowHover {display: none;}
.mob-pd-0 {padding: 0 !important;}

.setting-section .fb-tbl-view thead tr th, .setting-section .fb-tbl-view tbody tr td {
  display: none;
}
.setting-section .fb-tbl-view thead tr th:first-child, 
.setting-section .fb-tbl-view thead tr th:last-child, 
.setting-section .fb-tbl-view tbody tr td:first-child, 
.setting-section .fb-tbl-view tbody tr td:last-child {
  display: table-cell;
}
.setup-list-design .title-card.box-4 li {
  width: calc(50% - 20px);
}
.sidebar-collapse .mob-res {
  margin: 20px;
  flex: calc(100% - 20px);
  width: calc(100% - 20px);
  margin-bottom: 5px !important;
}
.chatReportCol.cstm-box-time {
  padding: 10px;
  min-height: 170px;
}

.custome-widget-bot.k-flex {display: block;}
.custome-widget-bot .bodress-widgets{display: none;}
.widget-phone {
  display: none;
}
.preview-btn {display: inline-block;}
.widget-settings .mob-view .btnBlueNext {
  width: calc(50% - 15px);
  margin: 0 15px 0 0;
  padding: 6px 20px;
}
.custome-widget-bot .widget-settings {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  padding: 0 !important;
}
.widget-phone {
  display: block;
  position: fixed;
  top: 25px;
  z-index: 99;
  background: #f8f9fe;
  max-width: 350px;
  max-height: 500px;
  height: auto;
  padding: 30px;
  margin: 0 auto;
  right: 20px;
  width: 100%;
  border-radius: 10px;
  z-index: -1;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  opacity: 0;
}
.widget-phone.show{
  z-index: 9;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  opacity: 1;
}

.close-icon {
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  right: 10px;
  border-radius: 50%;
  line-height: 25px;
  font-size: 12px;
  color: #fff !important;
  cursor: pointer;
  text-align: center;
}
.setup-list-design .position-top .tour_guide.guide-position {
  left: 0;
  bottom: -275px;
}

.navbar-light .navbar-nav .profile-icon a.nav-link .sidemenutext {
  margin: 0 auto;
}
.navbar-light .navbar-nav .profile-icon a.nav-link img {
  bottom: 18px !important;
  right: 12px !important;
}
.chat-sidebar.position-top .tour_guide {
  bottom: 50px;
}
.chat-sidebar.position-top .tour_guide::before {
  top: auto;
  bottom: 0;
}
.chat-sidebar.position-top .tour_guide::after{
  top: auto;
  bottom: -10px;
}
.card-body-text.position-top .tour_guide {
  left: 0;
  bottom: 65px;
}
.card-body-text.position-top .tour_guide::before {
  top: auto;
  bottom: 0;
}
.card-body-text.position-top .tour_guide::after {
  bottom: -10px;
  left: 100px;
  right: auto;
  top: auto;
}
.ongoingSection.position-top .tour_guide {
  left: 0;
  top: 125px;
}
.incomingSection.position-top .tour_guide {
  left: 0;
  top: 235px;
}
.ongoingSection.position-top .tour_guide::before, 
.incomingSection.position-top .tour_guide::before {
  left: 0;
  right: 0;
  top: -7px;
}
.ongoingSection.position-top .tour_guide::after, 
.incomingSection.position-top .tour_guide::after {
  left: 0;
  right: 0;
  top: -10px;
}
.ongoingSection.position-top .incoming-outgoing-btn,
.ongoingSection.position-top .tour_guide,
.incomingSection.position-top .incoming-outgoing-btn.accordion, 
.incomingSection.position-top .tour_guide {
  z-index: 999;
}
.widget-design .position-top .tour_guide {
  right: 0;
  top: -140px;
}
.setup-list-design .position-top .tour_guide::after {
  top: auto;
  bottom: -10px;
  right: 30px;
}

.widget-design .position-top .torgideNew .tour_guide {
  top: -35px;
  right: 0;
}
.incomingSection .position-top + .tour_guide {
  left: 0;
  right: auto;
  top: 260px;
  right: 0;
}
.incomingSection .position-top + .tour_guide::before {
  top: 0;
  left: 0;
  right: 0;
}
.incomingSection .position-top + .tour_guide::after {
  left: 0;
  right: 0;
  top: -10px;
}
.chat-header ul.position-top .tour_guide {
  top: 45px;
  right: auto;
  left: auto;
  width: 300px;
}
.chat-header ul.position-top .tour_guide::after, .chat-header ul.position-top .tour_guide::before {
  right: 14px;
}
// .chat-body .nav.position-top .overlay-guide.bottom-div {
//   z-index: -1;
// }
.planPhoneScreen {
  margin: 0 auto;
  float: none;
}
.planDetailServices {
  width: calc(100% - 0px);
}
.center-mobile {text-align: center;}
.addTextArea .liveCgatUserTable > tbody > tr > td:nth-child(1) {
  display: table-cell;
}
.addTextArea .agent-table{overflow-x: auto;}
.addTextArea .table.table-borderless.liveCgatUserTable.mob-tbl-view{
  width: 767px;
}
.mob-w-100 {
  width: 100% !important;
}
.mobViewDrop {
  margin: 0 !important;
  margin-top: 10px !important;
}

.moneyBanner {
  margin-top: 30px;
}
.agent-table .first .position-top.posED .tour_guide {
  top: 50px;
  right: 0px;
}
.agent-table .position-top .tour_guide::after {
  bottom: auto;
  right: 50px;
  top: -10px;
}
.triggers-design .card-info .crossAbs {
  top: -3px;
  right: 4px;
}
.triggers-design .card-info .link-style {
  margin-top: 10px;
}
.triggers-design .user-table-main-header .search-bar,
.agent-table-header.user-table-main-header .fltr-view {
  width: 100% !important;
}
.triggers-design .user-table-main-header .search-bar .search-div{
  width: calc(100% - 168px);
}
.triggers-design .user-table-main-header .search-bar.full-view .search-div{
  width: 100%;
}
.agent-table .position-top.posED .tour_guide{
  right: -10px;
  top: 50px;
}

.following-actions .col-md-3 {
  margin-bottom: 10px;
}
.following-actions .col-md-4 .col-md-12 {
  padding-left: 0;
}

.layout.responMaineDes .liveChatHeadrMobil{ 
  width: calc(100% - 60px);
  margin-left: 60px;
}

.responMaineDes .main {
  height: calc(100% - 50px);
  top: 50px;
  width: calc(100% - 60px);
  margin-left: 60px;
}

}

@media(max-width:600px){
  .billing-text .two-input.no-border input {
    width: 70px;
  }
  .overlay-guide {
    z-index: 999;
  }
  .plan-upgrade .navigation-not-liveChat {
    z-index: 999999;
  }
  .addTextArea .addArea {
    width: calc(100% - 0px);
    margin: 0;
}
.moneyBanner {
  flex-direction: column-reverse;
}
.moneyBanner .moneyImg {
  width: 100%;
  background: transparent;
  text-align: center;
  border-radius: 0;
}
.moneyBanner .moneyImg img {
  max-width: 80px;
}
.moneyBanner .bannerText {
  width: 100%;
}
.setup-list-design .title-card li {
  width: calc(100% - 0px);
  margin: 10px auto;
}

}

@media (max-width:479px) {
  .liveCgatUserTable > tbody > tr > td:nth-child(2), .liveCgatUserTable > tbody > tr > td:nth-child(5) {
    display: none;
  }
  .liveCgatUserTable > tbody > tr > td:nth-child(1) {display: table-cell;}
  .liveCgatUserTable > tbody > tr > td.error-img img {width: 100%;}
  .btnWhite, .btnBlue {padding: 6px 12px;}
  .body-section.mob-view-cont {padding: 0 !important;background: transparent !important;}
  .table.table-borderless.liveCgatUserTable.mob-tbl-view {
    border-collapse: separate;
    border-spacing: 0 15px;
  }
  .body-section .agent-section {padding-bottom: 50px;}
  .table.table-borderless.liveCgatUserTable.mob-tbl-view tr {
    background: #fff;
    box-shadow: 0px 1px 3px 1px #D1D1D1;
  }

  .viewTranscript{
    color: #FFA93A;
    cursor: pointer;
    background: #FFF5EA;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.forem-rightPanel .rightFeildsTitle {
  padding: 0px 0px;
}

.mobile-tranparent{
  background: transparent !important;
}

.mobile-agent-table{
  background: #fff;
  margin: 0px -15px;
}

.responsiveTbaleHeaderFilter .filterMobile-bg{
  background: #fff;
  padding: 20px 20px;
  box-shadow: 0px 2px 3px 1px rgba(128, 128, 128, 0.28);
}

.responsive-p-0{padding: 0;}

.dataManagement-section p.card-body-text{
  font-size: 13px;
}

#setuppage textarea#myInputCopy{
  height: 90px !important;
}

  .search-feilds-history.mob-drop {
    border: none;
    display: block;
    float: left;
    width: 100%;
  }
  .input-group.mob-fltr .search-feilds-history.mob-drop .w-150 {
    width: 100% !important;
}
  .search-feilds-history.mob-drop .historey-serch-drp {
    border: 1px solid #dcdcdc;
    margin-right: 5px;
    height: 40px;
    line-height: 36px;
    background: #fff;
    font-size: 14px;
    width: 100%;
    float: left;
    max-width: inherit;
    margin-bottom: 15px;
    z-index: 9;
}
.search-feilds-history.mob-drop .historey-serch-drp .dropdown-toggle {
  font-size: 0.8em;
}


.search-feilds-history.mob-drop .select-search-drpdown input {
  height: 40px !important;
}
.dataManagement-section.mob-card .dataMangmentRow {padding: 0 !important;padding-bottom: 15px !important;margin-top: 0 !important;}
.chatforms-rightPanel-Phone .screen.screen-chatsetting .screen-body {height: calc(100vh - 290px) !important;}
.mobileScreeNew .screen-body {height: calc(100vh - 350px);}
.backgrounds-color-tab.backgrounds-color-agent span {width: 125px;}
.chat-forems-section .setting-footer-fixed-RightPreview {padding: 20px 15px !important;}
.chat-forems-section .setting-footer-fixed-RightPreview .btnBlueSettings {padding: 5px;}
// .screen-less .screen-body {height: 290px !important;}
// .screen-less-Mobile .screen-body {height: 240px !important;}
.agent-table-header.phone-fltr .input-group {width: 100%;margin: 0 0 10px;}
.main{height: calc(100vh - 100px); top: 50px; }
.input-group.mob-fltr {display: block;}
.mob-fltr .histroy-filter {float: right;}
.mob-fltr.download-manager .histroy-filter {float: left;margin-left: calc(100% - 124px);margin-top: 8px;}
.mob-fltr.download-manager .download-btn {margin-top: 8px;}
.mob-fltr.download-manager .download-btn .btn-primary {
  padding: 8px;
  margin-top: 6px;
}
.liveCgatUserTable.past-chat > tbody > tr > td:nth-child(2) {display: table-cell;}
.billingDetails > ul > li {float: left;width: 100%;}
.billing-text.k-flex.align-items-center {
  display: block;
  width: 100%;
  padding: 0 0 5px;
}
.plane-section-right {margin-top: 5px; width:100%}
.plane-section-right select, .plane-section-right input {
  width: 100% !important;
}
.plane-section-right .material-select div {width: 100% !important;}
.plane-section-right .material-select {margin-top: 20px;}
.keyFeaturesList > ul > li {width: 100%;margin: 0;}
.popupLivechat .popupBody {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.subscription-tbl tr:last-child > td:first-child {display: none;}
.subscription-tbl tr:last-child > td:last-child {text-align: center;}


.datePic_transform .react-datepicker-popper {
  transform: translate3d(123px, 247px, 0px) !important;
  -webkit-transform: translate3d(123px, 247px, 0px) !important;
  -moz-transform: translate3d(123px, 247px, 0px) !important;
  -ms-transform: translate3d(123px, 247px, 0px) !important;
  -o-transform: translate3d(123px, 247px, 0px) !important;
}


.datePic_transform .react-datepicker-popper  .react-datepicker__triangle{
left: 165px !important;
}

.mixListingPreview{
  display: none;
}
.agent-table-header.phone-fltr .input-group {width: 100%;margin: 0 0 10px;}
.main{height: calc(100vh - 140px); top: 50px; }
.input-group.mob-fltr {display: block;}
.mob-fltr .select-search-drpdown {
  width: calc(100% - 50px);
  float: left;
}
.mob-fltr.download-manager .select-search-drpdown {
  width: calc(100% - 128px);
  margin-top: 15px;
}
.mob-fltr .histroy-filter {float: right;}
.liveCgatUserTable.past-chat > tbody > tr > td:nth-child(2) {display: table-cell;}
.rdrMonth {width: 100% !important};
.left-widgetsetings-box img {width: 100%;}
.left-widgetsetings-box .info-icon img {width: auto;}
.left-widgetsetings-box .nps-satis {padding-left: 20px !important;width: 100% !important;}
.rdrMonths.rdrMonthsHorizontal {display: block;}
#whatsapptemplates.chat-sidebar {
  max-width: 370px;
  min-width: inherit;
  width: 100%;
}
.__react_component_tooltip {left: 0 !important;}
.languageFilter p.detactLanguage {float: left;width: 100%;}
.translateChat .languageFilter .detactLanguage {float: left;width: 100%;}
.selectOptionDetactLanguage {
  margin-right: 5px;
  width: 190px;
  margin-top: 10px;
}
.languageFilter .detactLanguageBTN {
  width: 78px;
  float: right;
  margin-top: 10px;
}
.chatReportCol .chatReport-total-chats .online-time .big, 
.chatReportCol .chatReport-total-chats .no-data, 
.chatReport-total-chats .online-time .no-data {
  font-size: 30px;
}
.chatReport-total-chats .online-time .no-data.normal-text {
  font-size: 18px;
}
.wait-time-others p .left {
  font-size: 13px;
}
.chatReport-total-chats .wait-time-others .big {
  font-size: 26px;
}
.setup-list-design .title-card li {
  display: block;
  width: 100%;
  margin: 0 0 20px;
}
.setup-list-design .title-card.box-4 li {
  width: calc(100%);
}
.billing-text .two-input {
  max-width: 400px;
  width: 100%;
}
.billing-text .two-input input {
  width: calc(50% - 20px) !important;
}
.setup-heading.tabs-view button {
  padding: 20px 23px;
}
.chatReportCol.cstm-box-time .online-time {
  padding-right: 18px;
}
.chatReportCol.cstm-box-time .wait-time-others::before {
  left: -15px;
}
.navbar-light .navbar-nav a.nav-link {
  padding: 6px 17px !important;
}
// .dropdown-toggle::after {
//   top: 17px;
// }
.setup-list-design .title-card li .social-img img {
  margin-bottom: 0;
  max-width: 100px;
}
.setup-list-design .title-card li .social-img svg {
  height: 80px !important;
}
.planDetailServices ul li {
  width: calc(100% - 20px);
  min-height: 110px;
}
.test-drive img {
  max-width: 200px;
}

.addTextArea .liveCgatUserTable > tbody > tr > td:nth-child(2), 
.addTextArea .liveCgatUserTable > tbody > tr > td:nth-child(5) {
  display: table-cell;
}
.chatReportCol.cstm-box-time .dot::before {
  top: 20px;
}
.triggers-design .user-table-main-header .search-bar .search-div {
  width: calc(100% - 128px);
}
.triggers-design .user-table-main-header .search-bar .search-div + .btnBlueSettings {
  padding: 11px 10px;
}
.imageList ul li {
  text-align: center;
  max-width: inherit;
  min-width: auto;
  margin: 10px;
}
.chat-feilds .mob-col-rev {
  flex-direction: column-reverse;
}
.chat-feilds .mob-col-rev .imageList{
  width: calc(100% - 0px);
  padding-right: 0;
}
.imageList::after{
  display: none;
}
.chat-feilds .mob-col-rev .text-img-view::after {
  display: none;
}
.text-img-view {
  margin: 20px auto;
  max-width: 250px;
  padding: 0;
  width: 100%;
}
}

@media (max-width:380px)  {
  .setting-section .setting-footer-fixed {
    padding: 20px 10px !important;
  }
  .backgrounds-color-tab > span {
    width: 117px;
    margin-bottom: 5px;
  }
  .chatforms-rightPanel-Phone .screen.screen-chatsetting, .chatforms-rightPanel-Phone .mobileScreeNew {
    width: calc(100% - 57px) !important;
}
.mobileScreeNew .screen {width: calc(100% - 5px) !important;}

.datePic_transform .react-datepicker-popper {
  transform: translate3d(53px, 247px, 0px) !important;
  -webkit-transform: translate3d(53px, 247px, 0px) !important;
  -moz-transform: translate3d(53px, 247px, 0px) !important;
  -ms-transform: translate3d(53px, 247px, 0px) !important;
  -o-transform: translate3d(53px, 247px, 0px) !important;
}


.datePic_transform .react-datepicker-popper  .react-datepicker__triangle{
left: 165px !important;
}

.screen-body {height: calc(100vh - 330px) !important;}
text {
  font-size: 0.5em;
}
.languageFilter p.detactLanguage {
  font-size: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.googleTranslateIconImage {top: 0;width: 20px;}
span.dismissSpan {width: 12px;}
.selectOptionDetactLanguage {width: 100px;}
.widget-phone {
  height: 100vh;
  border-radius: 0;
  width: calc(100% - 28px);
  margin: 0px;
  padding: 30px 10px;
  max-height: inherit;
  padding-top: 30px;
  right: 14px;
}
.chatReportCol .chatReport-total-chats .numberReport {
  font-size: 16px;
}
.chatReport-total-chats .wait-time-others .big {
  font-size: 20px;
}
.chatReport-total-chats .online-time .numberReport .small, .numberReport .big {
  font-size: 18px;
}
.chatReportCol.cstm-box-time .dot::before {
  top: 16px;
}
.triggers-design .aside-agent .btnBlueSettings {
  padding: 11px 10px;
}
.widget-message.time_zone > div {
  width: calc(100% - 0px);
}
.trialPopup.timezone-popup .trialPopupBody {
  padding: 20px;
  width: calc(100% - 20px);
  margin: 10px;
}
}

@media(max-width:350px) {
  .card-body-text.position-top .tour_guide {
    bottom: 85px;
  }
}


