
.dials .numberdials {
  list-style: none !important;
}
.dialpad .number {
  position: relative;
  z-index: 2;
  padding: 6px 0px;
  color: #000;
  font-weight: bold;
  font-size: 25px;
  //background: #fff;
  height: 52px;
  overflow: hidden;
}
.dialpad .dials {
  margin: -1px 0 0 -1px;
  background: #1d1918;
  cursor: pointer;
}
.dialpad .dials:before, .dialpad .dials:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}
.dialpad .dials:after {
  clear: both;
}
.dialpad .dials .digits {
  float: left;
  width: 33.33%}
.dialpad .dials .digits p {
  font-weight: 600;
  padding: 15px 25px;
  border-top: 1px solid #4d4d4d;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #4d4d4d;
}
.dialpad .dials .digits p strong {
  font-size: 50px;
  margin-right: 2px;
  color: #fff;
  margin-left: 17px;
}
.dialpad .dials .digits:active {
  background: #00caf2;
  border-top-color: #b2f2ff;
}
.compact .dials .digits p {
  padding: 10px 72px 10px 1px;
}
.compact .dials .digits p strong {
  font-size: 20px;
}
.compact .dials .digits p sup {
  text-transform: uppercase;
  color: #c1c1c1;
}
.compact .dials .pad-action {
  background: #093;
}
.compact .dials .pad-action:active {
  background: #0c3;
}

.compact .dials .pad-hangup {
  background: #da3009;
}
.compact .dials .pad-hangup:active {
  background: #da3009;
}
/*
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px;
}
.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%}
.hidden {
  display: none !important;
  visibility: hidden;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.invisible {
  visibility: hidden;
}
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
} */

.dialer-nav{
  position: absolute;
  left: 62px;
  bottom: 0;
  background: #fff;
  box-shadow: 0px 2px 5px 4px rgba(51, 51, 51, 0.12);
  border-radius: 10px;
  width: 250px;
  z-index: 9999;
  top: 3%;
  display: inline-block;
  height: 100%;
}

.dialer-nav:before {
  content: '';
  position: absolute;   
  left: -20px;     
  top: 16px;
  //bottom: 250px;
  border: 10px;
  border-style: solid;
  border-color: transparent #ffffff transparent transparent;
}

.dialer-crose {
  background: #fff;
  width: 25px;
  height: 25px;
  line-height: 21px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
  border: 1.5px solid #333;
  position: absolute;
  right: -15px;
  top: -20px;
  z-index: 999999999999;
}


.dialpad .dials .digitsms {
  float: left;
  width: 33.33%}
.dialpad .dials .digitsms p {
  font-weight: 600;
  padding: 15px 25px;
  border-top: 1px solid #4d4d4d;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #4d4d4d;
}
.dialpad .dials .digitsms p strong {
  font-size: 50px;
  margin-right: 2px;
  color: #fff;
  margin-left: 7px;
}
.dialpad .dials .digitsms:active {
  background: #00caf2;
  border-top-color: #b2f2ff;
}
.compact .dials .digitsms p {
  padding: 10px 72px 10px 1px;
}
.compact .dials .digitsms p strong {
  font-size: 20px;
}
.compact .dials .digitsms p sup {
  text-transform: uppercase;
  color: #c1c1c1;
}

/// Right Panel css start //////

.rightdials .rightnumberdials {
  list-style: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.rightdialpad .number {
  padding: 6px 0px;
  color: #000;
  font-weight: bold;
  font-size: 25px;
  //background: #fff;
  //height: 52px;
  //overflow: hidden;
}
.rightdialpad .textsms {
  padding: 6px 0px;
  color: #000;
  font-weight: bold;
  font-size: 24px;
  //background: #fff;
  //height: 52px;
  overflow: hidden;
}
.rightdialpad .rightdials {
  margin: -1px 0 0 -1px;
  cursor: pointer;
}
.rightdialpad .rightdials:before, .rightdialpad .rightdials:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}
.rightdialpad .rightdials:after {
  clear: both;
}
.rightdialpad .rightdials .digits {
  width: calc(33.33% - 20px);
  background: #F0F0F0;
  margin: 10px 10px 10px 10px;
  border-radius: 10px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 92px;
}
.rightdialpad .rightdials .digits p {
  font-weight: 600;
  line-height: 1.1;
}

.rightdialpad .rightdials .digits span.english-word{
  color: #000;
  text-align: center;
  font-weight: normal;
}
.rightdialpad .rightdials .digits p strong {
  font-size: 50px;
  color: #000;
}
.rightdialpad .rightdials .digits:active {
  background: #82c3ff;
  border-top-color: #82c3ff;
}
.rightcompact .rightdials .digits p sup {
  text-transform: uppercase;
  color: #c1c1c1;
}
.rightcompact .rightdials .pad-action {
  background: #093;
}
.rightcompact .rightdials .pad-action:active {
  background: #0c3;
}

.rightcompact .rightdials .pad-hangup {
  background: #da3009;
}
.rightcompact .rightdials .pad-hangup:active {
  background: #da3009;
}

.rightdialerbuttons .dbuttons {
  list-style: none !important;
}

.rightdialerbuttons .digits {
  float: left;
  width: 33.33%;
  text-align: center;
}

.rightdialerbuttons .digits p {
  font-weight: 600;
  padding: 30px 20px;
}

.textsms.donthidebyclkonbdy textarea{
  width: 100%;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}

.number.donthidebyclkonbdy input{
  width: 100%;
  padding: 10px;
  height: 40px;
  font-size: 14px;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid #dfdfdf;
}

.enter-Numbers-Dialed{
  font-weight: normal;
  color: #000;
  font-size: 16px;
}

#rig_phone_number { padding-left: 50px; }
/// Right Panel css end //////