
/**********************************************************/
/**********************************************************/
/**********************************************************/
/************** start anas libarary css *******************/
/**********************************************************/
/**********************************************************/
/**********************************************************/


.k-flex{
    display: flex;
}

.justify-content-end{
    justify-content: flex-end;
}

.align-items-flexend{
  align-items: flex-end;
}

.justify-content-center{
  justify-content: center;
}

.align-items-center{
    align-items: center;
}

.align-items-flex-end{
  align-items: flex-end;
}

.custome-row{
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.top10{top: 10px !important;}
.top20{top: 20px !important;}
.top30{top: 30px !important;}
.top40{top: 40px !important;}
.top50{top: 50px !important;}
.top60{top: 60px !important;}
.top70{top: 70px !important;}
.top80{top: 80px !important;}
.top90{top: 90px !important;}
.top100{top: 100px !important;}

.pb10{padding-bottom: 10px;}
.pb20{padding-bottom: 20px;}
.pb30{padding-bottom: 30px;}
.pb40{padding-bottom: 40px;}
.pb50{padding-bottom: 50px;}
.pb60{padding-bottom: 60px;}
.pb70{padding-bottom: 70px;}
.pb80{padding-bottom: 80px;}
.pb90{padding-bottom: 90px;}
.pb100{padding-bottom: 100px;}

.pt10{padding-top: 10px;}
.pt20{padding-top: 20px;}
.pt30{padding-top: 30px;}
.pt40{padding-top: 40px;}
.pt50{padding-top: 50px;}
.pt60{padding-top: 60px;}
.pt70{padding-top: 70px;}
.pt80{padding-top: 80px;}
.pt90{padding-top: 90px;}
.pt100{padding-top: 100px;}


.p-RL10{padding: 0px 10px;}
.p-RL15{padding: 0px 15px;}
.p-20{padding: 0px 20px;}
.p-RL25{padding: 0px 25px;}
.p-RL30{padding: 0px 30px;}


.mb10{margin-bottom: 10px;}
.mb20{margin-bottom: 20px;}
.mb30{margin-bottom: 30px;}
.mb40{margin-bottom: 40px;}
.mb50{margin-bottom: 50px;}
.mb60{margin-bottom: 60px;}
.mb70{margin-bottom: 70px;}
.mb80{margin-bottom: 80px;}
.mb90{margin-bottom: 90px;}
.mb100{margin-bottom: 100px;}


.fb{background: #0F78D3;}
.tw{background: #43A8FF}
.pi{background: #EB0000}
.bl{background: #F4640C}




h3.card-title{font-size: 24px;}
h5.card-title{font-size: 16px;}
h4.card-title{font-size: 18px;}
h6.card-title{font-size: 14px;}



p.text-mutede{font-size: 16px;}
p.card-body-text{font-size: 14px;}

.card-body{
  display: flex;
    flex-direction: row;
    align-content: space-between;
    /* border-bottom: 0px solid #3f4156; */
}
/**********************************************************/
/**********************************************************/
/**********************************************************/
/**************** end anas libarary css *******************/
/**********************************************************/
/**********************************************************/
/**********************************************************/

body {
  margin: 0;
  font-weight: 400;
  text-align: left;
  font-size: 16px;
  background-color: #fff;
}

.customize-widget {
  width: 100%;
  height: 100vh;
  background: url(../images/bg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-y: auto;
}

.logo-section > img {
  width: 235px;
  height: auto;
  margin: 10px 0px;
}

/* .footerStepbyStep {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
} */

.catfeacture-section-2 {
  width: 900px;
  margin: auto;
  font-size: 30px;
  text-align: center;
  position: relative;
  bottom: -20px;
}

.catfeacture-section-2 ul {
  padding-left: 10px;
  padding-top: 10px;
  list-style-type: none;
  display: inline-block;
  margin-bottom: 0px;
}

.catfeacture-section-2 ul li {
  float: left;
  padding: 2px 20px;
}

.catfeacture-section-2 ul li img {
  height: 34px;
  filter: grayscale(1);
  opacity: 0.7;
}

.catfeacture-section-2 ul li img:hover {
  opacity: 1;
  filter: none;
}

.widget-sec > ul > li{
  list-style: none;
  display: inline-block;
}

.widget-theme > ul > li:last-child span{
  background: rgb(162,58,180);
  background: radial-gradient(circle, rgba(162,58,180,1) 15%, rgba(29,63,253,1) 37%, rgba(82,252,69,1) 92%);
}

.widget-badge > ul > li {
  width: 35px;
  height: 35px;
  border: 1px solid rgba(51, 51, 51, 0.08);
  text-align: center;
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
  vertical-align: top;
  margin-right: 5px;
}

.widget-badge > ul > li.lastes{
    background: #0484F8;
    border-radius: 4px;
    border: 1px solid #0484F8;
}

.widget-badge > ul > li.lastes span.agent-rightpanel-feilds{
  border: 0;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}

.widget-badge > ul > li.active{
  border: 1px solid#333;
}
.chrome-picker {position: absolute;z-index: 99999999;}
.widget-badge > ul > li > span{
  align-items: center;
  justify-content: center;
  display: block;
}

.widget-badge > ul > li > span img{
  width: 20px;
  height: 20px;
}
.widget-badge > ul.avatarImg > li span img {
  width: 27px;
  height: 27px;
  border-radius: 50%;
}

.widget-badge > ul > li.active {
  border: 1px solid #0CA200;
  position: relative;
}
.widget-badge > ul > li.active::after{
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: url('../../../assets/images/active-icon.svg') no-repeat;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}



.widget-brandLogo > ul > li {
  width: 33px;
  height: 33px;
  border: 1px solid #0484F8;
  text-align: center;
  line-height: 33px;
  padding: 0;
  border-radius: 100%;
  cursor: pointer;
  margin-right: 5px;
}

.widget-brandLogo > ul > li.lastes{
  background: #0484F8;
  border-radius: 4px;
  border: 1px solid #0484F8;
}

.widget-brandLogo > ul > li.lastes > span.agent-rightpanel-feilds{
  border: 0;
}


.widget-brandLogo > ul > li.active{
  border: 1px solid#333;
}

.widget-brandLogo > ul > li > span{
  align-items: center;
  justify-content: center;
  display: inline-flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.widget-brandLogo > ul > li > span img{
  width: 22px;
  height: 22px;
}


.widget-message textarea {
  background: transparent;
  border: 1px solid #a7a7a7;
  font-family: 'HelveticaNeueLight';
  font-weight: bold;
  resize: none;
  font-size: 14px;
  color: #000;
}

.widget-message textarea:focus{
  background: transparent;
  border: 1px solid #a7a7a7;
  color: #000;
}

.widget-name input {
  background: transparent;
  text-align: left;
}

.widget-name input:focus {
  background: transparent;
  text-align: left;
}

.widget-theme > ul > li > span{
  width: 30px;
  height: 30px;
  border: 1px solid rgba(51, 51, 51, 0);
  text-align: center;
  line-height: 25px;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.widget-theme > ul > li.active span{
  background-image: url(../images/widget/checked1.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}


.widget-sec h4{
  font-family: 'HelveticaNeueMedium';
}

.custmize-your-widget h1{
  font-family: 'Seravek';
  font-size: 44px;
  margin-bottom: 0;
  font-weight: normal;
}

.custmize-your-widget p{
  font-family: 'HelveticaNeueLight';
  color: #868686;
  font-weight: bold;
}

.custmize-your-widget p strong{
  font-family: 'HelveticaNeueBold';
}

.custmize-your-widget p a{
  font-family: 'HelveticaNeueLight';
  color: #000;
  font-weight: bold;
}
.custmize-your-widget p a {
  color: #0184f8;
  text-decoration: underline;
}
.custmize-your-widget p a:hover{
  color: #0184f8;
  text-decoration: none;
}
.test-drive img {width: 100%;}

.font-ligts-support{
  font-family: 'HelveticaNeueLight' !important;
  font-weight: bold !important;
}

.email-help-para p{
  font-family: 'HelveticaNeueMedium';
}

.powerdBySnapyIcon {
  display: inline-block;
  width: 10px;
  margin-right: 5px;
}

.custimize-wraper{
  width: 57%;
  margin: auto;
}

/*-------------- start screen section --------*/

.screen{
  width: 300px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2em 1em;
  border-radius: 10px 10px 23px 23px;
  -webkit-border-radius: 10px 10px 23px 23px;
  -moz-border-radius: 10px 10px 23px 23px;
  -ms-border-radius: 10px 10px 23px 23px;
  -o-border-radius: 10px 10px 23px 23px;
}

.screen .screen-header{
  background: #2c3149;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -ms-border-radius: 10px 10px 0px 0px;
  -o-border-radius: 10px 10px 0px 0px;
  text-align: left;
}

.screen .screen-header p{
  color: #fff;
  font-size: 14px;
}

.screen-body{
  height: 470px;
  overflow-y: auto;
  background: #fafcfe;
  padding: 0;
}

.custmize-your-widget .screen-body{
  height: 250px;
}

.screen-less{
  width: 270px;
}

.screen-less-Mobile{
  width: 276px !important;
}

.screen-less-Mobile .screen{
  width: 270px;
}

.screen-less-Mobile .screen-body{
  height: 360px;
}

.screen-less .screen-body{
  height: 360px;
}

.screen-less .mobileScreeNew{
  width: 276px;
}

.screen.screen-chatsetting{
  width: 300px;
}

.screen.screen-chatsetting-mobile{
  width: 300px;
}


.custmize-your-widget .screen{
  width: 230px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2em 1em;
  border-radius: 10px 10px 23px 23px;
  -webkit-border-radius: 10px 10px 23px 23px;
  -moz-border-radius: 10px 10px 23px 23px;
  -ms-border-radius: 10px 10px 23px 23px;
  -o-border-radius: 10px 10px 23px 23px;
}

.send-btn{
  background: #2b3149;
  width: 28px;
  height: 28px;
  display: inline-block;
  text-align: center;
  line-height: 26px;
  border-radius: 100%;
  padding: 0px;
}

.send-btn > img{
  width: 14px;
}

.scr-footer-1{
  background: #fff;
}

.scr-footer-1 > input{
  width: calc(100% - 30px);
  border: 0;
  font-size: 14px;
  padding-right: 10px;
  font-family: 'HelveticaNeueLight';
  text-align: left;
  background: transparent;
}

.powerby{
  text-align: center;
  background: #f5f5f5;
  padding: 5px 10px;
  margin: 0px 0px;
  border-radius: 0px 0px 23px 23px;
}

.powerby > p {
  margin: 0px;
  font-family: "HelveticaNeueMedium";
  font-size: 9px;
  padding: 2px 0px;
}


.body-chat .user{
  display: inline-block;
  width: 31px;
  height: 31px;
  color: #fff;
  text-align: center;
  line-height: 25px;
  border-radius: 100px;
  margin: 0px 5px;
  font-size: 12px;
  padding: 2px;
}

.body-chat .recived .user{
  line-height: 29px;
}

.sent .user {
  background: transparent;
}

.body-chat .user > img{
  width: 100%;
  height: 100%;
  /* border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%; */
}

.recived .user > img{
  border-radius: 100%;
  -webkit-border-radius: 100%;   
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.del-photo-user svg{
  width: 15px;
}

.del-photo-user{
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: super;
  padding-left: 10px;
  position: relative;
  cursor: pointer;
  z-index: 9;
}

.message-box{
  max-width: 67%;
}

.body-chat .message{
  border-radius: 5px !important;
  font-size: 11px;
  padding: 8px;
  text-align: left;
  display: block;  
  font-family: 'HelveticaNeueLight';
  color: #000;
}

.recived .message{
  background: #E6E6E6;
}


.sent .message{
  background: rgb(44, 48, 73);
  color: #fff; 
}

.chat-user-Name{
  font-family: 'HelveticaNeueLight';
  font-size: 10px;
  color: #000;
}

.chat-Line {
  display: inline-block;
  width: 100%;
  height: 4px;
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

.recived .message-line{
  line-height: 11px;
  text-align: left;
}

.sent .message-line{
  line-height: 12px;
  text-align: right;
}

.recived .chat-Line{
  background: #0a0a0a;
}

.sent .chat-Line{
  background: #fff;
}


.chatforms-rightPanel-Phone .screen{
  margin-left: 20px;
  margin-top: 20px;
}

.screenBackArroe svg{
  height: 16px;
  width: 16px;
}

.screenMenuArrow svg{
  width: 5px;
}

.screeCloseArrow svg{
  width: 23px;
}

.mobileScreeNew .screen{
  box-shadow: inherit;
}

.mobileScreeNew{
  background: #4c4d5b;
  width: 306px;
  margin-left: 20px;
  padding: 5px 0px 0px 0px;
  border-radius: 23px;
  position: relative;
  margin-top: 20px;
}

.mobileScreeNew .screen{
  margin: 0px auto;
  margin-bottom: 20px;
}

.mobileScreeNew .screen .screen-header{
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.btn-feilds{
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
}

.demo-device-chrome-phone1 {
  position: relative;
  bottom: 10px;
  height: 6px;
  width: 110px;
  border-radius: 100px;
  background: #fff;
  margin: auto;
}

.screen-forems .screen-feilds input, .screen-forems .screen-feilds textarea{
  border: 1px solid hsla(0,0%,64.7%,.64);
  font-size: 11px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
  font-family: 'HelveticaNeueLight';
  font-weight: bold;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.screen-forems .screen-feilds label{
  font-size: 11px;
  margin: 0;
  color: #000;
}

.screen-forems .screen-feilds{
  padding: 4px 10px;
}




.form-introduce{
  width: 90%;
  background: #fff;
  margin: 25px auto 0px;
  box-shadow: 0px 1px 4px rgba(51, 51, 51, 0.15);
  border-radius: 8px;
  padding: 20px 10px 10px;
  padding-bottom: 60px;
}


.introduceed-details p{
  margin: 0 0;
  font-size: 12px;
  text-align: left;
  color: #636363;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 0;
}

.introduceed-details span{
  width: 25px;
  height: 25px;
  background: #4092fa;
  padding: 5px;
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  margin: auto;
  display: block;
  text-align: center;
  margin-top: -35px;
  line-height: 0.9;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.introduceed-details svg{
  width: 9px;
  height: 100%;
}



.screen-thnks-message, .screen-ratings{
  position: absolute;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px 10px;
  box-shadow: 0px -1px 4px -3px grey;
}

.screen-thnks-message p, .screen-ratings p {
  font-size: 14px;
  line-height: 14px;
}

.rating-star-screen{
  color: #bfc4c3;
  cursor: pointer;
}

.rating-star-screen:hover{
  color: #efce4a;
}

.first-messages{
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.13) !important;
  font-size: 11px !important;
  color: #000 !important;
}

/*-------------- end screen section --------*/

.widgets{
  width: 50px;
  height: 50px;
  padding: 13px;
  background: #0184f8;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: auto;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  line-height: 1.1;
}

.widgets > img{
  width: 100%;
  height: 100%;
}

.bodress-widgets{
  display: inline-block;
  width: 20%;
}

span.bodress-widgets:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  border: 1px solid #e0e0e0;
  width: 1px;
  margin: auto;
}

.btnBlueNext {
  background: #0184f8;
  border: 1px solid #0184f8;
  font-size: 16px;
  color: #fff;
  padding: 10px 50px;
  border-radius: 100px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.14);
  height: auto;
}

.btnBlueNext.uploadagentprofile{
  font-size: 14px;
  display: inline-block;
  font-family: 'Roboto', sans-serif
}

.text-lightgray{
  color: #a7a7a7;
}

.setupt-name .md-form input{
  border: 0 !important;
  border-bottom: 1px solid #333 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  padding-left: 0 !important;
  color: #000;
  font-size: 14px;
}

.setupt-name .md-form input:focus{
  box-shadow: inherit !important;
  border: 0 !important;
  border-bottom: 1px solid #333 !important;
  border-radius: 0px !important;
}

.setupt-name .md-form.md-outline label{
  padding-left: 0px !important;
}

.setupt-name .md-form.md-outline label.active{
  background: transparent;
  left: 0;
  padding-right: 0;
  padding-left: 0;
}

.company-information{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.company-information1{
  width: 750px;
}

.company-information input{
  background: transparent;
  border: 0;
  border-bottom: 1.5px solid #000;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  padding: 0;
  font-size: 14px;
  color: #000;
}

.company-information label{
  margin: 0;
  color: #000;
}

.company-information input:focus{
  background: transparent;
  border-bottom: 1.5px solid #333;
  color: #000;
}

.clicntLogo-heading{
  font-family: 'HelveticaNeueLight';
  font-weight: bold;
}

.clicntLogo-heading strong{
  font-family: 'HelveticaNeueBold';
}



.bg-gradient-primary{
  background: url(../images/login-bg.svg);
  background-size: cover;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


.bg-gradient-primary .card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}


.widget-settings{
  width: 420px;
}

.setting-footer-fixed{
  position: fixed;
  bottom: 0px;
  background: #fff;
  width: calc(100% - 303px);
  z-index: 1;
}

.setting-footer-fixed-RightPreview{
  position: fixed;
  bottom: 0px;
  background: #fff;
  width: calc(100% - 726px);
  z-index: 9;
}


.mb-foter-Fixed{
  margin-bottom: 80px;
}

.main-cont {
  position: absolute;
  right: 400px;
  bottom: 250px;
  width: calc(100% - 400px);
  text-align: right;
  max-width: 800px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
}
.main-cont h2{color: #fff;}


.header-customer-side h3.side-logo {
  font-size: 32px;
  color: #fff;
}

.centerWidgetArrow h2{
  font-size: 30px;
  color: #474747;
  font-weight: bold;
}


.form-introduce .screen-forems .screen-feilds.bottom-fix {
  background: #fff;
  width: calc(100% - 50px);
  margin: 0 auto;
  bottom: 35px;
}

.widgets.avtarView {
  padding: 0;
  box-shadow: 0px 1px 3px #00000057;
}
.widgets.avtarView > img {
  border-radius: 50%;
}


@media (max-width:600px){
  .main-cont{
    right: 0;
    bottom: 400px;
    width: calc(100% - 0px);
    padding-right: 10px;
  }
}